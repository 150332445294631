import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomNode from "./CustomNode";
import IVRNode from "./IVRNode";
import Media from "./Media";
import VoiceBotNode from "./VoiceBotNode";
import CreatedNewIVR from "./CreatedNewIVR";
import NewSmsBroadcast from "./NewSmsBroadcast";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  InputLabel,
  Tooltip,
  Grid,
  Input,
  Switch,
  styled,
  Slider,
} from "@mui/material";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  Handle,
  reconnectEdge,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";
import "./SideDrawer.css";
import "./WorkflowBuilder.css";
import {
  Apartment,
  CallEnd,
  Delete,
  FollowTheSigns,
  KeyboardBackspace,
  LowPriority,
  MessageOutlined,
  MicNone,
  NearMe,
  NumbersSharp,
  PhoneCallback,
  RecordVoiceOver,
  SupportAgent,
  TransferWithinAStation,
  Voicemail,
  Api,
  MarkUnreadChatAlt,
  QuestionAnswer,
  TouchApp,
  MarkChatRead,
  ConnectWithoutContact,
  SmsFailed,
  WhatsApp,
  Subscriptions,
  Image,
  Videocam,
  AudioFile,
  Description,
} from "@mui/icons-material";
import {
  addChatbotCase,
  addIvrOption,
  DeleteIvrOptionById,
  DeleteNodeForVoiceBot,
  EditVoiceBotNode,
  GetIVROptionMenuDetails,
  getVoiceBotCaseById,
  getWhatsappConfiguration_API,
  KnowledgeBaseList,
  ShowExtensionsList,
  showIvrConfigurationList,
  ShowPromptList,
  ShowQueueListForIvr,
  showVoiceBotConfigurationList,
  updateIvrOption,
  updateChatbotCase,
  DeleteChatbotCase,
  getQueuesList,
  GetTtsPromptData,
  GetIdForTTSPrompt,
  EditIvr,
  AddCallBridgeNode,
  EditCallBridgeNode,
  getCallBridgeCaseById,
  DeleteNodeForCallBridge,
  getTemplateData,
  CreateVoiceConfiguration,
} from "./utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CallBridgeNode from "./CallBridgeNode";
import { Voices } from "./json_format_of_supported_language_and_voice";

const nodeTypes = {
  custom: CustomNode,
  IVRNode: IVRNode,
  CreatedNewIVR: CreatedNewIVR,
  VoiceBotNode: VoiceBotNode,
  NewSmsBroadcast: NewSmsBroadcast,
  media: Media,
  CallBridgeNode: CallBridgeNode,
};

const CreateNodeModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  bgcolor: "#F6F6F6",
  borderRadius: "4px",
  outline: "0px",
};

const ivrModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  bgcolor: "#F6F6F6",
  borderRadius: "8px",
  outline: "0px",
};
const voiceBotStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  bgcolor: "#F6F6F6",
  borderRadius: "8px",
  outline: "0px",
};

// const WorkflowBuilder = () => {
const WorkflowBuilder = ({ accessToken, domainUUID }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const edgeReconnectSuccessful = useRef(true);

  const { configurationName, configurationId, channel, condition, isTemplate, template_id } =
    location.state || {};

  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_API_URL_CASE_FACEBOOK =
    process.env.REACT_APP_API_URL_CASE_FACEBOOK;

  const REACT_APP_API_URL_FLOW = process.env.REACT_APP_API_URL_FLOW;
  const REACT_APP_API_URL_CASE_CALL_CENTER =
    process.env.REACT_APP_API_URL_CASE_CALL_CENTER;

  // const domainUUID = "50f0827c-d18f-11ee-8379-0a95ea71a99c";
  // const domainUUID = "d455360c-4b56-11ef-9674-42010a800003";
  // const domainUUID = "3fda11a6-4b39-11ef-8c21-42010a800003";

  // const accessToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJUZW5hbnQiLCJleHAiOjE3Mzc2OTczOTYsImlhdCI6MTczNzYxMDk5NiwiaXNzIjoiYXV0aC1zZXJ2aWNlIiwibmJmIjoxNzM3NjEwOTk2LCJzdWIiOiI1MGYwODI3Yy1kMThmLTExZWUtODM3OS0wYTk1ZWE3MWE5OWMifQ.JBznp2GH5PixwvO9h8CwUAkJlqh5qY_EnFYC5slXNag";

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [updateMode, setUpdateMode] = useState(false);
  const [selectedNodeType, setSelectedNodeType] = useState("");
  const [configurationList, setConfigurationList] = useState("");
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [nodeName, setNodeName] = useState("");
  const [nodeData, setNodeData] = useState("");
  const [conditionAPI, setConditionAPI] = useState("");
  const [method, setMethod] = useState("");
  const [payloadType, setPayloadType] = useState("");
  const [payload, setPayload] = useState("");
  const [queue, setQueue] = useState("");
  const [queueList, setQueueList] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [buttonList, setButtonList] = useState([]);
  const [buttonText, setButtonText] = useState("");

  const [SelectedFile, setSelectedFile] = useState(null); // For media upload in Voice bot and Whatsapp
  const fileInputRef = useRef(null);

  // Function to handle file selection
  const HandleSelectFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const HandleWhatsappFileSubmit = async () => {
    if (!SelectedFile) {
      toast.error("Please select a file before uploading.", {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }
    const fileTypeError = {
      image: !SelectedFile.type.startsWith("image/"),
      video: !SelectedFile.type.startsWith("video/"),
      audio: !SelectedFile.type.startsWith("audio/"),
      document: !SelectedFile.type.startsWith("application/"),
    };

    if (fileTypeError[ConditionData]) {
      toast.error(`Please select a valid ${ConditionData} format.`, {
        position: "top-right",
        autoClose: 3000,
      });
      setSelectedFile(null);
      setWhatsappMediaLoader(false);
      return;
    }

    const formData = new FormData();
    formData.append("domain_uuid", domainUUID);
    formData.append("attachment_name", SelectedFile.name);
    formData.append(
      "attachment_file",
      SelectedFile,
      "1ef847fd-320a-4620-b323-39c40b1e2a9c"
    );

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${REACT_APP_API_URL_FLOW}/ccaas/upload-media`,
        requestOptions
      );
      const result = await response.json();

      if (result.status == true) {
        if (updateMode) {
          EditWhatsAppFunction(result.file_path);
        } else {
          CreateWhatsAppFunction(result.file_path);
        }
      } else {
        toast.error(result.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setSelectedFile(null);
      }
    } catch (error) {
      setWhatsappMediaLoader(false);
      toast.error("An error occurred during the upload process.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const HandleVoicebotFileSubmit = async () => {
    if (!SelectedFile) {
      toast.error("Please select a file before uploading.", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    const fileTypeError = {
      image: !SelectedFile.type.startsWith("image/"),
      video: !SelectedFile.type.startsWith("video/"),
      audio: !SelectedFile.type.startsWith("audio/"),
      document: !SelectedFile.type.startsWith("application/"),
    };

    if (fileTypeError[ConditionData]) {
      toast.error(`Please select a valid ${ConditionData} format.`, {
        position: "top-right",
        autoClose: 3000,
      });
      setSelectedFile(null);
      setVoiceBotMediaLoader(false);
      return;
    }

    const formData = new FormData();
    formData.append("domain_uuid", domainUUID);
    formData.append("attachment_name", SelectedFile.name);
    formData.append(
      "attachment_file",
      SelectedFile,
      "1ef847fd-320a-4620-b323-39c40b1e2a9c"
    );

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${REACT_APP_API_URL_FLOW}/ccaas/upload-media`,
        requestOptions
      );
      const result = await response.json();

      if (result.status) {
        if (updateMode) {
          EditVoiceBotFunction(result.file_path);
        } else {
          CreateVoiceBotFunction(result.file_path);
        }
      } else {
        toast.error(result.message, { position: "top-right", autoClose: 3000 });
        setSelectedFile(null);
      }
    } catch (error) {
      setVoiceBotMediaLoader(false);
      toast.error("An error occurred during the upload process.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const HandleWhatsappMedia = (setValue) => {
    setSelectedFile(null);
    setPayloadType(setValue);
    // setConditionData(setValue);

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };

  const HandleVoiceMedia = (setValue) => {
    setSelectedFile(null);
    setConditionData(setValue);

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };

  const handleAddButton = () => {
    if (
      selectedImage == "layout_1" &&
      buttonList.length > 2 &&
      channel == "whatsapp"
    ) {
      toast.info("Please Change the Layout!", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    if (buttonText.trim() !== "") {
      setButtonList([...buttonList, buttonText]);
      setButtonText("");
    }
  };

  const handleDeleteButton = (index) => {
    setButtonList(buttonList.filter((_, i) => i !== index));
  };

  const onNodesChange = (changes) =>
    setNodes((nds) => applyNodeChanges(changes, nds));
  const onEdgesChange = (changes) =>
    setEdges((eds) => applyEdgeChanges(changes, eds));
  // const onConnect = (connection) => setEdges((eds) => addEdge(connection, eds));
  const onConnect = (connection) => {
    const newEdge = {
      ...connection,
      markerEnd: {
        type: MarkerType.ArrowClosed, // Closed arrow at the end
        width: 30,
        height: 30,
        color: "black",
      },
    };

    const isSourceHandleAlreadyConnected = edges.some(
      (edge) =>
        edge.source === connection.source &&
        edge.sourceHandle === connection.sourceHandle
    );

    if (isSourceHandleAlreadyConnected) {
      // Prevent the new connection if the source handle is already connected to another node
      return;
    } else {
      setEdges((eds) => addEdge(newEdge, eds));
    }
  };

  const onReconnectStart = () => {
    edgeReconnectSuccessful.current = false;
  };

  // Handles reconnection of an edge
  const onReconnect = (oldEdge, newConnection) => {
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
  };

  // Handles deletion if reconnection fails
  const onReconnectEnd = (_, edge) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }
    edgeReconnectSuccessful.current = true;
  };

  const fetchQueuesList = async () => {
    const result = await getQueuesList(accessToken, domainUUID);

    if (result.ResponseCode === 200) {
      let filteredResult = result.Queue; // Initialize with full queue

      // Filter based on channel
      if (channel === "whatsapp") {
        filteredResult = result.Queue.filter(
          (item) => item.map_with === "whatsapp"
        );
      } else if (channel === "telegram") {
        filteredResult = result.Queue.filter(
          (item) => item.map_with === "telegram"
        );
      }

      setQueueList(filteredResult);
    } else {
      setQueueList([]);
    }
  };

  const PublishChanges = (config_id) => {
    if (channel == "" || configurationId === "") {
      toast.info("Please select all the required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    const flow = { nodes, edges };

    // console.log(flow);

    localStorage.setItem("flow", JSON.stringify(flow));
    const flowvalue = localStorage.getItem("flow");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      configuration_id: isTemplate == "true" ? config_id : configurationId,
      flow: isTemplate == "true" ? templateFlow : flowvalue,
      channel:
        channel === "IVR"
          ? `${channel}_${configurationId}`
          : channel === "smsBroadcast"
            ? "smsBroadcastPublish"
            : channel,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL_FLOW}/ccaas/workflow/v1/publishChanges?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == false) {
          toast.info(result.message, {
            position: "top-right",
            autoClose: 3000,
          });
        } else if (result.status == true) {
          toast.success("Changes Published", {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.error("Error in Publishing Data", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (condition) {
      handleRestoreLayout();
    }
  }, [condition]);

  // <-----------For Retrieving the flow ------->
  useEffect(() => {
    if (isTemplate == "true") {
      restoreTemplateFlow();
    } else if (configurationId != "") {
      restoreNormalFlow();
    }
  }, [configurationId]);

  const restoreNormalFlow = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      configuration_id: configurationId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL_FLOW}/ccaas/workflow/v1/getChanges?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == true) {
          const Flow = result.data[0].flow;

          const localflow = JSON.parse(Flow);

          if (localflow) {
            const rehydratedNodes = localflow.nodes.map((node) => {
              if (
                node.type === "message" ||
                node.type === "chat" ||
                node.type === "button" ||
                node.type === "custom" ||
                node.type === "media"
              ) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    label: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          width: "250px",
                          rowGap: "6px",
                        }}
                      >
                        <h2
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            display: "flex",
                            alignItems: "center",
                            margin: "0px",
                            textTransform: "capitalize",
                            color: "#094e6c",
                            borderBottom: "1px #006d77 solid",
                            paddingBottom: "3px",
                          }}
                        >
                          {node.type === "message"
                            ? "Message"
                            : node.type === "chat"
                              ? "Chat"
                              : node.type === "media"
                                ? "Media"
                                : "Button"}
                        </h2>
                        <h3
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            margin: "0px",
                            color: "#161a1d",
                          }}
                        >
                          Name:
                          <span style={{ fontWeight: "400" }}>
                            {node.data.nodeName}
                          </span>
                        </h3>
                        <p
                          style={{
                            border: "1px lightgrey solid",
                            borderRadius: "4px",
                            padding: "6px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            fontSize: "16px",
                            fontStyle: "italic",
                            backgroundColor: "#E9F6E6",
                            margin: "0px 5px 0px 0px",
                            textAlign: "justify",
                            width: "95%",
                          }}
                        >
                          {node.data.ConditionData}
                        </p>

                        {(node.type === "button" ||
                          node.type === "custom" ||
                          node.type === "media") && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "10px",
                              }}
                            >
                              { }
                              {node.data.buttonList.map((buttonLabel, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    marginBottom: "4px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        backgroundColor: "#006d77",
                                        borderRadius: "50%",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <span>{buttonLabel}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                        {node.type !== "button" &&
                          node.type !== "custom" &&
                          node.type !== "media" && (
                            <>
                              <Handle
                                type="source"
                                position="right"
                                id="sourceHandle"
                                style={{
                                  background: "#333333",
                                  width: "12px",
                                  height: "12px",
                                  borderRadius: "50%",
                                  zIndex: 100,
                                }}
                              />
                              <Handle
                                type="target"
                                position="left"
                                id="targetHandle"
                                style={{
                                  background: "#333333",
                                  width: "12px",
                                  height: "12px",
                                  borderRadius: "50%",
                                  zIndex: 100,
                                }}
                              />
                            </>
                          )}
                      </div>
                    ),
                  },
                };
              } else if (node.type === "IVRNode") {
                return restoreIVRNode(node);
              } else if (node.type === "NewIVR") {
                return restoreNewIVRNode(node);
              } else if (node.type === "CreatedNewIVR") {
                return restoreCreatedNewIVR(node);
              } else if (node.type === "VoiceBotNode") {
                return restoreVoiceBotNode(node);
              } else if (node.type === "NewSmsBroadcast") {
                return restoreNewSmsBroadcastNode(node);
              } else if (
                node.type === "CallBridgeNode" ||
                node.type == "APICallBridge"
              ) {
                return restoreCallBridgeNode(node);
              } else if (node.type === "SmsNode") {
                return restoreSmsNode(node);
              }
              return node;
            });

            setNodes(rehydratedNodes);
            setEdges(localflow.edges);
          }
        }
      })
      .catch((error) => console.error(error));
  };
  const [templateFlow, setTemplateFlow] = useState("");
  const restoreTemplateFlow = async () => {
    const TemplateData = await getTemplateData(
      accessToken,
      domainUUID,
      channel,
      configurationId
    );

    if (TemplateData.status == true) {
      const Flow = TemplateData.templates[0].flow;

      setTemplateFlow(TemplateData.templates[0].flow);
      const localflow = JSON.parse(Flow);

      if (localflow) {
        const rehydratedNodes = localflow.nodes.map((node) => {
          if (
            node.type === "message" ||
            node.type === "chat" ||
            node.type === "button" ||
            node.type === "custom" ||
            node.type === "media"
          ) {
            return {
              ...node,
              data: {
                ...node.data,
                label: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "250px",
                      rowGap: "6px",
                    }}
                  >
                    <h2
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        margin: "0px",
                        textTransform: "capitalize",
                        color: "#094e6c",
                        borderBottom: "1px #006d77 solid",
                        paddingBottom: "3px",
                      }}
                    >
                      {node.type === "message"
                        ? "Message"
                        : node.type === "chat"
                          ? "Chat"
                          : node.type === "media"
                            ? "Media"
                            : "Button"}
                    </h2>
                    <h3
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        margin: "0px",
                        color: "#161a1d",
                      }}
                    >
                      Name:
                      <span style={{ fontWeight: "400" }}>
                        {node.data.nodeName}
                      </span>
                    </h3>
                    <p
                      style={{
                        border: "1px lightgrey solid",
                        borderRadius: "4px",
                        padding: "6px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        fontSize: "16px",
                        fontStyle: "italic",
                        backgroundColor: "#E9F6E6",
                        margin: "0px 5px 0px 0px",
                        textAlign: "justify",
                        width: "95%",
                      }}
                    >
                      {node.data.ConditionData}
                    </p>

                    {(node.type === "button" ||
                      node.type === "custom" ||
                      node.type === "media") && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                          }}
                        >
                          { }
                          {node.data.buttonList.map((buttonLabel, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                marginBottom: "4px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    backgroundColor: "#006d77",
                                    borderRadius: "50%",
                                    marginRight: "8px",
                                  }}
                                />
                                <span>{buttonLabel}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                    {node.type !== "button" &&
                      node.type !== "custom" &&
                      node.type !== "media" && (
                        <>
                          <Handle
                            type="source"
                            position="right"
                            id="sourceHandle"
                            style={{
                              background: "#333333",
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              zIndex: 100,
                            }}
                          />
                          <Handle
                            type="target"
                            position="left"
                            id="targetHandle"
                            style={{
                              background: "#333333",
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              zIndex: 100,
                            }}
                          />
                        </>
                      )}
                  </div>
                ),
              },
            };
          } else if (node.type === "IVRNode") {
            return restoreIVRNode(node);
          } else if (node.type === "NewIVR") {
            return restoreNewIVRNode(node);
          } else if (node.type === "CreatedNewIVR") {
            return restoreCreatedNewIVR(node);
          } else if (node.type === "VoiceBotNode") {
            return restoreVoiceBotNode(node);
          } else if (node.type === "NewSmsBroadcast") {
            return restoreNewSmsBroadcastNode(node);
          } else if (
            node.type === "CallBridgeNode" ||
            node.type == "APICallBridge"
          ) {
            return restoreCallBridgeNode(node);
          } else if (node.type === "SmsNode") {
            return restoreSmsNode(node);
          }
          return node;
        });

        setNodes(rehydratedNodes);
        setEdges(localflow.edges);
      }
    }
  };

  const restoreIVRNode = (node) => {
    if (node.type === "IVRNode") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "89% 10%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  alignItems: "flex-start",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  {node.destinationType}
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name :{" "}
                  <span style={{ fontWeight: "400" }}>
                    {" "}
                    {node.data.nodeName}{" "}
                  </span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Type :{node.destinationType}
                </p>
                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Name :<br />
                  {node.destinationName}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3px",
                }}
              >
                {[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "*",
                  "#",
                ].map((value, index) => (
                  <p style={{ margin: "0px" }}>{value}</p>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreNewIVRNode = (node) => {
    if (node.type === "NewIVR") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {node.destinationType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name:
                <span style={{ fontWeight: "400" }}>{node.data.nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Type:
                {node.destinationType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Name: <br />
                {node.destinationName}
              </p>

              <Handle
                type="source"
                position="right"
                id="sourceHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
              <Handle
                type="target"
                position="left"
                id="targetHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreCreatedNewIVR = (node) => {
    if (node.type === "CreatedNewIVR") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "89% 10%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  alignItems: "flex-start",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  IVR
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {node.name}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Description :{node.ivrDescription}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3px",
                }}
              >
                {[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "*",
                  "#",
                ].map((value, index) => (
                  <p style={{ margin: "0px" }}>{value}</p>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreVoiceBotNode = (node) => {
    // console.log(node, "from restore");
    if (node.type === "VoiceBotNode") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {node.conditionType === "message"
                  ? "Message"
                  : node.conditionType === "welcome_message"
                    ? "Welcome Message"
                    : node.conditionType === "connect_agent"
                      ? "Connect To Agent"
                      : node.conditionType === "user_input"
                        ? "User Input"
                        : node.conditionType === "invalid"
                          ? "Invalid Message"
                          : "WhatsApp"}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}>{node.name} </span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Type :{node.conditionType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Data :{node.conditionData}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                {node.data.buttonList.map((buttonLabel, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#006d77",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      <span>{buttonLabel}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreNewSmsBroadcastNode = (node) => {
    if (node.type === "NewSmsBroadcast") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                SMS Broadcast
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}>{node.name} </span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Description :{node.SmsDescription}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                {node.data.buttonList.map((buttonLabel, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#006d77",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      <span>{buttonLabel}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreSmsNode = (node) => {
    if (node.type === "SmsNode") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                API
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name :{" "}
                <span style={{ fontWeight: "400" }}>{node.data.nodeName} </span>
              </h3>

              <Handle
                type="source"
                position="right"
                id="sourceHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
              <Handle
                type="target"
                position="left"
                id="targetHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />

              {/* <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Description :{node.SmsDescription}
              </p> */}
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreCallBridgeNode = (node) => {
    if (node.type === "CallBridgeNode" || node.type === "APICallBridge") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {node.conditionType === "welcome_message"
                  ? "Welcome Message"
                  : "API"}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}>{node.name} </span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "left",
                  width: "95%",
                }}
              >
                Condition Type : {node.conditionType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Data :{node.conditionData}
              </p>

              {node.type === "CallBridgeNode" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {node.data.buttonList.map((buttonLabel, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#006d77",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        />
                        <span>{buttonLabel}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ),
        },
      };
    }
    return node;
  };

  const getVoiceMailIdById = (Id) => {
    const name = destinationNameList.find(
      (voicemail) => voicemail.extension_uuid === Id
    );
    return name ? name.voicemail_id : "";
  };

  const handleEditNode = () => { };

  const EditCreatedNewIVR = () => { };

  const deleteNode = (id) => {
    // <-----------to be used later for dynamic deletion ------------->
    // if (selectedNodeType == "CreatedNewIVR") {
    //   DeleteCreatedNewIVR();
    //   return;
    // }
    if (selectedNodeType == "NewIVR") {
      DeleteNewIVR(id);
      return;
    }
    if (selectedNodeType == "IVRNode") {
      DeleteIVRNode(id);
      return;
    }

    // <------------for temporary deletion ------------>
    // if (channel == "IVR") {
    //   handleDeleteNodeForIVR(id);
    //   return;
    // }

    if (channel == "voiceBot") {
      handleDeleteNodeForVoiceBot(id);
      return;
    }

    setUpdateMode(false);
    setNodeName("");
    setNodeData("");
    setSelectedNodeType("");
    setButtonList([]);
    setQueue("1");
    setSelectedImage("");
  };

  const DeleteCreatedNewIVR = () => { };
  const DeleteNewIVR = async (id) => {
    const result = await GetIVROptionMenuDetails(
      accessToken,
      domainUUID,
      configurationId,
      id
    );

    if (result.Status == "1") {
      const ivr_menu_option_uuid = result.IvrMenuList[0].ivr_menu_option_uuid;

      const DeleteResult = await DeleteIvrOptionById(
        accessToken,
        domainUUID,
        ivr_menu_option_uuid
      );

      if (DeleteResult.Status == "1") {
        setNodes((nds) => nds.filter((node) => node.id !== id));
        setEdges((eds) =>
          eds.filter((edge) => edge.source !== id && edge.target !== id)
        );
        setUpdateMode(false);
        setOpenCreateIvrNodeModal(false);
        setNodeName("");
        setIvrNodeDestinationType("IVR");
        setIvrNodeDestinationName("");
        setApiCurl("");
        setOpenCreateIvrNodeModal(false);
      }
    }
  };
  const DeleteIVRNode = async (id) => {
    const result = await GetIVROptionMenuDetails(
      accessToken,
      domainUUID,
      configurationId,
      id
    );

    if (result.Status == "1") {
      const ivr_menu_option_uuid = result.IvrMenuList[0].ivr_menu_option_uuid;

      const DeleteResult = await DeleteIvrOptionById(
        accessToken,
        domainUUID,
        ivr_menu_option_uuid
      );

      if (DeleteResult.Status == "1") {
        setNodes((nds) => nds.filter((node) => node.id !== id));
        setEdges((eds) =>
          eds.filter((edge) => edge.source !== id && edge.target !== id)
        );
        setUpdateMode(false);
        setOpenCreateIvrNodeModal(false);
        setNodeName("");
        setIvrNodeDestinationType("IVR");
        setIvrNodeDestinationName("");
        setApiCurl("");
        setOpenCreateIvrNodeModal(false);
      }
    }
  };

  const handleDeleteNodeForVoiceBot = async (id) => { };

  const handleNodeClick = (event, node) => {
    if (isTemplate == "true") {
      console.log('inside required function')
      return;
    }
    console.log(node);

    if (channel == "IVR" && node.type != "CreatedNewIVR") {
      setOpenCreateIvrNodeModal(true);
    }
    if (channel == "IVR" && node.type == "CreatedNewIVR") {
      HandleEditIvrFlow(configurationId);
    }
    if (channel == "whatsapp") {
      setOpenCreateWhatsAppNodeModal(true);
      setPayloadType(node.payloadType);
    }
    if (channel == "telegram") {
      setOpenCreateTelegramNodeModal(true);
    }

    if (channel == "voiceBot") {
      setOpenCreateVoicebotNodeModal(true);
      setMediaConfigurationId(node.config_id == "" ? "0" : node.config_id);
    }
    if (channel == "smsBroadcast") {
      setOpenCreateSmsbotNodeModal(true);
      setNodeName(node.data.nodeName);
      setSmsbotApiCurlName(node.Curl);
      return;
    }

    if (node.type == "CallBridgeNode") {
      setOpenCreateCallBridgeNodeModal(true);
      setCallBridgeNodeConditionType(node.conditionType);
      setButtonList(node.buttonList);
      setConditionData(node.conditionData);
      setCallBridgeLanguage(node);
      setCallBridgeVoiceType(node.voiceType);
      setCallBridgeLanguage(node.language);
      getParticularCallBridgeCaseById(node.id);
    }

    if (node.type == "APICallBridge") {
      setOpenCreateCallBridgeNodeModal(true);
      setCallBridgeNodeConditionType(node.conditionType);
      setConditionData(node.conditionData);
      setCallBridgeAPIType(node.apiType);
      setCallBridgeApiEndPoint(node.apiEndPoint);
      getParticularCallBridgeCaseById(node.id);
    }

    setNodeName(node.data.nodeName);
    setSelectedNodeId(node.id);
    setConditionData(node.data.ConditionData);
    setUpdateMode(true);

    if (node.type == "custom") {
      setWhatsappNodeConditionType("button");
      setTelegramNodeConditionType("button");
      setSelectedImage(node.selectedImage);
      setButtonList(node.data.buttonList);
    } else if (node.type == "VoiceBotNode") {
      setVoicebotNodeConditionType(node.conditionType);
      getParticularVoiceBotCaseById(node.id);
    } else if (node.type == "CreatedNewIVR") {
      setSelectedNodeType(node.type);
      getDetailsOfCreatedIVR(node.id);
    } else if (node.type == "IVRNode") {
      setSelectedNodeType(node.type);
      getDetailsOfIVRNode(node.id, node.destinationType);
    } else if (node.type == "NewIVR") {
      setSelectedNodeType(node.type);
      getDetailsOfNewIVR(node.id, node.destinationType);
    } else {
      setWhatsappNodeConditionType(node.type);
      setTelegramNodeConditionType(node.type);
      setSelectedNodeType(node.type);
      setQueue(node.data.queue);
      setSelectedImage(node.selectedImage);
      setButtonList(node.data.buttonList);
    }

    if (node.type == "media") {
      setButtonList(node.button);
    }
  };

  const getDetailsOfCreatedIVR = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/getIvrListUuid/${domainUUID}/${id}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setNodeName(result.IvrList[0].ivr_menu_name);
      })
      .catch((error) => console.error(error));
  };
  const [IvrMenuList, setIvrMenuList] = useState([]);

  const getParticularVoiceBotCaseById = async (nodeId) => {
    const result = await getVoiceBotCaseById(
      accessToken,
      domainUUID,
      configurationId,
      nodeId
    );
    setVoiceBotLanguage(result.VoiceBotCase[0].language);
    setVoiceBotUserInput(result.VoiceBotCase[0].user_input_type);
    setVoiceBotVoiceType(result.VoiceBotCase[0].voice_type);
    setQueueForVoiceBot(result.VoiceBotCase[0].queue_uuid);
    setIDforVoiceBot(result.VoiceBotCase[0].id);
    if (result.VoiceBotCase[0].add_option == "") {
      setButtonList([]);
    } else {
      const optionList = JSON.parse(result.VoiceBotCase[0].add_option);
      setButtonList(optionList);
    }
  };

  const getParticularCallBridgeCaseById = async (nodeId) => {
    const result = await getCallBridgeCaseById(
      accessToken,
      domainUUID,
      configurationId,
      nodeId
    );

    setIDforCallBridge(result.CallBridgeCase[0].id);
  };

  const handleSaveLayout = () => {
    const LocalFlow = { nodes, edges };
    localStorage.setItem("LocalFlow", JSON.stringify(LocalFlow));
  };

  const handleRestoreLayout = () => {
    const LocalFlow = JSON.parse(localStorage.getItem("LocalFlow"));

    if (LocalFlow) {
      const rehydratedNodes = LocalFlow.nodes.map((node) => {
        if (node.type === "NewSmsBroadcast") {
          return restoreNewSmsBroadcastNode(node);
        } else if (node.type === "SmsNode") {
          return restoreSmsNode(node);
        } else if (node.type === "IVRNode") {
          return restoreIVRNode(node);
        } else if (node.type === "NewIVR") {
          return restoreNewIVRNode(node);
        } else if (node.type === "CreatedNewIVR") {
          return restoreCreatedNewIVR(node);
        } else if (node.type === "VoiceBotNode") {
          return restoreVoiceBotNode(node);
        } else if (
          node.type === "CallBridgeNode" ||
          node.type == "APICallBridge"
        ) {
          return restoreCallBridgeNode(node);
        } else if (
          //whatsapp
          node.type === "message" ||
          node.type === "chat" ||
          node.type === "button" ||
          node.type === "custom" ||
          node.type === "media"
        ) {
          return {
            ...node,
            data: {
              ...node.data,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid",
                      paddingBottom: "3px",
                    }}
                  >
                    {node.type === "message"
                      ? "Message"
                      : node.type === "chat"
                        ? "Chat"
                        : node.type === "media"
                          ? "Media"
                          : "Button"}
                  </h2>
                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name:
                    <span style={{ fontWeight: "400" }}>
                      {node.data.nodeName}
                    </span>
                  </h3>
                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    {node.data.ConditionData}
                  </p>
                  {node.type === "button" ||
                    (node.type === "custom" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        { }
                        {node.data.buttonList.map((buttonLabel, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginBottom: "4px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  backgroundColor: "#006d77",
                                  borderRadius: "50%",
                                  marginRight: "8px",
                                }}
                              />
                              <span>{buttonLabel}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}

                  {node.type !== "button" && node.type !== "custom" && (
                    <>
                      <Handle
                        type="source"
                        position="right"
                        id="sourceHandle"
                        style={{
                          background: "#333333",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          zIndex: 100,
                        }}
                      />
                      <Handle
                        type="target"
                        position="left"
                        id="targetHandle"
                        style={{
                          background: "#333333",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          zIndex: 100,
                        }}
                      />
                    </>
                  )}
                </div>
              ),
            },
          };
        }
        return node;
      });

      setNodes(rehydratedNodes);
      setEdges(LocalFlow.edges);
    }
  };

  // <--------------------For IVR ------------------->

  const [promptList, setPromptList] = useState("");
  const [destinationNameList, setDestinationNameList] = useState("");
  const [IDforVoiceBot, setIDforVoiceBot] = useState("");

  const getIvrList = async () => {
    const result = await showIvrConfigurationList(accessToken, domainUUID);
    if (result.Status == "1") {
      setConfigurationList(result.IvrList);
      setDestinationNameList(result.IvrList);
    }
  };

  useEffect(() => {
    if (configurationId == "createIvr") {
      showPromptList();
    }
  }, [configurationId]);

  const showPromptList = async () => {
    const result = await ShowPromptList(accessToken, domainUUID);
    if (result.Status == "1") {
      setPromptList(result.prompt_list);
      setDestinationNameList(result.prompt_list);
    }
  };

  const showQueueListForIvr = async () => {
    const result = await ShowQueueListForIvr(accessToken, domainUUID);
    setDestinationNameList(result);

    const filteredResultforVoice = result.filter(
      (item) => item.is_enable === "true"
    );
    setQueueListforVoiceBot(filteredResultforVoice);
  };

  const FetchExtensionList = async () => {
    const result = await ShowExtensionsList(accessToken, domainUUID);

    const filteredResult = result.filter(
      (item) => item.voicemail_enable === "true"
    );
    setDestinationNameList(filteredResult);
    setExitNameList(filteredResult);
  };

  const VoiceBotConfigurationList = async () => {
    const result = await showVoiceBotConfigurationList(accessToken, domainUUID);
    if (result.Status == "1") {
      setDestinationNameList(result.VoiceBotConfiguration);
    }
  };

  const showKnowledgeBaseList = async () => {
    const result = await KnowledgeBaseList(accessToken, domainUUID);
    if (result.Status == "1") {
      setDestinationNameList(result.KnowledgeBase);
    }
  };

  const getFileLocationById = (Id) => {
    const name = promptList.find(
      (fileLocation) => fileLocation.pmt_uuid === Id
    );
    return name ? name.file_location : "";
  };

  const getVoiceMailIdByName = (voicename) => {
    const name = destinationNameList.find(
      (voicemail) => voicemail.extension === voicename
    );
    return name ? name.extension_uuid : "";
  };

  const getQueueNameById = (Id) => {
    const name = queueList.find((queue) => queue.queue_uuid === Id);
    return name ? name.name : "";
  };

  // <----------for background --------->
  const [backgroundVariant, setBackgroundVariant] = useState("cross");

  const handleVariantChange = (event) => {
    setBackgroundVariant(event.target.value);
  };

  // Add nodes button
  const handleModalForAddNode = () => {
    if (channel == "IVR") {
      setOpenCreateIvrNodeModal(true);
    }
    if (channel == "whatsapp") {
      setOpenCreateWhatsAppNodeModal(true);
    }
    if (channel == "voiceBot") {
      setOpenCreateVoicebotNodeModal(true);
    }
    if (channel == "smsBroadcast") {
      setOpenCreateSmsbotNodeModal(true);
    }
    if (channel == "telegram") {
      setOpenCreateTelegramNodeModal(true);
    }
    if (channel == "callBridge") {
      setOpenCreateCallBridgeNodeModal(true);
    }
  };

  // <-----------------for voicebot----------------->
  const [voiceBotLanguage, setVoiceBotLanguage] = useState("en-US");
  const [voiceBotVoiceType, setVoiceBotVoiceType] = useState("en-US-Casual-K");
  const [voiceBotUserInput, setVoiceBotUserInput] = useState("");
  const [queueListforVoiceBot, setQueueListforVoiceBot] = useState("");
  const [queueForVoiceBot, setQueueForVoiceBot] = useState("");

  // STATES FOR CREATING IVR NODE MODAL
  const [OpenCreateIvrNodeModal, setOpenCreateIvrNodeModal] = useState(false);
  const [IvrNodeDestinationType, setIvrNodeDestinationType] = useState("IVR"); // Default selected menu
  const [IvrNodeDestinationName, setIvrNodeDestinationName] = useState(""); // Track the selected sub-menu
  const [apicurl, setApiCurl] = useState("");

  const IvrDestinationTypeItems = [
    { name: "IVR", icon: <RecordVoiceOver id="MenuIcon" /> },
    { name: "QUEUE", icon: <TransferWithinAStation id="MenuIcon" /> },
    { name: "PLAYBACK", icon: <LowPriority id="MenuIcon" /> },
    { name: "VOICEMAIL", icon: <Voicemail id="MenuIcon" /> },
    { name: "HANGUP", icon: <CallEnd id="MenuIcon" /> },
    { name: "CALLBACK", icon: <PhoneCallback id="MenuIcon" /> },
    { name: "VOICEBOT", icon: <MicNone id="MenuIcon" /> },
    { name: "AIAGENT", icon: <SupportAgent id="MenuIcon" /> },
    { name: "API", icon: <Apartment id="MenuIcon" /> },
  ];

  useEffect(() => {
    if (IvrNodeDestinationType == "IVR") {
      getIvrList();
    }
    if (IvrNodeDestinationType == "QUEUE") {
      showQueueListForIvr();
    }
    if (IvrNodeDestinationType == "PLAYBACK") {
      showPromptList();
    }
    if (IvrNodeDestinationType == "VOICEMAIL") {
      FetchExtensionList();
    }
    if (IvrNodeDestinationType == "HANGUP") {
      setDestinationNameList([
        {
          HangupId: "hangup",
          HangupName: "Hangup",
        },
      ]);
    }
    if (IvrNodeDestinationType == "CALLBACK") {
      setDestinationNameList([
        {
          CallbackId: "callback",
          CallbackName: "Callback",
        },
      ]);
    }
    if (IvrNodeDestinationType == "VOICEBOT") {
      VoiceBotConfigurationList();
    }
    if (IvrNodeDestinationType == "AIAGENT") {
      showKnowledgeBaseList();
    }
    if (IvrNodeDestinationType == "API") {
      setDestinationNameList([]);
    }
  }, [IvrNodeDestinationType]);

  const HandleIvrNodeDestinationTypeClick = (type) => {
    setIvrNodeDestinationType(type);
    setIvrNodeDestinationName(""); // Clear sub-menu selection on new menu click
  };

  const HandleIvrNodeDestinationNameClick = (name) => {
    setIvrNodeDestinationName(name);
  };

  const getDestinationNameById = (Id) => {
    if (IvrNodeDestinationType == "IVR") {
      const name = destinationNameList.find(
        (destination) => destination.ivr_menu_uuid === Id
      );
      return name ? name.ivr_menu_name : "";
    }
    if (IvrNodeDestinationType == "QUEUE") {
      const name = destinationNameList.find(
        (destination) => destination.call_center_queue_uuid === Id
      );
      return name ? name.queue_name : "";
    }
    if (IvrNodeDestinationType == "PLAYBACK") {
      const name = destinationNameList.find(
        (destination) => destination.pmt_uuid === Id
      );
      return name ? name.prompt_name : "";
    }
    if (IvrNodeDestinationType == "VOICEMAIL") {
      const name = destinationNameList.find(
        (destination) => destination.extension_uuid === Id
      );
      return name ? name.extension : "";
    }
    if (IvrNodeDestinationType == "HANGUP") {
      const name = destinationNameList.find(
        (destination) => destination.HangupId === Id
      );
      return name ? name.HangupName : "";
    }
    if (IvrNodeDestinationType == "CALLBACK") {
      const name = destinationNameList.find(
        (destination) => destination.CallbackId === Id
      );
      return name ? name.CallbackName : "";
    }
    if (IvrNodeDestinationType == "VOICEBOT") {
      const name = destinationNameList.find(
        (destination) => destination.configuration_uuid === Id
      );
      return name ? name.configuration_name : "";
    }
    if (IvrNodeDestinationType == "AIAGENT") {
      const name = destinationNameList.find(
        (destination) => destination.knowledge_base_uuid === Id
      );
      return name ? name.doc_name : "";
    }
  };

  const HandleIvrCreateNodeModalCancelButton = () => {
    if (updateMode) {
      setUpdateMode(false);
    }
    setIvrNodeDestinationType("IVR");
    setIvrNodeDestinationName("");
    setApiCurl("");
    setOpenCreateIvrNodeModal(false);
    setNodeName("");
  };

  const HandleIvrCreateNodeModalButton = async () => {
    if (nodeName == "" && IvrNodeDestinationName != "") {
      toast.info("Please Enter the Required Fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (IvrNodeDestinationName == "" && nodeName != "") {
      toast.info("Please Select Destination Name", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (nodeName == "" || IvrNodeDestinationName == "") {
      toast.info("Missing Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    var IVR_MENU_OPTION_PARAM;
    if (IvrNodeDestinationType == "IVR") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML IVR`;
    } else if (IvrNodeDestinationType == "QUEUE") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML callcenter`;
    } else if (IvrNodeDestinationType == "PLAYBACK") {
      const value = getFileLocationById(IvrNodeDestinationName).substring(
        getFileLocationById(IvrNodeDestinationName).lastIndexOf("/") + 1
      );

      IVR_MENU_OPTION_PARAM = `transfer ${value} XML PLAYBACK`;
    } else if (IvrNodeDestinationType == "VOICEMAIL") {
      IVR_MENU_OPTION_PARAM = `transfer ${getVoiceMailIdById(
        IvrNodeDestinationName
      )} XML VOICEMAIL`;
    } else if (IvrNodeDestinationType == "HANGUP") {
      IVR_MENU_OPTION_PARAM = `hangup`;
    } else if (IvrNodeDestinationType == "CALLBACK") {
      IVR_MENU_OPTION_PARAM = `transfer callback XML CALLBACK`;
    } else if (IvrNodeDestinationType == "VOICEBOT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML voicebot`;
    } else if (IvrNodeDestinationType == "AIAGENT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML ai_agent`;
    }

    const result = await addIvrOption(
      accessToken,
      domainUUID,
      configurationId,
      IVR_MENU_OPTION_PARAM,
      nodeName
    );

    if (result.Status === "0") {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 3000,
      });
    } else if (result.Status === "1") {
      const NodeId = result.NodeId;
      // if destination type is ivr then make IVR type node(with 12 source nodes) --->IVRNode
      if (IvrNodeDestinationType == "IVR") {
        const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

        const newNodePosition = lastNode
          ? {
            x: lastNode.position.x + 300, // Offset horizontally from the last node
            y: lastNode.position.y, // Offset vertically from the last node
          }
          : { x: 100, y: 100 };

        const newNode = {
          id: `${NodeId}`,
          type: "IVRNode",
          style: { width: "fit-content" },
          position: newNodePosition,
          destinationType: IvrNodeDestinationType,
          destinationName: getDestinationNameById(IvrNodeDestinationName),
          markerEnd: {
            type: MarkerType.ArrowClosed, // Closed arrow at the end
            width: 30,
            height: 30,
            color: "black",
          },
          data: {
            nodeName,
            label: (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "89% 10%",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    alignItems: "flex-start",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid ",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />
                    {IvrNodeDestinationType}
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :{" "}
                    <span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Destination Type : {IvrNodeDestinationType}
                  </p>
                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Destination Name :<br />
                    {getDestinationNameById(IvrNodeDestinationName)}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "3px",
                  }}
                >
                  {[
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "*",
                    "#",
                  ].map((value, index) => (
                    <p style={{ margin: "0px" }}>{value}</p>
                  ))}
                </div>
              </div>
            ),
          },

          sourcePosition: "right",
          targetPosition: "left",
        };
        setNodes((nds) => [...nds, newNode]);
        setNodeName("");
        setIvrNodeDestinationType("IVR");
        setIvrNodeDestinationName("");
        setApiCurl("");
        setOpenCreateIvrNodeModal(false);
      } else {
        const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

        const newNodePosition = lastNode
          ? {
            x: lastNode.position.x + 300, // Offset horizontally from the last node
            y: lastNode.position.y, // Offset vertically from the last node
          }
          : { x: 100, y: 100 };

        // if destination type is other than IVR then make normal node(with 1 source node)---->NEWIVR
        const newNode = {
          id: `${NodeId}`,
          type: "NewIVR",
          style: { width: "fit-content" },

          position: newNodePosition,
          destinationType: IvrNodeDestinationType,
          destinationName: getDestinationNameById(IvrNodeDestinationName),
          data: {
            nodeName,
            label: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "250px",
                  rowGap: "6px",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  {IvrNodeDestinationType}
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Type : {IvrNodeDestinationType}
                </p>
                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Name :<br />
                  {getDestinationNameById(IvrNodeDestinationName)}
                </p>
                <Handle
                  type="source"
                  position="right"
                  id="sourceHandle"
                  style={{
                    background: "#333333",
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    zIndex: 100,
                  }}
                />
                <Handle
                  type="target"
                  position="left"
                  id="targetHandle"
                  style={{
                    background: "#333333",
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    zIndex: 100,
                  }}
                />
              </div>
            ),
            buttonList: selectedNodeType === "button" ? buttonList : [],
          },
          sourcePosition: "right",
          targetPosition: "left",
        };
        setNodes((nds) => [...nds, newNode]);
        setNodeName("");
        setIvrNodeDestinationType("IVR");
        setIvrNodeDestinationName("");
        setApiCurl("");
        setOpenCreateIvrNodeModal(false);
      }
    }
  };

  const handleIvrEditNodeModalButton = () => {
    if (selectedNodeType == "CreatedNewIVR") {
      EditCreatedNewIVR();
      return;
    } else if (selectedNodeType == "NewIVR") {
      EditNewIVR();
      return;
    } else if (selectedNodeType == "IVRNode") {
      EditIVRNode();
      return;
    }
  };

  const EditNewIVR = async () => {
    var IVR_MENU_OPTION_PARAM;
    if (IvrNodeDestinationType == "IVR") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML IVR`;
    } else if (IvrNodeDestinationType == "QUEUE") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML callcenter`;
    } else if (IvrNodeDestinationType == "PLAYBACK") {
      const value = getFileLocationById(IvrNodeDestinationName).substring(
        getFileLocationById(IvrNodeDestinationName).lastIndexOf("/") + 1
      );

      IVR_MENU_OPTION_PARAM = `transfer ${value} XML PLAYBACK`;
    } else if (IvrNodeDestinationType == "VOICEMAIL") {
      IVR_MENU_OPTION_PARAM = `transfer ${getVoiceMailIdById(
        IvrNodeDestinationName
      )} XML VOICEMAIL`;
    } else if (IvrNodeDestinationType == "HANGUP") {
      IVR_MENU_OPTION_PARAM = `hangup`;
    } else if (IvrNodeDestinationType == "CALLBACK") {
      IVR_MENU_OPTION_PARAM = `transfer callback XML CALLBACK`;
    } else if (IvrNodeDestinationType == "VOICEBOT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML voicebot`;
    } else if (IvrNodeDestinationType == "AIAGENT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML ai_agent`;
    }

    const result = await updateIvrOption(
      accessToken,
      domainUUID,
      IvrMenuList,
      IVR_MENU_OPTION_PARAM,
      nodeName,
      IvrNodeDestinationName
    );

    if (result.Status == "1") {
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      // const newNodePosition = lastNode
      //   ? {
      //       x: lastNode.position.x + 300, // Offset horizontally from the last node
      //       y: lastNode.position.y, // Offset vertically from the last node
      //     }
      //   : { x: 100, y: 100 };

      const nodeIdToFind = selectedNodeId;
      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        type: "NewIVR",
        style: { width: "fit-content" },
        position: newNodePosition,
        destinationType: IvrNodeDestinationType,
        destinationName: getDestinationNameById(IvrNodeDestinationName),
        data: {
          nodeName,
          nodeData,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {IvrNodeDestinationType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Type : {IvrNodeDestinationType}
              </p>
              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Name :<br />
                {getDestinationNameById(IvrNodeDestinationName)}
              </p>
              <Handle
                type="source"
                position="right"
                id="sourceHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
              <Handle
                type="target"
                position="left"
                id="targetHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
            </div>
          ),
          buttonList: selectedNodeType === "button" ? buttonList : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setUpdateMode(false);
      setOpenCreateIvrNodeModal(false);
      setIvrNodeDestinationType("IVR");
      setIvrNodeDestinationName("");
      setApiCurl("");
      setNodeName("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const EditIVRNode = async () => {
    var IVR_MENU_OPTION_PARAM;
    if (IvrNodeDestinationType == "IVR") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML IVR`;
    } else if (IvrNodeDestinationType == "QUEUE") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML callcenter`;
    } else if (IvrNodeDestinationType == "PLAYBACK") {
      const value = getFileLocationById(IvrNodeDestinationName).substring(
        getFileLocationById(IvrNodeDestinationName).lastIndexOf("/") + 1
      );

      IVR_MENU_OPTION_PARAM = `transfer ${value} XML PLAYBACK`;
    } else if (IvrNodeDestinationType == "VOICEMAIL") {
      IVR_MENU_OPTION_PARAM = `transfer ${getVoiceMailIdById(
        IvrNodeDestinationName
      )} XML VOICEMAIL`;
    } else if (IvrNodeDestinationType == "HANGUP") {
      IVR_MENU_OPTION_PARAM = `hangup`;
    } else if (IvrNodeDestinationType == "CALLBACK") {
      IVR_MENU_OPTION_PARAM = `transfer callback XML CALLBACK`;
    } else if (IvrNodeDestinationType == "VOICEBOT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML voicebot`;
    } else if (IvrNodeDestinationType == "AIAGENT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML ai_agent`;
    }

    const result = await updateIvrOption(
      accessToken,
      domainUUID,
      IvrMenuList,
      IVR_MENU_OPTION_PARAM,
      nodeName,
      IvrNodeDestinationName
    );

    if (result.Status == "1") {
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      // const newNodePosition = lastNode
      //   ? {
      //       x: lastNode.position.x + 300,
      //       y: lastNode.position.y,
      //     }
      //   : { x: 100, y: 100 };

      const nodeIdToFind = selectedNodeId;
      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        type: "IVRNode",
        style: { width: "fit-content" },
        position: newNodePosition,
        destinationType: IvrNodeDestinationType,
        destinationName: getDestinationNameById(IvrNodeDestinationName),

        data: {
          nodeName,
          // nodeData,
          label: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "89% 10%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  alignItems: "flex-start",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  {IvrNodeDestinationType}
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Type : {IvrNodeDestinationType}
                </p>
                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Name :<br />
                  {getDestinationNameById(IvrNodeDestinationName)}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3px",
                }}
              >
                {[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "*",
                  "#",
                ].map((value, index) => (
                  <p style={{ margin: "0px" }}>{value}</p>
                ))}
              </div>
            </div>
          ),
        },

        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setUpdateMode(false);
      setOpenCreateIvrNodeModal(false);
      setIvrNodeDestinationType("IVR");
      setIvrNodeDestinationName("");
      setApiCurl("");
      setNodeName("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const getDetailsOfIVRNode = (id, destinationType) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/get-ivr-option-menu-details-by-ivr-menu_options_uuid/${configurationId}/${id}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIvrMenuList(result.IvrMenuList);

        setIvrNodeDestinationType(destinationType);
        const inputString = result.IvrMenuList[0].ivr_menu_option_param;

        if (inputString.startsWith("transfer ")) {
          const fetchedValue = inputString.split(" ")[1];
          setIvrNodeDestinationName(fetchedValue);
        }
      })
      .catch((error) => console.error(error));
  };

  const getDetailsOfNewIVR = (id, destinationType) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/get-ivr-option-menu-details-by-ivr-menu_options_uuid/${configurationId}/${id}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIvrMenuList(result.IvrMenuList);

        setIvrNodeDestinationType(destinationType);
        const inputString = result.IvrMenuList[0].ivr_menu_option_param;

        if (inputString.startsWith("transfer ")) {
          const fetchedValue = inputString.split(" ")[1];

          if (destinationType == "VOICEMAIL") {
            FetchExtensionList();
            setIvrNodeDestinationName(getVoiceMailIdByName(fetchedValue));
          } else if (destinationType == "PLAYBACK") {
            const trimmedValue = fetchedValue.split(".")[0];
            setIvrNodeDestinationName(trimmedValue);
          } else {
            setIvrNodeDestinationName(fetchedValue);
          }
        } else {
          if (destinationType == "HANGUP") {
            setIvrNodeDestinationName("hangup");
          } else if (destinationType == "CALLBACK") {
            setIvrNodeDestinationName("callback");
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const HandleIvrDeleteNodeModal = (id) => {
    // <-----------to be used later for dynamic deletion ------------->
    // if (selectedNodeType == "CreatedNewIVR") {
    //   DeleteCreatedNewIVR();
    //   return;
    // }
    if (selectedNodeType == "NewIVR") {
      DeleteNewIVR(id);
      return;
    }
    if (selectedNodeType == "IVRNode") {
      DeleteIVRNode(id);
      return;
    }
  };

  // STATES FOR CREATING THE WHATSAPP NODE MODAL
  const [OpenCreateWhatsAppNodeModal, setOpenCreateWhatsAppNodeModal] =
    useState(false);
  const [WhatsappNodeConditionType, setWhatsappNodeConditionType] =
    useState("message"); // Default selected menu
  const [ConditionData, setConditionData] = useState("");
  const [WhatsappMediaLoader, setWhatsappMediaLoader] = useState(false);

  const WhatsappConditionTypeItems = [
    { name: "message", icon: <MarkUnreadChatAlt id="MenuIcon" /> },
    { name: "chat", icon: <QuestionAnswer id="MenuIcon" /> },
    { name: "button", icon: <TouchApp id="MenuIcon" /> },
    { name: "API", icon: <Api id="MenuIcon" /> },
    { name: "media", icon: <Subscriptions id="MenuIcon" /> },
  ];

  const WhatsappApiMethodItems = [
    { name: "Get", value: "GET" },
    { name: "Post", value: "POST" },
    { name: "Put", value: "PUT" },
    { name: "Delete", value: "DELETE" },
  ];

  const MediaTypeItems = [
    { name: "Image", value: "image", icon: <Image id="MediaIcon" /> },
    { name: "Video", value: "video", icon: <Videocam id="MediaIcon" /> },
    { name: "Audio", value: "audio", icon: <AudioFile id="MediaIcon" /> },
    {
      name: "Document",
      value: "document",
      icon: <Description id="MediaIcon" />,
    },
  ];

  const HandleWhatsappNodeConditionTypeClick = (type) => {
    setWhatsappNodeConditionType(type);
    setConditionData("");
    setQueue("");
    setSelectedImage("");
    setButtonList([]);
    setButtonText("");
    setConditionAPI("");
    setMethod("");
    setPayload("");
    setPayloadType("");
  };

  const HandleWhatsappCreateNodeModalCancelButton = () => {
    setOpenCreateWhatsAppNodeModal(false);
    setUpdateMode(false);
    setWhatsappNodeConditionType("message");
    setNodeName("");
    setConditionData("");
    setQueue("");
    setSelectedImage("");
    setButtonList([]);
    setButtonText("");
    setConditionAPI("");
    setPayload("");
    setMethod("");
    setPayloadType("");
  };

  const CreateWhatsAppFunction = async (file_path) => {
    const convertedButtonList = JSON.stringify(
      buttonList.map((item) => [item])
    );

    const result = await addChatbotCase(
      accessToken,
      domainUUID,
      WhatsappNodeConditionType,
      ConditionData,
      conditionAPI,
      method,
      payloadType,
      payload,
      convertedButtonList,
      selectedImage,
      configurationId,
      queue,
      channel,
      nodeName,
      file_path
    );

    if (result.Status == "1") {
      const NodeId = result.NodeId;
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      const newNodePosition = lastNode
        ? {
          x: lastNode.position.x + 300,
          y: lastNode.position.y,
        }
        : { x: 100, y: 100 };
      const newNode = {
        id: `${NodeId}`,
        type:
          WhatsappNodeConditionType === "button"
            ? "custom"
            : WhatsappNodeConditionType === "media"
              ? "media"
              : WhatsappNodeConditionType,
        selectedImage: selectedImage,
        payloadType: payloadType,
        button: buttonList,
        style: { width: "fit-content" },
        position: newNodePosition,
        markerEnd: {
          type: MarkerType.ArrowClosed, // Closed arrow at the end
          width: 30,
          height: 30,
          color: "black",
        },
        data: {
          nodeName,
          ConditionData,
          queue,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {WhatsappNodeConditionType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name :<span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                {ConditionData}
              </p>

              {(WhatsappNodeConditionType === "button" ||
                WhatsappNodeConditionType === "media") && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {buttonList.map((buttonLabel, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#006d77",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                          />
                          <span>{buttonLabel}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              {WhatsappNodeConditionType != "button" &&
                WhatsappNodeConditionType != "media" && (
                  <>
                    <Handle
                      type="source"
                      position="right"
                      id="sourceHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                    <Handle
                      type="target"
                      position="left"
                      id="targetHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                  </>
                )}
            </div>
          ),
          buttonList:
            WhatsappNodeConditionType === "button" ||
              WhatsappNodeConditionType === "media"
              ? buttonList
              : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setWhatsappMediaLoader(false);
      setOpenCreateWhatsAppNodeModal(false);
      setWhatsappNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
      toast.success("Node created successfully!", {
        position: "top-right",
        autoClose: 3000,
      });
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }
  };

  const EditWhatsAppFunction = async (file_path) => {
    const convertedButtonList = JSON.stringify(
      buttonList.map((item) => [item])
    );

    const result = await updateChatbotCase(
      domainUUID,
      accessToken,
      selectedNodeId,
      WhatsappNodeConditionType,
      ConditionData,
      conditionAPI,
      method,
      payloadType,
      payload,
      convertedButtonList,
      selectedImage,
      configurationId,
      queue,
      channel,
      nodeName,
      file_path
    );

    if (result.Status == "1") {
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      const nodeIdToFind = selectedNodeId;

      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        selectedImage: selectedImage,
        type:
          WhatsappNodeConditionType === "button"
            ? "custom"
            : WhatsappNodeConditionType === "media"
              ? "media"
              : WhatsappNodeConditionType,
        style: { width: "fit-content" },
        button: buttonList,
        position: newNodePosition,
        payloadType: payloadType,
        data: {
          nodeName,
          ConditionData,
          queue,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />

                {WhatsappNodeConditionType == "custom"
                  ? "button"
                  : WhatsappNodeConditionType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                {ConditionData}
              </p>

              {(WhatsappNodeConditionType === "button" ||
                WhatsappNodeConditionType === "custom" ||
                WhatsappNodeConditionType === "media") && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {buttonList.map((buttonLabel, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#006d77",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                          />
                          <span>{buttonLabel}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              {WhatsappNodeConditionType != "button" &&
                WhatsappNodeConditionType != "custom" &&
                WhatsappNodeConditionType != "media" && (
                  <>
                    <Handle
                      type="source"
                      position="right"
                      id="sourceHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                    <Handle
                      type="target"
                      position="left"
                      id="targetHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                  </>
                )}
            </div>
          ),
          buttonList:
            WhatsappNodeConditionType === "button" ||
              WhatsappNodeConditionType === "custom" ||
              WhatsappNodeConditionType == "media"
              ? buttonList
              : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setWhatsappMediaLoader(false);
      setOpenCreateWhatsAppNodeModal(false);
      setUpdateMode(false);
      setWhatsappNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
      toast.success("Node edited successfully!", {
        position: "top-right",
        autoClose: 3000,
      });
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
      setWhatsappMediaLoader(false);
      return;
    }
  };

  const HandleWhatsAppCreateNodeButton = () => {
    setWhatsappMediaLoader(true);

    if (nodeName == "" || ConditionData == "") {
      toast.warn("Please Enter the Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }

    if (WhatsappNodeConditionType == "chat" && queue == "") {
      toast.warn("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }

    if (
      (WhatsappNodeConditionType == "button" ||
        WhatsappNodeConditionType == "custom" ||
        WhatsappNodeConditionType == "media") &&
      selectedImage == ""
    ) {
      toast.warn("Please select one layout!", {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }

    if (
      (WhatsappNodeConditionType == "button" ||
        WhatsappNodeConditionType == "custom" ||
        WhatsappNodeConditionType == "media") &&
      selectedImage != "" &&
      buttonList.length == 0
    ) {
      toast.warn("Please add atleast 1 button!", {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }

    if (WhatsappNodeConditionType == "media") {
      HandleWhatsappFileSubmit();
    } else {
      CreateWhatsAppFunction();
    }
  };

  const HandleWhatsAppEditNodeButton = () => {
    setWhatsappMediaLoader(true);

    if (nodeName == "" || ConditionData == "") {
      toast.warn("Please Enter the Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }

    if (WhatsappNodeConditionType == "chat" && queue == "") {
      toast.warn("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }

    if (
      (WhatsappNodeConditionType == "button" ||
        WhatsappNodeConditionType == "custom" ||
        WhatsappNodeConditionType == "media") &&
      selectedImage == ""
    ) {
      toast.warn("Please select one layout!", {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }

    if (
      (WhatsappNodeConditionType == "button" ||
        WhatsappNodeConditionType == "custom" ||
        WhatsappNodeConditionType == "media") &&
      selectedImage != "" &&
      buttonList.length == 0
    ) {
      toast.warn("Please add atleast 1 button!", {
        position: "top-right",
        autoClose: 3000,
      });
      setWhatsappMediaLoader(false);
      return;
    }

    if (WhatsappNodeConditionType == "media") {
      HandleWhatsappFileSubmit();
    } else {
      EditWhatsAppFunction();
    }
  };

  const HandleWhatsappDeleteNodeModal = async (id) => {
    const result = await DeleteChatbotCase(
      domainUUID,
      accessToken,
      id,
      channel
    );

    if (result.Status == "1") {
      setNodes((nds) => nds.filter((node) => node.id !== id));
      setEdges((eds) =>
        eds.filter((edge) => edge.source !== id && edge.target !== id)
      );
      setUpdateMode(false);
      setOpenCreateWhatsAppNodeModal(false);
      setWhatsappNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  // STATES FOR CREATING THE VOICE BOT NODES MODAL
  const [OpenCreateVoicebotNodeModal, setOpenCreateVoicebotNodeModal] =
    useState(false);
  const [VoicebotNodeConditionType, setVoicebotNodeConditionType] =
    useState("message");

  const [WhatsAppConfigurationList, setWhatsAppConfigurationList] = useState(
    []
  );
  const [MediaConfigurationId, setMediaConfigurationId] = useState("0");
  const [VoiceBotMediaLoader, setVoiceBotMediaLoader] = useState(false);

  const fetchWhatsappFlowsList = async () => {
    const result = await getWhatsappConfiguration_API(accessToken, domainUUID);
    if (result.Status == "1") {
      setWhatsAppConfigurationList(result.Whatsapp);
    }
  };

  useEffect(() => {
    if (channel == "whatsapp" || channel == "voiceBot") {
      fetchWhatsappFlowsList();
    }
  }, []);

  const VoicebotConditionTypeItems = [
    {
      name: "Message",
      icon: <MarkUnreadChatAlt id="MenuIcon" />,
      value: "message",
    },
    {
      name: "Welcome Message",
      icon: <MarkChatRead id="MenuIcon" />,
      value: "welcome_message",
    },
    {
      name: "Connect To Agent",
      icon: <ConnectWithoutContact id="MenuIcon" />,
      value: "connect_agent",
    },
    {
      name: "User Input",
      icon: <RecordVoiceOver id="MenuIcon" />,
      value: "user_input",
    },
    {
      name: "Invalid Message",
      icon: <SmsFailed id="MenuIcon" />,
      value: "invalid",
    },
    {
      name: "WhatsApp",
      icon: <WhatsApp id="MenuIcon" />,
      value: "whatsapp",
    },
  ];

  useEffect(() => {
    if (VoicebotNodeConditionType == "connect_agent") {
      showQueueListForIvr();
    }
  }, [VoicebotNodeConditionType]);

  const HandleVoicebotNodeConditionTypeClick = (type) => {
    setVoicebotNodeConditionType(type);
    setConditionData("");
    setVoiceBotLanguage("en-US");
    setVoiceBotVoiceType("en-US-Casual-K");
    setButtonText("");
    setButtonList([]);
    setVoiceBotUserInput("");
    setQueueForVoiceBot("");
  };

  const CreateVoiceBotFunction = async (file_path) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json, text/plain, */*");
      myHeaders.append("Accept-Language", "en-US,en;q=0.9");
      myHeaders.append("Authorization", `Bearer ${accessToken} `);
      myHeaders.append("Connection", "keep-alive");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
      );

      myHeaders.append("Sec-Fetch-Dest", "empty");
      myHeaders.append("Sec-Fetch-Mode", "cors");
      myHeaders.append("Sec-Fetch-Site", "same-origin");
      myHeaders.append(
        "User-Agent",
        "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
      );
      myHeaders.append(
        "sec-ch-ua",
        '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
      );
      myHeaders.append("sec-ch-ua-mobile", "?0");
      myHeaders.append("sec-ch-ua-platform", '"Linux"');

      const optionsList = JSON.stringify(buttonList);

      const raw = JSON.stringify({
        domain_uuid: domainUUID,
        condition: "",
        condition_type: VoicebotNodeConditionType,
        condition_data: ConditionData,
        language: voiceBotLanguage,
        configuration_uuid: configurationId,
        voice_type:
          VoicebotNodeConditionType == "whatsapp"
            ? file_path
            : voiceBotVoiceType,
        queue_uuid: queueForVoiceBot,
        user_input_type: voiceBotUserInput,
        add_option: optionsList,
        config_id:
          VoicebotNodeConditionType == "whatsapp"
            ? MediaConfigurationId.toString()
            : "",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//tenant/create-voicebot-case?user_uuid=${domainUUID}`,
        requestOptions
      );

      const result = await response.json();

      if (result.Status == "1") {
        const NodeId = result.NodeId;
        const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

        const newNodePosition = lastNode
          ? {
            x: lastNode.position.x + 400, // Offset horizontally from the last node
            y: lastNode.position.y, // Offset vertically from the last node
          }
          : { x: 100, y: 100 };
        const newNode = {
          id: `${NodeId}`,
          type: "VoiceBotNode",
          style: { width: "fit-content" },
          // position: { x: Math.random() * 200, y: Math.random() * 200 },
          markerEnd: {
            type: MarkerType.ArrowClosed, // Closed arrow at the end
            width: 30,
            height: 30,
            color: "black",
          },
          position: newNodePosition,
          name: nodeName,
          conditionType: VoicebotNodeConditionType,
          conditionData: ConditionData,
          UserInput: voiceBotUserInput,
          buttonList: buttonList,
          config_id: MediaConfigurationId,
          data: {
            nodeName,
            ConditionData,
            label: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "250px",
                  rowGap: "6px",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />

                  {VoicebotNodeConditionType === "message"
                    ? "Message"
                    : VoicebotNodeConditionType === "welcome_message"
                      ? "Welcome Message"
                      : VoicebotNodeConditionType === "connect_agent"
                        ? "Connect To Agent"
                        : VoicebotNodeConditionType === "user_input"
                          ? "User Input"
                          : VoicebotNodeConditionType === "invalid"
                            ? "Invalid Message"
                            : "WhatsApp"}
                </h2>
                {/* invalid */}

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    // textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Condition Type: {VoicebotNodeConditionType}
                </p>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Condition Data : {ConditionData}
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {buttonList.map((buttonLabel, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#006d77",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        />
                        <span>{buttonLabel}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ),
            buttonList: buttonList,
          },

          sourcePosition: "right",
          targetPosition: "left",
        };
        setNodes((nds) => [...nds, newNode]);
        setVoiceBotMediaLoader(false);
        setOpenCreateVoicebotNodeModal(false);
        HandleVoicebotNodeConditionTypeClick("message");
        setConditionData("");
        setNodeName("");
        setVoiceBotLanguage("en-US");
        setVoiceBotVoiceType("en-US-Casual-K");
        setButtonText("");
        setButtonList([]);
        setVoiceBotUserInput("");
        setQueueForVoiceBot("");
        setMediaConfigurationId("0");

        toast.success("Node created successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.info(result.Msg, {
          position: "top-right",
          autoClose: 5000,
        });
        setVoiceBotMediaLoader(false);
        return;
      }
    } catch (error) {
      setVoiceBotMediaLoader(false);
      console.error("Error during Voicebot node creation:", error);
    }
  };

  const EditVoiceBotFunction = async (file_path) => {
    try {
      const optionsList = JSON.stringify(buttonList);
      const result = await EditVoiceBotNode(
        accessToken,
        domainUUID,
        VoicebotNodeConditionType,
        ConditionData,
        voiceBotLanguage,
        configurationId,
        IDforVoiceBot,
        voiceBotVoiceType,
        queueForVoiceBot,
        voiceBotUserInput,
        optionsList,
        MediaConfigurationId,
        file_path
      );

      if (result.Status == "1") {
        const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

        const nodeIdToFind = selectedNodeId;
        const existingNode = nodes.find((node) => node.id === nodeIdToFind);

        const newNodePosition = existingNode
          ? { ...existingNode.position }
          : { x: 100, y: 100 };

        const newNode = {
          id: `${selectedNodeId}`,
          type: "VoiceBotNode",
          style: { width: "fit-content" },
          position: newNodePosition,
          name: nodeName,
          conditionType: VoicebotNodeConditionType,
          conditionData: ConditionData,
          buttonList: buttonList,
          UserInput: voiceBotUserInput,
          config_id: MediaConfigurationId,
          data: {
            nodeName,
            ConditionData,
            label: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "250px",
                  rowGap: "6px",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />

                  {VoicebotNodeConditionType === "message"
                    ? "Message"
                    : VoicebotNodeConditionType === "welcome_message"
                      ? "Welcome Message"
                      : VoicebotNodeConditionType === "connect_agent"
                        ? "Connect To Agent"
                        : VoicebotNodeConditionType === "user_input"
                          ? "User Input"
                          : VoicebotNodeConditionType === "invalid"
                            ? "Invalid Message"
                            : "WhatsApp"}

                  {/* Voice Bot */}
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    // textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Condition Type : {VoicebotNodeConditionType}
                </p>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Condition Data : {ConditionData}
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {buttonList.map((buttonLabel, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#006d77",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        />
                        <span>{buttonLabel}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ),
            buttonList: buttonList,
          },
          sourcePosition: "right",
          targetPosition: "left",
        };

        setNodes((nds) => [...nds, newNode]);
        setVoiceBotMediaLoader(false);
        setOpenCreateVoicebotNodeModal(false);
        setUpdateMode(false);
        HandleVoicebotNodeConditionTypeClick("message");
        setNodeName("");
        setConditionData("");
        setVoiceBotLanguage("en-US");
        setVoiceBotVoiceType("en-US-Casual-K");
        setButtonText("");
        setButtonList([]);
        setVoiceBotUserInput("");
        setQueueForVoiceBot("");
        setMediaConfigurationId("0");
        toast.success("Node edited successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.info(result.Msg, {
          position: "top-right",
          autoClose: 5000,
        });
        setVoiceBotMediaLoader(false);
        return;
      }
    } catch (error) {
      setVoiceBotMediaLoader(false);
      console.error("Error during Voicebot node edit ", error);
    }
  };

  const HandleVoicebotCreateNodeButton = async () => {
    setVoiceBotMediaLoader(true);
    // ValidationsForVoiceBot()
    if (nodeName == "" || ConditionData == "") {
      toast.warn("Please Enter the Required Fields!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (
      VoicebotNodeConditionType == "whatsapp" &&
      MediaConfigurationId == "0"
    ) {
      toast.warn("Please select a WhatsApp configuration Id !", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (VoicebotNodeConditionType == "whatsapp" && ConditionData == "") {
      toast.info("Please select a media type!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (VoicebotNodeConditionType == "message" && voiceBotUserInput == "") {
      toast.info("Please Select user input!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (VoicebotNodeConditionType == "user_input" && voiceBotUserInput == "") {
      toast.info("Please Select user input!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }


    if (
      VoicebotNodeConditionType == "connect_agent" &&
      queueForVoiceBot == ""
    ) {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (voiceBotVoiceType == "0") {
      toast.info("Please Select a Voice Type!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;

    }

    if (VoicebotNodeConditionType == "whatsapp") {
      await HandleVoicebotFileSubmit();
    } else {
      await CreateVoiceBotFunction();
    }
  };

  const HandleVoicebotEditNodeButton = async () => {
    setVoiceBotMediaLoader(true);
    // ValidationsForVoiceBot()
    if (nodeName == "" || ConditionData == "") {
      toast.warn("Please Enter the Required Fields!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (
      VoicebotNodeConditionType == "whatsapp" &&
      MediaConfigurationId == "0"
    ) {
      toast.warn("Please select a WhatsApp configuration Id !", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (VoicebotNodeConditionType == "whatsapp" && ConditionData == "") {
      toast.info("Please select a media type!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (VoicebotNodeConditionType == "message" && voiceBotUserInput == "") {
      toast.info("Please Select user input!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (VoicebotNodeConditionType == "user_input" && voiceBotUserInput == "") {
      toast.info("Please Select user input!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (
      VoicebotNodeConditionType == "connect_agent" &&
      queueForVoiceBot == ""
    ) {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;
    }

    if (voiceBotVoiceType == "0") {
      toast.info("Please Select a Voice Type!", {
        position: "top-right",
        autoClose: 3000,
      });
      setVoiceBotMediaLoader(false);
      return;

    }

    if (VoicebotNodeConditionType == "whatsapp") {
      await HandleVoicebotFileSubmit();
    } else {
      await EditVoiceBotFunction();
    }
  };

  const HandleVoicebotCreateNodeModalDeleteButton = async (id) => {
    const result = await DeleteNodeForVoiceBot(
      accessToken,
      domainUUID,
      IDforVoiceBot,
      id
    );

    if (result.Status == "1") {
      setNodes((nds) => nds.filter((node) => node.id !== id));
      setEdges((eds) =>
        eds.filter((edge) => edge.source !== id && edge.target !== id)
      );
      setUpdateMode(false);
      setOpenCreateVoicebotNodeModal(false);
      HandleVoicebotNodeConditionTypeClick("message");
      setNodeName("");
      setConditionData("");
      setVoiceBotLanguage("en-US");
      setVoiceBotVoiceType("en-US-Casual-K");
      setButtonText("");
      setButtonList([]);
      setVoiceBotUserInput("");
      setQueueForVoiceBot("");
      setMediaConfigurationId("0");
    }
  };

  const HandleVoicebotCreateNodeModalCancelButton = () => {
    setOpenCreateVoicebotNodeModal(false);
    setUpdateMode(false);
    HandleVoicebotNodeConditionTypeClick("message");
    setNodeName("");
    setConditionData("");
    setVoiceBotLanguage("en-US");
    setVoiceBotVoiceType("en-US-Casual-K");
    setButtonText("");
    setButtonList([]);
    setVoiceBotUserInput("");
    setQueueForVoiceBot("");
    setMediaConfigurationId("0");
  };

  // STATES FOR CREATING THE NODES FOR SMS

  const [OpenCreateSmsbotNodeModal, setOpenCreateSmsbotNodeModal] =
    useState(false);
  const [SmsbotNodeDestinationType, setSmsbotNodeDestinationType] =
    useState("API");
  const [SmsbotApiCurlName, setSmsbotApiCurlName] = useState("");

  const SmsDestinationTypeItems = [
    { name: "API", icon: <Api id="MenuIcon" /> },
  ];

  const HandleSmsbotNodeDestinationTypeClick = (type) => {
    setSmsbotNodeDestinationType(type);
  };

  const HandleSmsCreateNodeModalCancelButton = () => {
    if (updateMode) {
      setUpdateMode(false);
    }

    setSmsbotNodeDestinationType("API");
    setNodeName("");
    setSmsbotApiCurlName("");
    setOpenCreateSmsbotNodeModal(false);
  };

  const HandleSmsbotEditNodeModalButton = () => {
    // For editing SMS node
  };

  const HandleSmsbotCreateNodeModalButton = () => {
    if (!SmsbotApiCurlName || !nodeName) {
      toast.info("Please Enter the Required Fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", `Bearer ${accessToken}`);

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      broadcast_uuid: configurationId,
      condition_type: "api",
      condition_data: SmsbotApiCurlName,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}:10601/sms/sms-bot-case/create?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          const NodeId = result.node_id;

          const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

          const newNodePosition = lastNode
            ? {
              x: lastNode.position.x + 300, // Offset horizontally from the last node
              y: lastNode.position.y, // Offset vertically from the last node
            }
            : { x: 100, y: 100 };

          const newNode = {
            id: `${NodeId}`,
            // id: `${nodes.length + 10}`,
            type: "SmsNode",
            style: { width: "fit-content" },
            position: newNodePosition,
            Name: nodeName,
            Curl: SmsbotApiCurlName,
            markerEnd: {
              type: MarkerType.ArrowClosed, // Closed arrow at the end
              width: 30,
              height: 30,
              color: "black",
            },
            data: {
              nodeName,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid ",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />
                    API
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :{" "}
                    <span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <Handle
                    type="source"
                    position="right"
                    id="sourceHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                  <Handle
                    type="target"
                    position="left"
                    id="targetHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                </div>
              ),
            },
            sourcePosition: "right",
            targetPosition: "left",
          };
          setNodes((nds) => [...nds, newNode]);
          setNodeName("");
          setSmsbotApiCurlName("");
          setOpenCreateSmsbotNodeModal(false);
        } else {
          toast.info(result.msg, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      })
      .catch((error) => console.error(error));

    // For creating the  SMS node
  };

  const HandleSmsbotDeleteNodeModal = () => {
    // For deleting the  SMS node
  };

  const onConnectEnd = () => {
    handleModalForAddNode();
  };

  // STATES FOR CREATING THE TELEGRAM NODES MODAL
  const [OpenCreateTelegramNodeModal, setOpenCreateTelegramNodeModal] =
    useState(false);
  const [TelegramNodeConditionType, setTelegramNodeConditionType] =
    useState("message"); // Default selected menu

  const TelegramConditionTypeItems = [
    { name: "message", icon: <MarkUnreadChatAlt id="MenuIcon" /> },
    { name: "chat", icon: <QuestionAnswer id="MenuIcon" /> },
    { name: "button", icon: <TouchApp id="MenuIcon" /> },
    { name: "API", icon: <Api id="MenuIcon" /> },
  ];

  const TelegramApiMethodItems = [
    { name: "Get", value: "GET" },
    { name: "Post", value: "POST" },
    { name: "Put", value: "PUT" },
    { name: "Delete", value: "DELETE" },
  ];

  const HandleTelegramNodeConditionTypeClick = (type) => {
    setTelegramNodeConditionType(type);
    setConditionData("");
    setQueue("");
    setSelectedImage("");
    setButtonList([]);
    setButtonText("");
    setConditionAPI("");
    setPayload("");
    setMethod("");
    setPayloadType("");
  };

  const HandleTelegramCreateNodeModalCancelButton = () => {
    setOpenCreateTelegramNodeModal(false);
    setUpdateMode(false);
    setTelegramNodeConditionType("message");
    setNodeName("");
    setConditionData("");
    setQueue("");
    setSelectedImage("");
    setButtonList([]);
    setButtonText("");
    setConditionAPI("");
    setPayload("");
    setMethod("");
    setPayloadType("");
  };

  const HandleTelegramCreateNodeModalButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.info("Please Enter the Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (TelegramNodeConditionType == "chat" && queue == "") {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (TelegramNodeConditionType == "button" ||
        TelegramNodeConditionType == "custom") &&
      selectedImage == ""
    ) {
      toast.info("Please select one layout!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (TelegramNodeConditionType == "button" ||
        TelegramNodeConditionType == "custom") &&
      selectedImage != "" &&
      buttonList.length == 0
    ) {
      toast.info("Please add atleast 1 button!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const convertedButtonList = JSON.stringify(
      buttonList.map((item) => [item])
    );

    const result = await addChatbotCase(
      accessToken,
      domainUUID,
      TelegramNodeConditionType,
      ConditionData,
      conditionAPI,
      method,
      payloadType,
      payload,
      convertedButtonList,
      selectedImage,
      configurationName,
      queue,
      channel
    );

    if (result.Status == "1") {
      const NodeId = result.NodeId;
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      const newNodePosition = lastNode
        ? {
          x: lastNode.position.x + 300,
          y: lastNode.position.y,
        }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${NodeId}`,
        type:
          TelegramNodeConditionType === "button"
            ? "custom"
            : TelegramNodeConditionType,
        selectedImage: selectedImage,
        style: { width: "fit-content" },
        position: newNodePosition,
        markerEnd: {
          type: MarkerType.ArrowClosed, // Closed arrow at the end
          width: 30,
          height: 30,
          color: "black",
        },
        data: {
          nodeName,
          ConditionData,
          queue,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {TelegramNodeConditionType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name :<span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                {ConditionData}
              </p>

              {TelegramNodeConditionType === "button" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {buttonList.map((buttonLabel, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#006d77",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        />
                        <span>{buttonLabel}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {TelegramNodeConditionType != "button" && (
                <>
                  <Handle
                    type="source"
                    position="right"
                    id="sourceHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                  <Handle
                    type="target"
                    position="left"
                    id="targetHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                </>
              )}
            </div>
          ),
          buttonList: TelegramNodeConditionType === "button" ? buttonList : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setOpenCreateTelegramNodeModal(false);
      setTelegramNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const HandleTelegramEditNodeModalButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.info("Please Enter the Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (TelegramNodeConditionType == "chat" && queue == "") {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (TelegramNodeConditionType == "button" ||
        TelegramNodeConditionType == "custom") &&
      selectedImage == ""
    ) {
      toast.info("Please select one layout!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (TelegramNodeConditionType == "button" ||
        TelegramNodeConditionType == "custom") &&
      selectedImage != "" &&
      buttonList.length == 0
    ) {
      toast.info("Please add atleast 1 button!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const convertedButtonList = JSON.stringify(
      buttonList.map((item) => [item])
    );

    const result = await updateChatbotCase(
      domainUUID,
      accessToken,
      selectedNodeId,
      TelegramNodeConditionType,
      ConditionData,
      conditionAPI,
      method,
      payloadType,
      payload,
      convertedButtonList,
      selectedImage,
      configurationName,
      queue,
      channel
    );

    if (result.Status == "1") {
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      // const newNodePosition = lastNode
      //   ? {
      //       x: lastNode.position.x + 300, // Offset horizontally from the last node
      //       y: lastNode.position.y, // Offset vertically from the last node
      //     }
      //   : { x: 100, y: 100 };

      const nodeIdToFind = selectedNodeId;

      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        selectedImage: selectedImage,
        type:
          TelegramNodeConditionType === "button"
            ? "custom"
            : TelegramNodeConditionType,
        style: { width: "fit-content" },
        position: newNodePosition,
        data: {
          nodeName,
          ConditionData,
          queue,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />

                {TelegramNodeConditionType == "custom"
                  ? "button"
                  : TelegramNodeConditionType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                {ConditionData}
              </p>

              {(TelegramNodeConditionType === "button" ||
                TelegramNodeConditionType === "custom") && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {buttonList.map((buttonLabel, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#006d77",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                          />
                          <span>{buttonLabel}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              {TelegramNodeConditionType !== "button" &&
                TelegramNodeConditionType !== "custom" && (
                  <>
                    <Handle
                      type="source"
                      position="right"
                      id="sourceHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                    <Handle
                      type="target"
                      position="left"
                      id="targetHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                  </>
                )}
            </div>
          ),
          buttonList:
            TelegramNodeConditionType === "button" ||
              TelegramNodeConditionType === "custom"
              ? buttonList
              : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setOpenCreateTelegramNodeModal(false);
      setUpdateMode(false);
      setTelegramNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const HandleTelegramDeleteNodeModal = async (id) => {
    const result = await DeleteChatbotCase(
      domainUUID,
      accessToken,
      id,
      channel
    );

    if (result.Status == "1") {
      setNodes((nds) => nds.filter((node) => node.id !== id));
      setEdges((eds) =>
        eds.filter((edge) => edge.source !== id && edge.target !== id)
      );
      setUpdateMode(false);
      setOpenCreateTelegramNodeModal(false);
      setTelegramNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  // This is for fetching  the Queueslist for Omnichannel
  useEffect(() => {
    if (
      WhatsappNodeConditionType == "chat" ||
      TelegramNodeConditionType == "chat"
    ) {
      fetchQueuesList();
    }
  }, [WhatsappNodeConditionType, TelegramNodeConditionType]);

  // STATES FOR EDITING THE IVR PARET NODE
  const [CreateIvrConfigurationModal, setCreateIvrConfigurationModal] =
    useState(false);
  const [IvrName, setIvrName] = useState("");
  const [IvrExtension, setIvrExtension] = useState("");
  // const [PromptList, setPromptList] = useState([]);

  const [SelectIvrGreetLong, setSelectIvrGreetLong] = useState("1");
  const [GreetLongMessage, setGreetLongMessage] = useState("");
  const [TtsLongPromptName, setTtsLongPromptName] = useState("");
  const [TtsLongPromptText, setTtsLongPromptText] = useState("");
  const [TtsLongPromptLanguage, setTtsLongPromptLanguage] = useState("1");
  const [TtsLongPromptVoiceType, setTtsLongPromptVoiceType] = useState("1");

  const [SelectIvrGreetShort, setSelectIvrGreetShort] = useState("1");
  const [GreetShortMessage, setGreetShortMessage] = useState("");
  const [TtsShortPromptName, setTTSShortPromptName] = useState("");
  const [TtsShortPromptText, setTTSShortPromptText] = useState("");
  const [TtsShortPromptLanguage, setTtsShortPromptLanguage] = useState("1");
  const [TtsShortPromptVoiceType, setTtsShortPromptVoiceType] = useState("1");

  const [IvrMaxFailures, setIvrMaxFailures] = useState("2");
  const [IvrMaxTimeout, setIvrMaxTimeout] = useState("2");
  const [IvrTimeout, setIvrTimeout] = useState("3000");

  const [IvrSelectIvrExitAction, setIvrSelectIvrExitAction] = useState("1");
  const [ExitNameList, setExitNameList] = useState("");
  const [IvrSelectIvrExitName, setIvrSelectIvrExitName] = useState("0");
  const [IvrStatus, setIvrStatus] = useState(true);
  const [IvrDescription, setIvrDescription] = useState("");
  const [IvrSelectDirectDial, setIvrSelectDirectDial] = useState("false");
  const [IvrSelectRingBack, setIvrSelectRingBack] = useState("${in-ring}");
  const [IvrCallerIdPrefix, setIvrCallerIdPrefix] = useState("");
  const [IvrInvalidSound, setIvrInvalidSound] = useState("0");
  const [IvrExitSound, setIvrExitSound] = useState("0");

  // Error handling states for IVR
  const [IvrNameError, setIvrNameError] = useState("");
  const [IvrExtensionError, setIvrExtensionError] = useState("");
  const [IvrGreetLongError, setIvrGreetLongError] = useState("");
  const [IvrGreetLongMessageError, setIvrGreetLongMessageError] = useState("");
  const [IvrLongNameError, setIvrLongNameError] = useState("");
  const [IvrLongTextError, setIvrLongTextError] = useState("");
  const [IvrLongLanguageError, setIvrLongLanguageError] = useState("");
  const [IvrLongVoiceTypeError, setIvrLongVoiceTypeError] = useState("");
  const [IvrGreetShortError, setIvrGreetShortError] = useState("");
  const [IvrGreetShortMessageError, setIvrGreetShortMessageError] =
    useState("");
  const [IvrShortNameError, setIvrShortNameError] = useState("");
  const [IvrShortTextError, setIvrShortTextError] = useState("");
  const [IvrShortLanguageError, setIvrShortLanguageError] = useState("");
  const [IvrShortVoiceTypeError, setIvrShortVoiceTypeError] = useState("");
  const [IvrMaxFailuresError, setIvrMaxFailuresError] = useState("");
  const [IvrMaxTimeoutError, setIvrMaxTimeoutError] = useState("");
  const [IvrTimeoutError, setIvrTimeoutError] = useState("");
  const [IvrExitNameError, setIvrExitNameError] = useState("");
  const [IvrInvalidSoundError, setIvrInvalidSoundError] = useState("");
  const [IvrExitSoundError, setIvrExitSoundError] = useState("");

  // This function is for handling the form validations for empty fields
  const ErrorHandlingFunction = (e, setInput, setError, message) => {
    let value = e.target.value;

    value.trim() == "" || value == "0" ? setError(message) : setError("");
    setInput(value);
  };

  const ErrorHandlingChatLimit = (e, setInput, setError, message) => {
    let value = e.target.value;

    value.trim() === "" || value <= 0 || value > 9999
      ? setError(message)
      : setError("");

    setInput(value);
  };

  const ErrorHandlingExtension = (e, setInput, setError, message) => {
    let value = e.target.value;

    value.trim() == "" || value <= 0 ? setError(message) : setError("");
    setInput(value);
  };

  const ErrorHandlingMaxFailures = (e, setInput, setError, message) => {
    let value = e.target.value;

    value.trim() == "" || value < 0 || value > 20
      ? setError(message)
      : setError("");
    setInput(value);
  };

  const ErrorHandlingMaxTimeout = (e, setInput, setError, message) => {
    let value = e.target.value;

    value.trim() == "" || value < 0 || value > 20
      ? setError(message)
      : setError("");
    setInput(value);
  };

  const ErrorHandlingTimeout = (e, setInput, setError, message) => {
    let value = e.target.value;

    value.trim() == "" || value < 1000 || value > 9999
      ? setError(message)
      : setError("");
    setInput(value);
  };

  // const getFileLocationById = (Id) => {
  //   const name = promptList.find(
  //     (fileLocation) => fileLocation.pmt_uuid === Id
  //   );
  //   return name ? name.file_location : "";
  // };

  const getLocationUuid = async (
    locationName,
    setGreetMessage,
    ttsName,
    ttsText,
    lang,
    voice
  ) => {
    if (locationName.startsWith("say:")) {
      const [prefix, message] = locationName.split(":");
      setGreetMessage(message);
      return prefix;
    } else {
      const fileName = locationName.split("/").pop();
      const matchingPrompt = promptList.find(
        (prompt) => prompt.file_location.split("/").pop() === fileName
      );
      setGreetMessage("");

      if (!matchingPrompt) {
        const result = await GetTtsPromptData(
          accessToken,
          domainUUID,
          fileName.split(".")[0]
        );

        ttsName(result.v_prompt.file_name);
        ttsText(result.v_prompt.tts_prompt_text);
        lang(result.v_prompt.language);
        voice(result.v_prompt.voice_type);
      } else {
        ttsName("");
        ttsText("");
        lang("1");
        voice("1");
      }
      return matchingPrompt ? matchingPrompt.pmt_uuid : "tts_prompt";
    }
  };

  const getSoundUuid = (sound) => {
    const matchingPrompt = promptList.find(
      (prompt) => prompt.file_location.split("/").pop() === sound
    );

    return matchingPrompt ? matchingPrompt.pmt_uuid : sound;
  };

  const HandleCancelButtonIvrModal = () => {
    setCreateIvrConfigurationModal(false);
    setIvrName("");
    setIvrExtension("");
    setSelectIvrGreetLong("1");
    setSelectIvrGreetShort("1");
    setIvrMaxFailures("2");
    setIvrMaxTimeout("2");
    setIvrTimeout("3000");
    setIvrSelectIvrExitAction("1");
    setIvrStatus(true);
    setIvrDescription("");
    setIvrSelectDirectDial("false");
    setIvrSelectRingBack("${in-ring}");
    setIvrCallerIdPrefix("1");
    setIvrInvalidSound("0");
    setIvrExitSound("0");
    setTtsLongPromptName("");
    setTtsLongPromptText("");
    setTtsLongPromptLanguage("1");
    setTtsLongPromptVoiceType("1");
    setTTSShortPromptName("");
    setTTSShortPromptText("");
    setTtsShortPromptLanguage("1");
    setTtsShortPromptVoiceType("1");

    // Clearing error states of IVR
    setIvrNameError("");
    setIvrExtensionError("");
    setIvrGreetLongError("");
    setIvrGreetLongMessageError("");
    setIvrLongNameError("");
    setIvrLongTextError("");
    setIvrLongLanguageError("");
    setIvrLongVoiceTypeError("");
    setIvrGreetShortError("");
    setIvrGreetShortMessageError("");
    setIvrShortNameError("");
    setIvrShortTextError("");
    setIvrShortLanguageError("");
    setIvrShortVoiceTypeError("");
    setIvrMaxFailuresError("");
    setIvrMaxTimeoutError("");
    setIvrTimeoutError("");
    setIvrExitNameError("");
    setIvrInvalidSoundError("");
    setIvrExitSoundError("");
  };

  const handleIvrAddEdit = async () => {
    if (IvrName == "") {
      setIvrNameError("Please enter the IVR name");
    } else {
      setIvrNameError("");
    }

    if (IvrExtension == "" || IvrExtension <= 0) {
      setIvrExtensionError(
        "Please enter a valid IVR extension using digits greater than zero"
      );
    } else {
      setIvrExtensionError("");
    }

    if (SelectIvrGreetLong == "1") {
      setIvrGreetLongError("Please select a greet long ");
    } else {
      setIvrGreetLongError("");
    }

    if (SelectIvrGreetLong == "say" && GreetLongMessage == "") {
      setIvrGreetLongMessageError("Please enter greet long message");
    } else {
      setIvrGreetLongMessageError("");
    }

    if (SelectIvrGreetLong == "tts_prompt" && TtsLongPromptName == "") {
      setIvrLongNameError("Please enter a name for TTS prompt ");
    } else {
      setIvrLongNameError("");
    }

    if (SelectIvrGreetLong == "tts_prompt" && TtsLongPromptText == "") {
      setIvrLongTextError("Please enter a text for TTS prompt ");
    } else {
      setIvrLongTextError("");
    }

    if (SelectIvrGreetLong == "tts_prompt" && TtsLongPromptLanguage == "1") {
      setIvrLongLanguageError("Please select a language ");
    } else {
      setIvrLongLanguageError("");
    }

    if (SelectIvrGreetLong == "tts_prompt" && TtsLongPromptVoiceType == "1") {
      setIvrLongVoiceTypeError("Please select a voice type");
    } else {
      setIvrLongVoiceTypeError("");
    }

    if (SelectIvrGreetShort == "1") {
      setIvrGreetShortError("Please select a greet short ");
    } else {
      setIvrGreetShortError("");
    }

    if (SelectIvrGreetShort == "say" && GreetShortMessage == "") {
      setIvrGreetShortMessageError("Please enter greet short message");
    } else {
      setIvrGreetShortMessageError("");
    }

    if (SelectIvrGreetShort == "tts_prompt" && TtsShortPromptName == "") {
      setIvrShortNameError("Please enter a name for TTS prompt ");
    } else {
      setIvrShortNameError("");
    }

    if (SelectIvrGreetShort == "tts_prompt" && TtsShortPromptText == "") {
      setIvrShortTextError("Please enter a text for TTS prompt ");
    } else {
      setIvrShortTextError("");
    }

    if (SelectIvrGreetShort == "tts_prompt" && TtsShortPromptLanguage == "1") {
      setIvrShortLanguageError("Please select a language ");
    } else {
      setIvrShortLanguageError("");
    }

    if (SelectIvrGreetShort == "tts_prompt" && TtsShortPromptVoiceType == "1") {
      setIvrShortVoiceTypeError("Please select a voice type");
    } else {
      setIvrShortVoiceTypeError("");
    }

    if (IvrMaxFailures == "" || IvrMaxFailures < 0 || IvrMaxFailures > 20) {
      setIvrMaxFailuresError(
        "Please enter max failures count ( in digits between 0 to 20 )"
      );
    } else {
      setIvrMaxFailuresError("");
    }

    if (IvrMaxTimeout == "" || IvrMaxTimeout < 0 || IvrMaxTimeout > 20) {
      setIvrMaxTimeoutError(
        "Please enter max timeout ( in digits between 0 to 20 )"
      );
    } else {
      setIvrMaxTimeoutError("");
    }

    if (IvrTimeout == "" || IvrTimeout < 1000 || IvrTimeout > 9999) {
      setIvrTimeoutError(
        "Please enter timeout in milli-seconds ( in digits between 1000 to 9999 )"
      );
    } else {
      setIvrTimeoutError("");
    }

    if (IvrSelectIvrExitAction != "1" && IvrSelectIvrExitName == "0") {
      setIvrExitNameError("Please select an exit name");
    } else {
      setIvrExitNameError("");
    }

    if (IvrInvalidSound == "0") {
      setIvrInvalidSoundError("Please select an invalid sound");
    } else {
      setIvrInvalidSoundError("");
    }

    if (IvrExitSound == "0") {
      setIvrExitSoundError("Please select an exit sound");
    } else {
      setIvrExitSoundError("");
    }

    if (
      IvrName == "" ||
      IvrExtension == "" ||
      IvrExtension <= 0 ||
      SelectIvrGreetLong == "1" ||
      SelectIvrGreetShort == "1" ||
      IvrMaxFailures == "" ||
      IvrMaxFailures < 0 ||
      IvrMaxFailures > 20 ||
      IvrMaxTimeout == "" ||
      IvrMaxTimeout < 0 ||
      IvrMaxTimeout > 20 ||
      IvrTimeout == "" ||
      IvrTimeout < 1000 ||
      IvrTimeout > 9999 ||
      IvrSelectRingBack == "1" ||
      IvrInvalidSound == "0" ||
      IvrExitSound == "0"
    ) {
      toast.warn("Please Enter all the Required Fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (SelectIvrGreetLong == "say" && GreetLongMessage == "") ||
      (SelectIvrGreetShort == "say" && GreetShortMessage == "")
    ) {
      toast.warn("Please Enter all the Required Fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (SelectIvrGreetLong == "tts_prompt" &&
        (TtsLongPromptName == "" ||
          TtsLongPromptText == "" ||
          TtsLongPromptLanguage == "1" ||
          TtsLongPromptVoiceType == "1")) ||
      (SelectIvrGreetShort == "tts_prompt" &&
        (TtsShortPromptName == "" ||
          TtsShortPromptText == "" ||
          TtsShortPromptLanguage == "1" ||
          TtsShortPromptVoiceType == "1"))
    ) {
      toast.warn("Please Enter all the Required Fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (IvrSelectIvrExitAction != "1" && IvrSelectIvrExitName == "0") {
      toast.warn("Please select an exit name", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    var GreetShortValue = 0;
    if (SelectIvrGreetShort === "tts_prompt") {
      const result = await GetIdForTTSPrompt(
        accessToken,
        domainUUID,
        TtsShortPromptName,
        TtsShortPromptText,
        TtsShortPromptLanguage,
        TtsShortPromptVoiceType
      );

      GreetShortValue = result.UUID + ".mp3";
    } else {
      GreetShortValue = getFileLocationById(SelectIvrGreetShort).substring(
        getFileLocationById(SelectIvrGreetShort).lastIndexOf("/") + 1
      );
    }

    var GreetLongValue = 0;
    if (SelectIvrGreetLong === "tts_prompt") {
      const result = await GetIdForTTSPrompt(
        accessToken,
        domainUUID,
        TtsLongPromptName,
        TtsLongPromptText,
        TtsLongPromptLanguage,
        TtsLongPromptVoiceType
      );

      GreetLongValue = result.UUID + ".mp3";
    } else {
      GreetLongValue = getFileLocationById(SelectIvrGreetLong).substring(
        getFileLocationById(SelectIvrGreetLong).lastIndexOf("/") + 1
      );
    }

    if (
      GreetShortValue != 0 ||
      GreetLongValue != 0 ||
      SelectIvrGreetShort == "say" ||
      SelectIvrGreetLong == "say"
    ) {
      EditIVRFunction(GreetShortValue, GreetLongValue);
    }
  };

  const FetchPromptList = async () => {
    const result = await ShowPromptList(accessToken, domainUUID);
    if (result.Status == "1") {
      setPromptList(result.prompt_list);
      setExitNameList(result.prompt_list);
    }
  };

  const FetchQueueListForIvr = async () => {
    const result = await ShowQueueListForIvr(accessToken, domainUUID);
    setExitNameList(result);
  };

  // const FetchExtensionList = async () => {
  //   const result = await ShowExtensionsList(accessToken, domainUUID);

  //   const filteredResult = result.filter(
  //     (item) => item.voicemail_enable === "true"
  //   );
  //   setExitNameList(filteredResult);
  // };

  // states for editing the IVR.
  const [EditIvrFlowUuid, setEditIvrFlowUuid] = useState("");

  const HandleEditIvrFlow = async (editIvrId) => {
    setCreateIvrConfigurationModal(true);
    setEditIvrFlowUuid(editIvrId);

    const result = await showIvrConfigurationList(accessToken, domainUUID);

    if (result.Status === "1") {
      const selectedIvr = result.IvrList.find(
        (ivr) => ivr.ivr_menu_uuid === editIvrId
      );

      if (selectedIvr) {
        // Populate the modal form fields with the selected IVR details
        setIvrName(selectedIvr.ivr_menu_name || "");
        setIvrExtension(selectedIvr.ivr_menu_extension || "");

        // setSelectIvrGreetLong(getLocationUuid(selectedIvr.ivr_menu_greet_long, setGreetLongMessage, setTtsLongPromptName, setTtsLongPromptText, setTtsLongPromptLanguage, setTtsLongPromptVoiceType))

        // setSelectIvrGreetShort(getLocationUuid(selectedIvr.ivr_menu_greet_short, setGreetShortMessage, setTTSShortPromptName, setTTSShortPromptText, setTtsShortPromptLanguage, setTtsShortPromptVoiceType))

        const longGreetUuid = await getLocationUuid(
          selectedIvr.ivr_menu_greet_long,
          setGreetLongMessage,
          setTtsLongPromptName,
          setTtsLongPromptText,
          setTtsLongPromptLanguage,
          setTtsLongPromptVoiceType
        );
        setSelectIvrGreetLong(longGreetUuid);

        const shortGreetUuid = await getLocationUuid(
          selectedIvr.ivr_menu_greet_short,
          setGreetShortMessage,
          setTTSShortPromptName,
          setTTSShortPromptText,
          setTtsShortPromptLanguage,
          setTtsShortPromptVoiceType
        );
        setSelectIvrGreetShort(shortGreetUuid);

        setIvrMaxFailures(selectedIvr.ivr_menu_max_failures || "2");
        setIvrMaxTimeout(selectedIvr.ivr_menu_max_failures || "2");
        setIvrTimeout(selectedIvr.ivr_menu_timeout || "3000");
        setIvrStatus(selectedIvr.ivr_menu_enabled);

        setIvrSelectIvrExitAction(selectedIvr.ivr_menu_exit_app || "1");
        setIvrSelectIvrExitName(selectedIvr.ivr_menu_exit_data || "");

        setIvrDescription(selectedIvr.ivr_menu_description || "");
        setIvrSelectDirectDial(selectedIvr.ivr_menu_direct_dial);
        setIvrSelectRingBack(selectedIvr.ivr_menu_ringback || "${in-ring}");
        setIvrCallerIdPrefix(selectedIvr.ivr_menu_cid_prefix || "");

        setIvrInvalidSound(
          getSoundUuid(selectedIvr.ivr_menu_invalid_sound) || "0"
        );
        setIvrExitSound(getSoundUuid(selectedIvr.ivr_menu_exit_sound) || "0");
      }
    }
  };

  const EditIVRFunction = async (GreetShortValue, GreetLongValue) => {
    const InvalidSoundValue = getFileLocationById(IvrInvalidSound).substring(
      getFileLocationById(IvrInvalidSound).lastIndexOf("/") + 1
    );

    const ExitSoundValue = getFileLocationById(IvrExitSound).substring(
      getFileLocationById(IvrExitSound).lastIndexOf("/") + 1
    );
    const result = await EditIvr(
      accessToken,
      domainUUID,
      EditIvrFlowUuid,
      IvrName,
      IvrExtension,
      SelectIvrGreetLong,
      GreetLongMessage,
      GreetLongValue,
      SelectIvrGreetShort,
      GreetShortMessage,
      GreetShortValue,
      IvrTimeout,
      IvrSelectIvrExitAction,
      IvrSelectIvrExitName,
      IvrSelectDirectDial,
      IvrSelectRingBack,
      IvrCallerIdPrefix,
      InvalidSoundValue,
      ExitSoundValue,
      IvrStatus,
      IvrDescription,
      IvrMaxFailures,
      IvrMaxTimeout
    );

    if (result.Status == "1") {
      const nodeIdToFind = selectedNodeId;

      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        type: "CreatedNewIVR",
        style: { width: "fit-content" },
        position: newNodePosition,
        ivrDescription: IvrDescription,
        name: IvrName,
        data: {
          label: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "89% 10%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  alignItems: "flex-start",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  IVR
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {IvrName}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  IVR Description :<br /> {IvrDescription}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3px",
                }}
              >
                {[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "*",
                  "#",
                ].map((value, index) => (
                  <p style={{ margin: "0px" }}>{value}</p>
                ))}
              </div>
            </div>
          ),
        },

        sourcePosition: "right",
        targetPosition: "left",
      };

      setNodes((nds) => [...nds, newNode]);

      toast.success(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });

      HandleCancelButtonIvrModal();
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    FetchPromptList();
  }, []);

  const fetchIvrFlowsList = async () => {
    const result = await showIvrConfigurationList(accessToken, domainUUID);

    if (result.Status == "1") {
      setExitNameList(result.IvrList);
    }
  };

  useEffect(() => {
    if (IvrSelectIvrExitAction == "IVR") {
      fetchIvrFlowsList();
    }
    if (IvrSelectIvrExitAction == "QUEUE") {
      FetchQueueListForIvr();
    }
    if (IvrSelectIvrExitAction == "PLAYBACK") {
      FetchPromptList();
    }
    if (IvrSelectIvrExitAction == "VOICEMAIL") {
      FetchExtensionList();
    }
  }, [IvrSelectIvrExitAction]);

  // STATES FOR CREATING CALL BRIDGE NODES MODAL
  const [OpenCreateCallBridgeNodeModal, setOpenCreateCallBridgeNodeModal] =
    useState(false);
  const [CallBridgeNodeConditionType, setCallBridgeNodeConditionType] =
    useState("welcome_message");
  const [CallBridgeLanguage, setCallBridgeLanguage] = useState("en-US");
  const [CallBridgeVoiceType, setCallBridgeVoiceType] = useState("en-US-Casual-K");
  const [CallBridgeAPIType, setCallBridgeAPIType] = useState("");
  const [CallBridgeApiEndPoint, setCallBridgeApiEndPoint] = useState("");

  const [IDforCallBridge, setIDforCallBridge] = useState("");

  const CallBridgeConditionTypeItems = [
    {
      name: "Welcome Message",
      icon: <MarkChatRead id="MenuIcon" />,
      value: "welcome_message",
    },
    {
      name: "API",
      icon: <Api id="MenuIcon" />,
      value: "API",
    },
  ];

  const HandleCallBridgeNodeConditionTypeClick = (type) => {
    setCallBridgeNodeConditionType(type);
    setConditionData("");
    setCallBridgeLanguage("en-US");
    setCallBridgeVoiceType("en-US-Casual-K");
    setButtonText("");
    setButtonList([]);

    setCallBridgeAPIType("");
    setCallBridgeApiEndPoint("");
  };

  const HandleCallBridgeCreateNodeModalCancelButton = () => {
    setOpenCreateCallBridgeNodeModal(false);
    setUpdateMode(false);
    HandleCallBridgeNodeConditionTypeClick("welcome_message");

    setNodeName("");
    setConditionData("");
    setCallBridgeLanguage("en-US");
    setCallBridgeVoiceType("en-US-Casual-K");
    setButtonText("");
    setButtonList([]);
    setCallBridgeAPIType("");
    setCallBridgeApiEndPoint("");
  };

  const HandleCallBridgeCreateNodeModalDeleteButton = async (id) => {
    const result = await DeleteNodeForCallBridge(
      accessToken,
      domainUUID,
      IDforCallBridge,
      id
    );

    if (result.Status == "1") {
      setNodes((nds) => nds.filter((node) => node.id !== id));
      setEdges((eds) =>
        eds.filter((edge) => edge.source !== id && edge.target !== id)
      );
      setUpdateMode(false);
      setOpenCreateCallBridgeNodeModal(false);
      HandleCallBridgeNodeConditionTypeClick("welcome_message");
      setNodeName("");
      setConditionData("");
      setCallBridgeLanguage("en-US");
      setCallBridgeVoiceType("en-US-Casual-K");
      setButtonText("");
      setButtonList([]);
      setCallBridgeAPIType("");
      setCallBridgeApiEndPoint("");
    }
  };

  const HandleCallBridgeCreateNodeButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.warn("Please Enter the Required Fields!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (CallBridgeNodeConditionType == "API" && CallBridgeAPIType == "") {
      toast.warn("Please select an API type", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (
      CallBridgeNodeConditionType == "API" &&
      CallBridgeAPIType != "" &&
      CallBridgeApiEndPoint == ""
    ) {
      toast.warn("Please enter a curl for the API", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (CallBridgeVoiceType == "0") {
      toast.info("Please Select a Voice Type!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const optionsList = JSON.stringify(buttonList);

    const result = await AddCallBridgeNode(
      accessToken,
      domainUUID,
      configurationId,
      CallBridgeNodeConditionType,
      ConditionData,
      CallBridgeLanguage,
      CallBridgeVoiceType,
      CallBridgeAPIType,
      optionsList,
      CallBridgeApiEndPoint
    );

    if (result.Status == "1") {
      const NodeId = result.NodeId;
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      const newNodePosition = lastNode
        ? {
          x: lastNode.position.x + 400, // Offset horizontally from the last node
          y: lastNode.position.y, // Offset vertically from the last node
        }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${NodeId}`,
        type:
          CallBridgeNodeConditionType == "API"
            ? "APICallBridge"
            : "CallBridgeNode",
        style: { width: "fit-content" },
        // position: { x: Math.random() * 200, y: Math.random() * 200 },
        markerEnd: {
          type: MarkerType.ArrowClosed, // Closed arrow at the end
          width: 30,
          height: 30,
          color: "black",
        },
        position: newNodePosition,
        name: nodeName,
        conditionType: CallBridgeNodeConditionType,
        conditionData: ConditionData,
        buttonList: buttonList,
        language: CallBridgeLanguage,
        voiceType: CallBridgeVoiceType,
        apiType: CallBridgeAPIType,
        apiEndPoint: CallBridgeApiEndPoint,
        data: {
          nodeName,
          ConditionData,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />

                {CallBridgeNodeConditionType === "welcome_message"
                  ? "Welcome Message"
                  : "API"}
              </h2>
              {/* invalid */}

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "left",
                  width: "95%",
                }}
              >
                Condition Type: {CallBridgeNodeConditionType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Data : {ConditionData}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                {buttonList.map((buttonLabel, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#006d77",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      <span>{buttonLabel}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
          buttonList: buttonList,
        },

        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);

      setOpenCreateCallBridgeNodeModal(false);
      setUpdateMode(false);
      HandleCallBridgeNodeConditionTypeClick("welcome_message");

      setNodeName("");
      setConditionData("");
      setCallBridgeLanguage("en-US");
      setCallBridgeVoiceType("en-US-Casual-K");
      setButtonText("");
      setButtonList([]);
      setCallBridgeAPIType("");
      setCallBridgeApiEndPoint("");

      toast.success("Node created successfully!", {
        position: "top-right",
        autoClose: 3000,
      });
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }
  };

  const HandleCallBridgeEditNodeButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.warn("Please Enter the Required Fields!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (CallBridgeNodeConditionType == "API" && CallBridgeAPIType == "") {
      toast.warn("Please select an API type", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (
      CallBridgeNodeConditionType == "API" &&
      CallBridgeAPIType != "" &&
      CallBridgeApiEndPoint == ""
    ) {
      toast.warn("Please enter a curl for the API", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (CallBridgeVoiceType == "0") {
      toast.info("Please Select a Voice Type!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const optionsList = JSON.stringify(buttonList);

    const result = await EditCallBridgeNode(
      accessToken,
      domainUUID,
      configurationId,
      CallBridgeNodeConditionType,
      ConditionData,
      CallBridgeLanguage,
      CallBridgeVoiceType,
      CallBridgeAPIType,
      optionsList,
      CallBridgeApiEndPoint,
      IDforCallBridge
    );

    if (result.Status == "1") {
      const NodeId = result.NodeId;
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      const nodeIdToFind = selectedNodeId;
      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        type:
          CallBridgeNodeConditionType == "API"
            ? "APICallBridge"
            : "CallBridgeNode",
        style: { width: "fit-content" },
        // position: { x: Math.random() * 200, y: Math.random() * 200 },
        markerEnd: {
          type: MarkerType.ArrowClosed, // Closed arrow at the end
          width: 30,
          height: 30,
          color: "black",
        },
        position: newNodePosition,
        name: nodeName,
        conditionType: CallBridgeNodeConditionType,
        conditionData: ConditionData,
        buttonList: buttonList,
        language: CallBridgeLanguage,
        voiceType: CallBridgeVoiceType,
        apiType: CallBridgeAPIType,
        apiEndPoint: CallBridgeApiEndPoint,
        data: {
          nodeName,
          ConditionData,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />

                {CallBridgeNodeConditionType === "welcome_message"
                  ? "Welcome Message"
                  : "API"}
              </h2>
              {/* invalid */}

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "left",
                  width: "95%",
                }}
              >
                Condition Type: {CallBridgeNodeConditionType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Data : {ConditionData}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                {buttonList.map((buttonLabel, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#006d77",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      <span>{buttonLabel}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
          buttonList: buttonList,
        },

        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);

      setOpenCreateCallBridgeNodeModal(false);
      setUpdateMode(false);
      HandleCallBridgeNodeConditionTypeClick("welcome_message");

      setNodeName("");
      setConditionData("");
      setCallBridgeLanguage("en-US");
      setCallBridgeVoiceType("en-US-Casual-K");
      setButtonText("");
      setButtonList([]);
      setCallBridgeAPIType("");
      setCallBridgeApiEndPoint("");

      toast.success("Node edited successfully!", {
        position: "top-right",
        autoClose: 3000,
      });
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }
  };

  // <----------------- FOR VOICE BOT CONFIGURATION CREATION ----------------->
  const [
    CreateVoiceBotConfigurationModal,
    setCreateVoiceBotConfigurationModal,
  ] = useState(false);
  const [voiceBotConfigurationName, setVoiceBotConfigurationName] =
    useState("");
  const [voiceBotDescription, setVoiceBotDescription] = useState("");
  const [VoiceBotRetryValue, setVoiceBotRetryValue] = useState(2);
  // Error handling states ,
  const [VoiceBotNameError, setVoiceBotNameError] = useState("");

  const VoiceBotRetryValueArray = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  const HandleCancelButtonVoiceBotModal = () => {
    setCreateVoiceBotConfigurationModal(false);
    setVoiceBotConfigurationName("");
    setVoiceBotDescription("");
    setVoiceBotRetryValue(2);

    // Clearing Error states
    setVoiceBotNameError("");
  };

  const [loading, setLoading] = useState(false)
  const HandleAddNodesInBackend = (configuration_id) => {
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "channel": channel,
      "configuration_id": configuration_id,
      "template_id": template_id
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };


    console.log(channel, configuration_id, template_id)

    fetch("https://multiverse-flow.callibry.com/ccaas/workflow/v1/useTemplate", requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result.status == "true") {
          setLoading(false)
          navigate("/all_flows");
          HandleCancelButtonVoiceBotModal();
          toast.info("Voice Bot Configuration created successfully", {
            position: "top-right",
            autoClose: 3000,
          });
        }



      })
      .catch((error) => console.error(error));
  }

  const handleAddVoiceBotConfiguration = async () => {
    if (!voiceBotConfigurationName) {
      setVoiceBotNameError("Please enter configuration name");
    } else {
      setVoiceBotNameError("");
    }

    if (!voiceBotConfigurationName) {
      toast.warn("Please fill in all required fields!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    } else {
      const result = await CreateVoiceConfiguration(
        accessToken,
        domainUUID,
        voiceBotConfigurationName,
        voiceBotDescription,
        VoiceBotRetryValue
      );

      if (result.Status == "1") {
        PublishChanges(result.configuration_uuid)
        // HandleAddNodesInBackend(result.configuration_uuid)

        navigate("/all_flows");
        HandleCancelButtonVoiceBotModal();
        toast.info("Voice Bot Configuration created successfully", {
          position: "top-right",
          autoClose: 3000,
        });

      } else {
        toast.warn(result.Msg, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <>
      {/* JSX starts from here  */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <div
          style={{
            background: "#f0f0f0",
            border: "1px solid #ddd",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              padding: "5px 0px",
            }}
          >
            <Tooltip title="Back to previous page" placement="top-end">
              <KeyboardBackspace
                id="BackButton"
                onClick={() => {
                  navigate("/all_flows", { state: { channel } });
                }}
              />
            </Tooltip>

            <h3
              style={{
                color: "#6b6f82",
                fontWeight: "500",
                fontSize: "22px",
                margin: "0px",
              }}
            >
              {channel === "voiceBot" && <>Voice Bot</>}
              {channel === "whatsapp" && <>WhatsApp Bot</>}
              {channel === "telegram" && <>Telegram Bot</>}
              {channel === "viber" && <>Viber Bot</>}
              {channel === "IVR" && <>IVR</>}
              {channel === "smsBroadcast" && <>SMS Broadcast</>}
              {channel === "callBridge" && <>Call Bridge</>}

              <span style={{ color: "#746df7" }}> Channel</span>
            </h3>

            <h2
              style={{
                fontSize: "33px",
                fontWeight: "300",
                color: "grey",
                margin: "0px",
              }}
            >
              {" "}
              |{" "}
            </h2>

            <p
              style={{
                fontWeight: "500",
                fontSize: "16px",
                margin: "0px",
              }}
            >
              {isTemplate == "true"
                ? "Template Name :"
                : "Configuration Name :"}
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#746df7",
                  marginLeft: "4px",
                }}
              >
                {configurationName}
              </span>
            </p>
          </div>

          <div>
            {isTemplate == "true" ? (
              <>
                <Button
                  id="AddNodeButton"
                  onClick={() => {
                    setCreateVoiceBotConfigurationModal(true);
                  }}
                  style={{ margin: "0px 10px" }}
                >
                  Use This Template
                </Button>
              </>
            ) : (
              <>
                <Button
                  id="AddNodeButton"
                  onClick={() => {
                    handleModalForAddNode();
                  }}
                  style={{ margin: "0px 10px" }}
                >
                  Add Node
                </Button>

                <Button
                  style={{
                    margin: "0px 10px",
                  }}
                  id="AddNodeButton"
                  onClick={PublishChanges}
                >
                  Publish Changes
                </Button>
              </>
            )}
          </div>
        </div>

        <ReactFlowProvider>
          <ReactFlow
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={handleNodeClick}
            onReconnect={onReconnect}
            onReconnectStart={onReconnectStart}
            onReconnectEnd={onReconnectEnd}
            deleteKeyCode={46}
            proOptions={{ hideAttribution: true }}
            fitView
          >
            <MiniMap
              maskColor="white"
              maskStrokeWidth={1}
              style={{ backgroundColor: "white", border: "2px solid grey" }}
            />
            <Controls />
            <Background variant={backgroundVariant} />
          </ReactFlow>
        </ReactFlowProvider>

        {/* Save & restore layout buttons  */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <FormControl style={{ margin: "10px", minWidth: 200 }}>
            <InputLabel id="background-variant-label">Background</InputLabel>
            <Select
              labelId="background-variant-label"
              value={backgroundVariant}
              onChange={handleVariantChange}
              label="Background"
              sx={{
                height: "35px",
                fontSize: "14px",
              }}
            >
              <MenuItem value="dots">Dots</MenuItem>
              <MenuItem value="cross">Cross</MenuItem>
              <MenuItem value="lines">Lines</MenuItem>
            </Select>
          </FormControl>

          <div>
            {isTemplate != "true" && (
              <>
                <Button
                  onClick={handleSaveLayout}
                  style={{ margin: "10px", minWidth: 180 }}
                  id="AddNodeButton"
                >
                  Save layout
                </Button>
                <Button
                  id="AddNodeButton"
                  onClick={handleRestoreLayout}
                  style={{ margin: "10px", minWidth: 180 }}
                >
                  Restore layout
                </Button>
                ``
              </>
            )}
          </div>
        </div>
      </div>

      {/* MODAL FOR CREATING THE NODES FOR IVR*/}
      <Modal
        open={OpenCreateIvrNodeModal}
        onClose={() => HandleIvrCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            {updateMode ? "Edit " : "Create"}
            <span> IVR Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter the node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Destination Type </h2>
              {IvrDestinationTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${IvrNodeDestinationType === type.name ? "active" : ""
                    }`}
                  onClick={() => HandleIvrNodeDestinationTypeClick(type.name)}
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Menu Content with Submenus */}
            <div className="MenuContentBody">
              <h2>
                {IvrNodeDestinationType != "API"
                  ? "Select one destination name from below"
                  : ""}
              </h2>

              {IvrNodeDestinationType == "API" ? (
                <div id="NodeNameBox" style={{ marginTop: "20px" }}>
                  <p>Enter the Curl here : </p>
                  <TextField
                    placeholder="Enter name here"
                    variant="outlined"
                    value={apicurl}
                    onChange={(e) => setApiCurl(e.target.value)}
                    fullWidth
                  />
                </div>
              ) : (
                <div className="MenuContent">
                  {destinationNameList
                    ? destinationNameList.map((name, index) => {
                      const id =
                        name.ivr_menu_uuid ||
                        name.call_center_queue_uuid ||
                        name.pmt_uuid ||
                        name.extension_uuid ||
                        name.HangupId ||
                        name.CallbackId ||
                        name.configuration_uuid ||
                        name.knowledge_base_uuid;
                      const displayName =
                        name.ivr_menu_name ||
                        name.queue_name ||
                        name.prompt_name ||
                        name.extension ||
                        name.HangupName ||
                        name.CallbackName ||
                        name.configuration_name ||
                        name.doc_name;

                      return (
                        <div
                          key={index}
                          className={`SubMenuItem ${IvrNodeDestinationName === id ? "selected" : ""
                            }`}
                          onClick={() =>
                            HandleIvrNodeDestinationNameClick(id)
                          }
                        >
                          <NearMe id="Icon" />
                          {displayName}
                        </div>
                      );
                    })
                    : "No data"}
                </div>
              )}
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              id="CancelButton"
              onClick={() => HandleIvrCreateNodeModalCancelButton()}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              onClick={() => {
                updateMode
                  ? handleIvrEditNodeModalButton()
                  : HandleIvrCreateNodeModalButton();
              }}
            >
              {updateMode ? "Update Node" : "Add Node"}
            </Button>
            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => HandleIvrDeleteNodeModal(selectedNodeId)}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/* MODAL FOR CREATING THE NODES FOR WHATSAPP*/}
      <Modal
        open={OpenCreateWhatsAppNodeModal}
        onClose={() => HandleWhatsappCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            {updateMode ? "Edit " : "Create"}
            <span> Whatsapp Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter a node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Condition Type </h2>
              {WhatsappConditionTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${WhatsappNodeConditionType === type.name ? "active" : ""
                    }`}
                  onClick={() =>
                    HandleWhatsappNodeConditionTypeClick(type.name)
                  }
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="MenuContentBody">
              <h2>
                {WhatsappNodeConditionType == "message" &&
                  "Message condition type"}
                {WhatsappNodeConditionType == "chat" && "Chat condition type"}
                {WhatsappNodeConditionType == "button" &&
                  "Button condition type"}
                {WhatsappNodeConditionType == "API" && "API condition type"}
                {WhatsappNodeConditionType == "media" && "Media condition type"}
              </h2>

              {WhatsappNodeConditionType == "media" ? (
                <>
                  <div id="NodeNameBox">
                    <p>Enter the condition data : </p>
                    <TextField
                      placeholder="Enter data here"
                      variant="outlined"
                      value={ConditionData}
                      onChange={(e) => setConditionData(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select the media type from following:{" "}
                    </p>
                    <div
                      className="MenuContent"
                      style={{ gridTemplateColumns: "auto auto auto auto" }}
                    >
                      {MediaTypeItems.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className={`SubMenuItem ${payloadType === data.value ? "selected" : ""
                              }`}
                            onClick={() => HandleWhatsappMedia(data.value)}
                          >
                            {data.icon}
                            {data.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {payloadType !== "" && (
                    <div
                      style={{
                        padding: "12px 20px",
                        display: "flex",
                        gap: "15px",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="file"
                        onChange={HandleSelectFile}
                        ref={fileInputRef}
                        style={{
                          border: "2px #746df7 dashed",
                          backgroundColor: "#e4e6f2",
                          padding: "20px",
                          borderRadius: "10px",
                          width: "60%",
                          cursor: "pointer",
                        }}
                      />
                      {payloadType == "image" && (
                        <p id="UploadDescription">
                          Please upload an image file in JPG, PNG, or GIF
                          format.
                        </p>
                      )}
                      {payloadType == "video" && (
                        <p id="UploadDescription">
                          Please upload a video file in MP4, AVI, or MOV format.
                        </p>
                      )}
                      {payloadType == "audio" && (
                        <p id="UploadDescription">
                          Please upload an audio file in MP3 or WAV format.
                        </p>
                      )}
                      {payloadType == "document" && (
                        <p id="UploadDescription">
                          Please upload a document file in PDF, DOCX, or TXT
                          format.
                        </p>
                      )}
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select buttons layout design :{" "}
                    </p>

                    <div className="MenuContent">
                      <label>
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "flex-start",
                            columnGap: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="layout_1"
                            checked={selectedImage === "layout_1"}
                            onChange={() => setSelectedImage("layout_1")}
                          />
                          <img
                            src={
                              channel === "whatsapp"
                                ? require("./assets/WhatsApplayout.jpg")
                                : channel === "telegram"
                                  ? require("./assets/telegram_layout.jpg")
                                  : null
                            }
                            alt="WhatsApp Layout 1"
                            style={{ width: "220px", height: "100px" }}
                          />
                        </div>
                      </label>

                      <label>
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "flex-start",
                            columnGap: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="layout_2"
                            checked={selectedImage === "layout_2"}
                            onChange={() => setSelectedImage("layout_2")}
                          />
                          <img
                            src={
                              channel === "whatsapp"
                                ? require("./assets/WhatsApplayout2.jpg")
                                : channel === "telegram"
                                  ? require("./assets/telegram_layout_2.png")
                                  : null
                            }
                            alt="WhatsApp Layout 2"
                            style={{ width: "220px", height: "150px" }}
                          />
                        </div>
                      </label>
                    </div>

                    {selectedImage != "" && (
                      <>
                        <div
                          id="NodeNameBox"
                          style={{
                            gridTemplateColumns: "24% 45%  20%",
                            columnGap: "12px",
                          }}
                        >
                          <p>Enter names for buttons : </p>
                          <TextField
                            placeholder="Enter button"
                            variant="outlined"
                            value={buttonText}
                            onChange={(e) => setButtonText(e.target.value)}
                            fullWidth
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          />

                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAddButton}
                          >
                            Add Button
                          </Button>
                        </div>

                        <div
                          style={{
                            padding: "10px 40px",
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {buttonList.map((label, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "2px lightgrey solid",
                                borderRadius: "10px",
                                paddingLeft: "10px",
                                textTransform: "capitalize",
                              }}
                            >
                              {label}
                              <Tooltip title="Delete" placement="bottom">
                                <IconButton
                                  color="error"
                                  onClick={() => handleDeleteButton(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div id="NodeNameBox">
                    <p>Enter the condition data : </p>
                    <TextField
                      placeholder="Enter data here"
                      variant="outlined"
                      value={ConditionData}
                      onChange={(e) => setConditionData(e.target.value)}
                      fullWidth
                    />
                  </div>

                  {WhatsappNodeConditionType == "chat" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                          rowGap: "8px",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "30px",
                            fontWeight: "500",
                            textDecoration: "underline",
                          }}
                        >
                          Select one Queue from the following :{" "}
                        </p>
                        <div className="MenuContent">
                          {queueList.length >= 1
                            ? queueList.map((data, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`SubMenuItem ${queue === data.queue_uuid
                                    ? "selected"
                                    : ""
                                    }`}
                                  onClick={() => setQueue(data.queue_uuid)}
                                >
                                  <NearMe id="Icon" />
                                  {data.name}
                                </div>
                              );
                            })
                            : "No data"}
                        </div>
                      </div>
                    </>
                  )}

                  {WhatsappNodeConditionType == "button" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "15px",
                          rowGap: "8px",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "30px",
                            fontWeight: "500",
                            textDecoration: "underline",
                          }}
                        >
                          Select buttons layout design :{" "}
                        </p>

                        <div className="MenuContent">
                          <label>
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "flex-start",
                                columnGap: "5px",
                              }}
                            >
                              <input
                                type="radio"
                                value="layout_1"
                                checked={selectedImage === "layout_1"}
                                onChange={() => setSelectedImage("layout_1")}
                              />
                              <img
                                src={
                                  channel === "whatsapp"
                                    ? require("./assets/WhatsApplayout.jpg")
                                    : channel === "telegram"
                                      ? require("./assets/telegram_layout.jpg")
                                      : null
                                }
                                alt="WhatsApp Layout 1"
                                style={{ width: "220px", height: "100px" }}
                              />
                            </div>
                          </label>

                          <label>
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "flex-start",
                                columnGap: "5px",
                              }}
                            >
                              <input
                                type="radio"
                                value="layout_2"
                                checked={selectedImage === "layout_2"}
                                onChange={() => setSelectedImage("layout_2")}
                              />
                              <img
                                src={
                                  channel === "whatsapp"
                                    ? require("./assets/WhatsApplayout2.jpg")
                                    : channel === "telegram"
                                      ? require("./assets/telegram_layout_2.png")
                                      : null
                                }
                                alt="WhatsApp Layout 2"
                                style={{ width: "220px", height: "150px" }}
                              />
                            </div>
                          </label>
                        </div>

                        {selectedImage != "" && (
                          <>
                            <div
                              id="NodeNameBox"
                              style={{
                                gridTemplateColumns: "24% 45%  20%",
                                columnGap: "12px",
                              }}
                            >
                              <p>Enter names for buttons : </p>
                              <TextField
                                placeholder="Enter button"
                                variant="outlined"
                                value={buttonText}
                                onChange={(e) => setButtonText(e.target.value)}
                                fullWidth
                                style={{
                                  marginBottom: "10px",
                                  marginTop: "10px",
                                }}
                              />

                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleAddButton}
                              >
                                Add Button
                              </Button>
                            </div>

                            <div
                              style={{
                                padding: "10px 40px",
                                display: "flex",
                                gap: "10px",
                                flexWrap: "wrap",
                              }}
                            >
                              {buttonList.map((label, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    border: "2px lightgrey solid",
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {label}
                                  <Tooltip title="Delete" placement="bottom">
                                    <IconButton
                                      color="error"
                                      onClick={() => handleDeleteButton(index)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {WhatsappNodeConditionType == "API" && (
                    <>
                      <div id="NodeNameBox">
                        <p>Enter the condition API : </p>
                        <TextField
                          placeholder="Enter condition here"
                          variant="outlined"
                          value={conditionAPI}
                          onChange={(e) => setConditionAPI(e.target.value)}
                          fullWidth
                        />
                      </div>

                      <div id="NodeNameBox">
                        <p>Enter the payload : </p>
                        <TextField
                          placeholder="Enter here"
                          variant="outlined"
                          value={payload}
                          onChange={(e) => setPayload(e.target.value)}
                          fullWidth
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "15px",
                          rowGap: "8px",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "30px",
                            fontWeight: "500",
                            textDecoration: "underline",
                          }}
                        >
                          Select one API method from the following :{" "}
                        </p>
                        <div className="MenuContent">
                          {WhatsappApiMethodItems.map((data, index) => {
                            return (
                              <div
                                key={index}
                                className={`SubMenuItem ${method === data.value ? "selected" : ""
                                  }`}
                                onClick={() => setMethod(data.value)}
                              >
                                <NearMe id="Icon" />
                                {data.name}
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "15px",
                          rowGap: "8px",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "30px",
                            fontWeight: "500",
                            textDecoration: "underline",
                          }}
                        >
                          Select payload type :{" "}
                        </p>
                        <div className="MenuContent">
                          <div
                            onClick={() => setPayloadType("query_param")}
                            className={`SubMenuItem ${payloadType == "query_param" ? "selected" : ""
                              }`}
                          >
                            <NearMe id="Icon" />
                            Query param
                          </div>

                          <div
                            onClick={() => setPayloadType("request_body")}
                            className={`SubMenuItem ${payloadType == "request_body" ? "selected" : ""
                              }`}
                          >
                            <NearMe id="Icon" />
                            Request body
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => HandleWhatsappCreateNodeModalCancelButton()}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              disabled={WhatsappMediaLoader ? true : false}
              onClick={() => {
                updateMode
                  ? HandleWhatsAppEditNodeButton()
                  : HandleWhatsAppCreateNodeButton();
              }}
            >
              {WhatsappMediaLoader
                ? "Loading..."
                : updateMode
                  ? "Update Node"
                  : "Add Node"}
            </Button>
            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => HandleWhatsappDeleteNodeModal(selectedNodeId)}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/* MODAL FOR CREATING THE NODES FOR TELEGRAM */}
      <Modal
        open={OpenCreateTelegramNodeModal}
      // onClose={() => HandleWhatsappCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            {updateMode ? "Edit " : "Create"}
            <span> Telegram Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter a node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Condition Type </h2>
              {TelegramConditionTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${TelegramNodeConditionType === type.name ? "active" : ""
                    }`}
                  onClick={() =>
                    HandleTelegramNodeConditionTypeClick(type.name)
                  }
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="MenuContentBody">
              <h2>
                {TelegramNodeConditionType == "message" &&
                  "Message condition type"}
                {TelegramNodeConditionType == "chat" && "Chat condition type"}
                {TelegramNodeConditionType == "button" &&
                  "Button condition type"}
                {TelegramNodeConditionType == "API" && "API condition type"}
              </h2>

              <div id="NodeNameBox">
                <p>Enter the condition data : </p>
                <TextField
                  placeholder="Enter data here"
                  variant="outlined"
                  value={ConditionData}
                  onChange={(e) => setConditionData(e.target.value)}
                  fullWidth
                />
              </div>

              {TelegramNodeConditionType == "chat" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "20px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select one Queue from the following :{" "}
                    </p>
                    <div className="MenuContent">
                      {queueList.length >= 1
                        ? queueList.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className={`SubMenuItem ${queue === data.queue_uuid ? "selected" : ""
                                }`}
                              onClick={() => setQueue(data.queue_uuid)}
                            >
                              <NearMe id="Icon" />
                              {data.name}
                            </div>
                          );
                        })
                        : "No data"}
                    </div>
                  </div>
                </>
              )}

              {TelegramNodeConditionType == "button" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select buttons layout design :{" "}
                    </p>

                    <div className="MenuContent">
                      <label>
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "flex-start",
                            columnGap: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="layout_1"
                            checked={selectedImage === "layout_1"}
                            onChange={() => setSelectedImage("layout_1")}
                          />
                          <img
                            src={
                              channel === "whatsapp"
                                ? require("./assets/WhatsApplayout.jpg")
                                : channel === "telegram"
                                  ? require("./assets/telegram_layout_2.png")
                                  : null
                            }
                            alt="WhatsApp Layout 1"
                            style={{ width: "220px", height: "100px" }}
                          />
                        </div>
                      </label>

                      <label>
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "flex-start",
                            columnGap: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="layout_2"
                            checked={selectedImage === "layout_2"}
                            onChange={() => setSelectedImage("layout_2")}
                          />
                          <img
                            src={
                              channel === "whatsapp"
                                ? require("./assets/WhatsApplayout2.jpg")
                                : channel === "telegram"
                                  ? require("./assets/telegram_layout.jpg")
                                  : null
                            }
                            alt="WhatsApp Layout 2"
                            style={{ width: "220px", height: "150px" }}
                          />
                        </div>
                      </label>
                    </div>

                    {selectedImage != "" && (
                      <>
                        <div
                          id="NodeNameBox"
                          style={{
                            gridTemplateColumns: "24% 45%  20%",
                            columnGap: "12px",
                          }}
                        >
                          <p>Enter names for buttons : </p>
                          <TextField
                            placeholder="Enter button"
                            variant="outlined"
                            value={buttonText}
                            onChange={(e) => setButtonText(e.target.value)}
                            fullWidth
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          />

                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAddButton}
                          >
                            Add Button
                          </Button>
                        </div>

                        <div
                          style={{
                            padding: "10px 40px",
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {buttonList.map((label, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "2px lightgrey solid",
                                borderRadius: "10px",
                                paddingLeft: "10px",
                                textTransform: "capitalize",
                              }}
                            >
                              {label}
                              <Tooltip title="Delete" placement="bottom">
                                <IconButton
                                  color="error"
                                  onClick={() => handleDeleteButton(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {TelegramNodeConditionType == "API" && (
                <>
                  <div id="NodeNameBox">
                    <p>Enter the condition API : </p>
                    <TextField
                      placeholder="Enter condition here"
                      variant="outlined"
                      value={conditionAPI}
                      onChange={(e) => setConditionAPI(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div id="NodeNameBox">
                    <p>Enter the payload : </p>
                    <TextField
                      placeholder="Enter here"
                      variant="outlined"
                      value={payload}
                      onChange={(e) => setPayload(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select one API method from the following :{" "}
                    </p>
                    <div className="MenuContent">
                      {TelegramApiMethodItems.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className={`SubMenuItem ${method === data.value ? "selected" : ""
                              }`}
                            onClick={() => setMethod(data.value)}
                          >
                            <NearMe id="Icon" />
                            {data.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select payload type :{" "}
                    </p>
                    <div className="MenuContent">
                      <div
                        onClick={() => setPayloadType("query_param")}
                        className={`SubMenuItem ${payloadType == "query_param" ? "selected" : ""
                          }`}
                      >
                        <NearMe id="Icon" />
                        Query param
                      </div>

                      <div
                        onClick={() => setPayloadType("request_body")}
                        className={`SubMenuItem ${payloadType == "request_body" ? "selected" : ""
                          }`}
                      >
                        <NearMe id="Icon" />
                        Request body
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              id="CancelButton"
              onClick={() => HandleTelegramCreateNodeModalCancelButton()}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              onClick={() => {
                updateMode
                  ? HandleTelegramEditNodeModalButton()
                  : HandleTelegramCreateNodeModalButton();
              }}
            >
              {updateMode ? "Update Node " : "Add Node"}
            </Button>

            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => HandleTelegramDeleteNodeModal(selectedNodeId)}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/* MODAL FOR CREATING THE NODES FOR VOICE BOT*/}
      <Modal
        open={OpenCreateVoicebotNodeModal}
        onClose={() => HandleVoicebotCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            Create<span> Voice Bot Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter a node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Condition Type </h2>
              {VoicebotConditionTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${VoicebotNodeConditionType === type.value ? "active" : ""
                    }`}
                  onClick={() =>
                    HandleVoicebotNodeConditionTypeClick(type.value)
                  }
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="MenuContentBody">
              <h2>
                {VoicebotNodeConditionType == "message" &&
                  "Condition type - Message"}
                {VoicebotNodeConditionType == "welcome_message" &&
                  "Condition type - Welcome message"}
                {VoicebotNodeConditionType == "connect_agent" &&
                  "Condition type - Connect to agent"}
                {VoicebotNodeConditionType == "user_input" &&
                  "Condition type - User input"}
                {VoicebotNodeConditionType == "invalid" &&
                  "Condition type - Invalid Message"}
                {VoicebotNodeConditionType == "whatsapp" &&
                  "Condition type - WhatsApp Message"}
              </h2>

              {VoicebotNodeConditionType == "whatsapp" ? (
                <>
                  <div id="NodeNameBox">
                    <p>Please select a configuration : </p>
                    <Select
                      id="node-type"
                      value={MediaConfigurationId}
                      onChange={(e) => {
                        setMediaConfigurationId(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value="0" disabled>
                        -- Select WhatsApp configuration--
                      </MenuItem>

                      {WhatsAppConfigurationList ? (
                        WhatsAppConfigurationList.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {option.configuration_name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No data</MenuItem>
                      )}
                    </Select>
                  </div>

                  <div
                    id="NodeNameBox"
                    style={{
                      gridTemplateColumns: "24% 45%  20%",
                      columnGap: "12px",
                    }}
                  >
                    <p>Enter the options : </p>
                    <TextField
                      placeholder="Enter here"
                      variant="outlined"
                      value={buttonText}
                      onChange={(e) => setButtonText(e.target.value)}
                      fullWidth
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    />

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleAddButton}
                    >
                      Add Options
                    </Button>
                  </div>

                  <div
                    style={{
                      padding: "10px 40px",
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {buttonList.map((label, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "2px lightgrey solid",
                          borderRadius: "10px",
                          paddingLeft: "10px",
                          textTransform: "capitalize",
                        }}
                      >
                        {label}
                        <Tooltip title="Delete" placement="bottom">
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteButton(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select the media type from following:{" "}
                    </p>
                    <div
                      className="MenuContent"
                      style={{ gridTemplateColumns: "auto auto auto auto" }}
                    >
                      {MediaTypeItems.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className={`SubMenuItem ${ConditionData === data.value ? "selected" : ""
                              }`}
                            onClick={() => HandleVoiceMedia(data.value)}
                          >
                            {data.icon}
                            {data.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {ConditionData !== "" && (
                    <div
                      style={{
                        padding: "12px 20px",
                        display: "flex",
                        gap: "15px",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="file"
                        onChange={HandleSelectFile}
                        ref={fileInputRef}
                        style={{
                          border: "2px #746df7 dashed",
                          backgroundColor: "#e4e6f2",
                          padding: "20px",
                          borderRadius: "10px",
                          width: "60%",
                          cursor: "pointer",
                        }}
                      />
                      {ConditionData == "image" && (
                        <p id="UploadDescription">
                          Please upload an image file in JPG, PNG, or GIF
                          format.
                        </p>
                      )}
                      {ConditionData == "video" && (
                        <p id="UploadDescription">
                          Please upload a video file in MP4, AVI, or MOV format.
                        </p>
                      )}
                      {ConditionData == "audio" && (
                        <p id="UploadDescription">
                          Please upload an audio file in MP3 or WAV format.
                        </p>
                      )}
                      {ConditionData == "document" && (
                        <p id="UploadDescription">
                          Please upload a document file in PDF, DOCX, or TXT
                          format.
                        </p>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div id="NodeNameBox">
                    <p>Enter the condition data : </p>
                    <TextField
                      placeholder="Enter data here"
                      variant="outlined"
                      value={ConditionData}
                      onChange={(e) => setConditionData(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Language :{" "}
                    </p>

                    <div id="NodeNameBox">
                      <p>Please select a language : </p>
                      <Select
                        id="node-type"
                        value={voiceBotLanguage}
                        onChange={(e) => {
                          setVoiceBotLanguage(e.target.value);
                          setVoiceBotVoiceType("0")
                        }}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="0" disabled>
                          -- Select Language --
                        </MenuItem>

                        {Array.from(
                          Voices.reduce((map, voice) => {
                            if (!map.has(voice.language)) {
                              map.set(voice.language, voice.language_code);
                            }
                            return map;
                          }, new Map())
                        ).map(([language, language_code]) => (
                          <MenuItem key={language_code} value={language_code}>
                            {language}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Voice Type :{" "}
                    </p>
                    <div id="NodeNameBox">
                      <p>Please select a voice type : </p>
                      <Select
                        id="node-type-voice"
                        value={voiceBotVoiceType}
                        onChange={(e) => setVoiceBotVoiceType(e.target.value)}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="0" disabled>
                          -- Select Voice Type --
                        </MenuItem>
                        {Voices.filter((voice) => voice.language_code === voiceBotLanguage).map(
                          (voice, index) => (
                            <MenuItem key={index} value={voice.voice_name}>
                              {`${voice.gender} - (${voice.voice_name})`}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </div>
                  </div>

                  <div
                    id="NodeNameBox"
                    style={{
                      gridTemplateColumns: "24% 45%  20%",
                      columnGap: "12px",
                    }}
                  >
                    <p>Enter the options : </p>
                    <TextField
                      placeholder="Enter here"
                      variant="outlined"
                      value={buttonText}
                      onChange={(e) => setButtonText(e.target.value)}
                      fullWidth
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    />

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleAddButton}
                    >
                      Add Options
                    </Button>
                  </div>

                  <div
                    style={{
                      padding: "10px 40px",
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {buttonList.map((label, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "2px lightgrey solid",
                          borderRadius: "10px",
                          paddingLeft: "10px",
                          textTransform: "capitalize",
                        }}
                      >
                        {label}
                        <Tooltip title="Delete" placement="bottom">
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteButton(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ))}
                  </div>

                  {(VoicebotNodeConditionType == "message" ||
                    VoicebotNodeConditionType == "user_input") && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "15px",
                          rowGap: "8px",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "30px",
                            fontWeight: "500",
                            textDecoration: "underline",
                          }}
                        >
                          Select user input :{" "}
                        </p>
                        <div className="MenuContent">
                          <div
                            onClick={() => setVoiceBotUserInput("true")}
                            className={`SubMenuItem ${voiceBotUserInput == "true" ? "selected" : ""
                              }`}
                          >
                            <NearMe id="Icon" />
                            Yes
                          </div>

                          <div
                            onClick={() => setVoiceBotUserInput("false")}
                            className={`SubMenuItem ${voiceBotUserInput == "false" ? "selected" : ""
                              }`}
                          >
                            <NearMe id="Icon" />
                            No
                          </div>
                        </div>
                      </div>
                    )}

                  {VoicebotNodeConditionType == "connect_agent" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                          rowGap: "8px",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "30px",
                            fontWeight: "500",
                            textDecoration: "underline",
                          }}
                        >
                          Select one Queue from the following :{" "}
                        </p>
                        <div className="MenuContent">
                          {queueListforVoiceBot
                            ? queueListforVoiceBot.map((data, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`SubMenuItem ${queueForVoiceBot ===
                                    data.call_center_queue_uuid
                                    ? "selected"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setQueueForVoiceBot(
                                      data.call_center_queue_uuid
                                    )
                                  }
                                >
                                  <NearMe id="Icon" />
                                  {data.queue_name}
                                </div>
                              );
                            })
                            : "No data"}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              id="CancelButton"
              onClick={() => {
                HandleVoicebotCreateNodeModalCancelButton();
              }}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              disabled={VoiceBotMediaLoader ? true : false}
              onClick={() => {
                updateMode
                  ? HandleVoicebotEditNodeButton()
                  : HandleVoicebotCreateNodeButton();
              }}
            >
              {VoiceBotMediaLoader
                ? "Loading..."
                : updateMode
                  ? "Update Node"
                  : "Add Node"}
            </Button>
            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => {
                  HandleVoicebotCreateNodeModalDeleteButton(selectedNodeId);
                }}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/*MODAL FOR CREATING THE NODES FOR SMS */}
      <Modal
        open={OpenCreateSmsbotNodeModal}
        onClose={() => HandleSmsCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            Create <span> SMS Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter the node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Destination Type </h2>
              {SmsDestinationTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${SmsbotNodeDestinationType === type.name ? "active" : ""
                    }`}
                  onClick={() =>
                    HandleSmsbotNodeDestinationTypeClick(type.name)
                  }
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="MenuContentBody">
              <h2>Destination type : API</h2>

              <div id="NodeNameBox" style={{ marginTop: "20px" }}>
                <p>Enter the Curl here : </p>
                <TextField
                  placeholder="Enter name here"
                  variant="outlined"
                  value={SmsbotApiCurlName}
                  onChange={(e) => setSmsbotApiCurlName(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              id="CancelButton"
              onClick={() => HandleSmsCreateNodeModalCancelButton()}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              onClick={() => {
                updateMode
                  ? HandleSmsbotEditNodeModalButton()
                  : HandleSmsbotCreateNodeModalButton();
              }}
            >
              {updateMode ? "Update Node" : "Add Node"}
            </Button>

            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => HandleSmsbotDeleteNodeModal(selectedNodeId)}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/*MODAL FOR CREATING THE NODES FOR CALL BRIDGE */}
      <Modal
        open={OpenCreateCallBridgeNodeModal}
        onClose={() => HandleCallBridgeCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            Create<span> Call Bridge Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter a node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Condition Type </h2>
              {CallBridgeConditionTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${CallBridgeNodeConditionType === type.value ? "active" : ""
                    }`}
                  onClick={() =>
                    HandleCallBridgeNodeConditionTypeClick(type.value)
                  }
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="MenuContentBody">
              <h2>
                {CallBridgeNodeConditionType == "welcome_message" &&
                  "Condition type - Welcome message"}
                {CallBridgeNodeConditionType == "API" && "Condition type - API"}
              </h2>

              {CallBridgeNodeConditionType == "welcome_message" && (
                <>
                  <div id="NodeNameBox">
                    <p>Enter the condition data : </p>
                    <TextField
                      placeholder="Enter data here"
                      variant="outlined"
                      value={ConditionData}
                      onChange={(e) => setConditionData(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Language :{" "}
                    </p>

                    <div id="NodeNameBox">
                      <p>Please select a language : </p>
                      <Select
                        id="node-type"
                        value={CallBridgeLanguage}
                        onChange={(e) => {
                          setCallBridgeLanguage(e.target.value);
                          setCallBridgeVoiceType("0")
                        }}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="0" disabled>
                          -- Select Language --
                        </MenuItem>

                        {Array.from(
                          Voices.reduce((map, voice) => {
                            if (!map.has(voice.language)) {
                              map.set(voice.language, voice.language_code);
                            }
                            return map;
                          }, new Map())
                        ).map(([language, language_code]) => (
                          <MenuItem key={language_code} value={language_code}>
                            {language}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Voice Type :{" "}
                    </p>

                    <div id="NodeNameBox">
                      <p>Please select a voice type : </p>
                      <Select
                        id="node-type-voice"
                        value={CallBridgeVoiceType}
                        onChange={(e) => setCallBridgeVoiceType(e.target.value)}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="0" disabled>
                          -- Select Voice Type --
                        </MenuItem>
                        {Voices.filter((voice) => voice.language_code === CallBridgeLanguage).map(
                          (voice, index) => (
                            <MenuItem key={index} value={voice.voice_name}>
                              {`${voice.gender} - (${voice.voice_name})`}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </div>
                  </div>

                  <div
                    id="NodeNameBox"
                    style={{
                      gridTemplateColumns: "24% 45%  20%",
                      columnGap: "12px",
                    }}
                  >
                    <p>Enter the options : </p>
                    <TextField
                      placeholder="Enter here"
                      variant="outlined"
                      value={buttonText}
                      onChange={(e) => setButtonText(e.target.value)}
                      fullWidth
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    />

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleAddButton}
                    >
                      Add Options
                    </Button>
                  </div>

                  <div
                    style={{
                      padding: "10px 40px",
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {buttonList.map((label, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "2px lightgrey solid",
                          borderRadius: "10px",
                          paddingLeft: "10px",
                          textTransform: "capitalize",
                        }}
                      >
                        {label}
                        <Tooltip title="Delete" placement="bottom">
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteButton(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {CallBridgeNodeConditionType == "API" && (
                <>
                  <div id="NodeNameBox">
                    <p>Enter the condition data : </p>
                    <TextField
                      placeholder="Enter data here"
                      variant="outlined"
                      value={ConditionData}
                      onChange={(e) => setConditionData(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select API type :{" "}
                    </p>
                    <div className="MenuContent">
                      <div
                        onClick={() => setCallBridgeAPIType("play&gather")}
                        className={`SubMenuItem ${CallBridgeAPIType == "play&gather" ? "selected" : ""
                          }`}
                      >
                        <NearMe id="Icon" />
                        Play and Gather
                      </div>

                      <div
                        onClick={() => setCallBridgeAPIType("call&bridge")}
                        className={`SubMenuItem ${CallBridgeAPIType == "call&bridge" ? "selected" : ""
                          }`}
                      >
                        <NearMe id="Icon" />
                        Call & Bridge
                      </div>
                    </div>
                  </div>

                  {CallBridgeAPIType != "" && (
                    <>
                      <div id="NodeNameBox">
                        <p>Enter end-point for API : </p>
                        <TextField
                          placeholder="Enter end-point here"
                          variant="outlined"
                          value={CallBridgeApiEndPoint}
                          onChange={(e) =>
                            setCallBridgeApiEndPoint(e.target.value)
                          }
                          fullWidth
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              id="CancelButton"
              onClick={() => {
                HandleCallBridgeCreateNodeModalCancelButton();
              }}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              disabled={VoiceBotMediaLoader ? true : false}
              onClick={() => {
                updateMode
                  ? HandleCallBridgeEditNodeButton()
                  : HandleCallBridgeCreateNodeButton();
              }}
            >
              {updateMode ? "Update Node" : "Add Node"}
            </Button>
            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => {
                  HandleCallBridgeCreateNodeModalDeleteButton(selectedNodeId);
                }}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/* MODAL FOR EDITING THE IVR PARENT NODE */}
      <Modal
        open={CreateIvrConfigurationModal}
        onClose={() => HandleCancelButtonIvrModal()}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleIvrAddEdit();
          }}
        >
          <Box sx={{ ...ivrModalStyle }} id="IvrConfigurationModal">
            <h2>Edit IVR Configuration</h2>

            <div id="AllInputBoxes">
              <div id="RowBox">
                <div id="InputBox">
                  <p>
                    {" "}
                    Enter IVR Name <span>*</span>
                  </p>
                  <TextField
                    placeholder="Enter name here"
                    variant="outlined"
                    value={IvrName}
                    onChange={(e) =>
                      ErrorHandlingFunction(
                        e,
                        setIvrName,
                        setIvrNameError,
                        "Please enter the IVR name"
                      )
                    }
                    fullWidth
                    style={{ width: "100%" }}
                  />
                  <p id="ErrorMessage">{IvrNameError && IvrNameError}</p>
                </div>

                <div id="InputBox">
                  <p>
                    {" "}
                    Enter IVR Extension <span>*</span>
                  </p>
                  <TextField
                    placeholder="Enter extension here in digits"
                    type="number"
                    variant="outlined"
                    value={IvrExtension}
                    onChange={(e) =>
                      ErrorHandlingExtension(
                        e,
                        setIvrExtension,
                        setIvrExtensionError,
                        "Please enter a valid IVR extension using digits greater than zero"
                      )
                    }
                    fullWidth
                    style={{ width: "100%" }}
                    inputProps={{ min: 1 }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "e" ||
                        e.key === "E" ||
                        e.key === "+" ||
                        e.key === "-"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <p id="ErrorMessage">
                    {IvrExtensionError && IvrExtensionError}
                  </p>
                </div>
              </div>

              <div id="RowBox">
                <div id="InputBox">
                  <p>
                    {" "}
                    Select Greet Long<span>*</span>
                  </p>
                  <Select
                    id="node-type"
                    value={SelectIvrGreetLong}
                    onChange={(e) =>
                      ErrorHandlingFunction(
                        e,
                        setSelectIvrGreetLong,
                        setIvrGreetLongError,
                        "Please select a greet long"
                      )
                    }
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="1" disabled>
                      -- Select Greet Long--
                    </MenuItem>
                    <MenuItem value="say">Say</MenuItem>
                    <MenuItem value="tts_prompt">TTS Prompt</MenuItem>

                    {promptList ? (
                      promptList.map((option) => (
                        <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                          {option.prompt_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                  <p id="ErrorMessage">
                    {IvrGreetLongError && IvrGreetLongError}
                  </p>
                </div>

                {SelectIvrGreetLong == "say" && (
                  <div id="InputBox">
                    <p>
                      {" "}
                      Enter Greet Long Message <span>*</span>
                    </p>
                    <TextField
                      placeholder="Enter message here"
                      variant="outlined"
                      value={GreetLongMessage}
                      onChange={(e) =>
                        ErrorHandlingFunction(
                          e,
                          setGreetLongMessage,
                          setIvrGreetLongMessageError,
                          "Please enter greet long message"
                        )
                      }
                      fullWidth
                    />
                    <p id="ErrorMessage">
                      {IvrGreetLongMessageError && IvrGreetLongMessageError}
                    </p>
                  </div>
                )}
              </div>

              {SelectIvrGreetLong == "tts_prompt" && (
                <>
                  <div id="RowBox">
                    <div id="InputBox">
                      <p>
                        {" "}
                        Enter Name For TTS Prompt <span>*</span>{" "}
                      </p>
                      <TextField
                        placeholder="Enter name here"
                        variant="outlined"
                        value={TtsLongPromptName}
                        onChange={(e) =>
                          ErrorHandlingFunction(
                            e,
                            setTtsLongPromptName,
                            setIvrLongNameError,
                            "Please enter a name for TTS prompt"
                          )
                        }
                        fullWidth
                        style={{ width: "100%" }}
                      />
                      <p id="ErrorMessage">
                        {IvrLongNameError && IvrLongNameError}
                      </p>
                    </div>

                    <div id="InputBox">
                      <p>
                        {" "}
                        Enter Text For TTS Prompt <span>*</span>{" "}
                      </p>
                      <TextField
                        placeholder="Enter text here"
                        variant="outlined"
                        value={TtsLongPromptText}
                        onChange={(e) =>
                          ErrorHandlingFunction(
                            e,
                            setTtsLongPromptText,
                            setIvrLongTextError,
                            "Please enter a text for TTS prompt "
                          )
                        }
                        fullWidth
                        style={{ width: "100%" }}
                      />
                      <p id="ErrorMessage">
                        {IvrLongTextError && IvrLongTextError}
                      </p>
                    </div>
                  </div>

                  <div id="RowBox">
                    <div id="InputBox">
                      <p>
                        {" "}
                        Select Language For Greet Long <span>*</span>{" "}
                      </p>

                      <Select
                        id="node-type"
                        value={TtsLongPromptLanguage}
                        onChange={(e) =>
                          ErrorHandlingFunction(
                            e,
                            setTtsLongPromptLanguage,
                            setIvrLongLanguageError,
                            "Please select a language"
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="1" disabled>
                          Select Language For Greet Long
                        </MenuItem>
                        <MenuItem value="en-US">English</MenuItem>
                        <MenuItem value="hi-IN">Hindi</MenuItem>
                      </Select>
                      <p id="ErrorMessage">
                        {IvrLongLanguageError && IvrLongLanguageError}
                      </p>
                    </div>

                    <div id="InputBox">
                      <p>
                        {" "}
                        Select Voice Type For Greet Long <span>*</span>{" "}
                      </p>
                      <Select
                        id="node-type"
                        value={TtsLongPromptVoiceType}
                        onChange={(e) =>
                          ErrorHandlingFunction(
                            e,
                            setTtsLongPromptVoiceType,
                            setIvrLongVoiceTypeError,
                            "Please select a voice type"
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="1" disabled>
                          Select Voice Type for Greet Long
                        </MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                      <p id="ErrorMessage">
                        {IvrLongVoiceTypeError && IvrLongVoiceTypeError}
                      </p>
                    </div>
                  </div>
                </>
              )}

              <div id="RowBox">
                <div id="InputBox">
                  <p>
                    {" "}
                    Select Greet Short <span>*</span>{" "}
                  </p>
                  <Select
                    id="node-type"
                    value={SelectIvrGreetShort}
                    onChange={(e) =>
                      ErrorHandlingFunction(
                        e,
                        setSelectIvrGreetShort,
                        setIvrGreetShortError,
                        "Please select a greet short"
                      )
                    }
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="1" disabled>
                      Select Greet Short
                    </MenuItem>
                    <MenuItem value="say">Say</MenuItem>
                    <MenuItem value="tts_prompt">TTS Prompt</MenuItem>
                    {promptList ? (
                      promptList.map((option) => (
                        <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                          {option.prompt_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                  <p id="ErrorMessage">
                    {IvrGreetShortError && IvrGreetShortError}
                  </p>
                </div>

                {SelectIvrGreetShort == "say" && (
                  <div id="InputBox">
                    <p>
                      {" "}
                      Enter Greet Short Message <span>*</span>{" "}
                    </p>
                    <TextField
                      placeholder="Enter message here"
                      variant="outlined"
                      value={GreetShortMessage}
                      onChange={(e) =>
                        ErrorHandlingFunction(
                          e,
                          setGreetShortMessage,
                          setIvrGreetShortMessageError,
                          "Please enter greet short message"
                        )
                      }
                      fullWidth
                    />
                    <p id="ErrorMessage">
                      {IvrGreetShortMessageError && IvrGreetShortMessageError}
                    </p>
                  </div>
                )}
              </div>

              {SelectIvrGreetShort == "tts_prompt" && (
                <>
                  <div id="RowBox">
                    <div id="InputBox">
                      <p>
                        {" "}
                        Enter Name For TTS Prompt <span>*</span>{" "}
                      </p>
                      <TextField
                        placeholder="Enter name here"
                        variant="outlined"
                        value={TtsShortPromptName}
                        onChange={(e) =>
                          ErrorHandlingFunction(
                            e,
                            setTTSShortPromptName,
                            setIvrShortNameError,
                            "Please enter a name for TTS prompt"
                          )
                        }
                        fullWidth
                      />
                      <p id="ErrorMessage">
                        {IvrShortNameError && IvrShortNameError}
                      </p>
                    </div>

                    <div id="InputBox">
                      <p>
                        {" "}
                        Enter Text For TTS Prompt <span>*</span>{" "}
                      </p>
                      <TextField
                        placeholder="Enter text here"
                        variant="outlined"
                        value={TtsShortPromptText}
                        onChange={(e) =>
                          ErrorHandlingFunction(
                            e,
                            setTTSShortPromptText,
                            setIvrShortTextError,
                            "Please enter a text for TTS prompt"
                          )
                        }
                        fullWidth
                      />
                      <p id="ErrorMessage">
                        {IvrShortTextError && IvrShortTextError}
                      </p>
                    </div>
                  </div>

                  <div id="RowBox">
                    <div id="InputBox">
                      <p>
                        {" "}
                        Select Language For Greet Short <span>*</span>{" "}
                      </p>
                      <Select
                        id="node-type"
                        value={TtsShortPromptLanguage}
                        onChange={(e) =>
                          ErrorHandlingFunction(
                            e,
                            setTtsShortPromptLanguage,
                            setIvrShortLanguageError,
                            "Please select a language"
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="1" disabled>
                          Select Language For Greet Short
                        </MenuItem>
                        <MenuItem value="en-US">English</MenuItem>
                        <MenuItem value="hi-IN">Hindi</MenuItem>
                      </Select>
                      <p id="ErrorMessage">
                        {IvrShortLanguageError && IvrShortLanguageError}
                      </p>
                    </div>

                    <div id="InputBox">
                      <p>
                        {" "}
                        Select Voice Type For Greet Short <span>*</span>{" "}
                      </p>
                      <Select
                        id="node-type"
                        value={TtsShortPromptVoiceType}
                        onChange={(e) =>
                          ErrorHandlingFunction(
                            e,
                            setTtsShortPromptVoiceType,
                            setIvrShortVoiceTypeError,
                            "Please select a voice type"
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="1" disabled>
                          Select Voice Type For Greet Short
                        </MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                      <p id="ErrorMessage">
                        {IvrShortVoiceTypeError && IvrShortVoiceTypeError}
                      </p>
                    </div>
                  </div>
                </>
              )}

              <div id="RowBox">
                <div id="InputBox">
                  <p>
                    {" "}
                    Enter Max Failures <span>*</span>{" "}
                  </p>
                  <TextField
                    variant="outlined"
                    placeholder="Enter failures here"
                    type="number"
                    value={IvrMaxFailures}
                    onChange={(e) =>
                      ErrorHandlingMaxFailures(
                        e,
                        setIvrMaxFailures,
                        setIvrMaxFailuresError,
                        "Please enter max failures count ( in digts between 0 to 20 )"
                      )
                    }
                    fullWidth
                    inputProps={{ min: 0 }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "e" ||
                        e.key === "E" ||
                        e.key === "+" ||
                        e.key === "-"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <p id="ErrorMessage">
                    {IvrMaxFailuresError && IvrMaxFailuresError}
                  </p>
                </div>

                <div id="InputBox">
                  <p>
                    {" "}
                    Enter Max Timeout <span>*</span>{" "}
                  </p>
                  <TextField
                    variant="outlined"
                    placeholder="Enter timeout here"
                    type="number"
                    value={IvrMaxTimeout}
                    onChange={(e) =>
                      ErrorHandlingMaxTimeout(
                        e,
                        setIvrMaxTimeout,
                        setIvrMaxTimeoutError,
                        "Please enter max timeout ( in digits between 0 to 20 )"
                      )
                    }
                    fullWidth
                    inputProps={{ min: 0 }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "e" ||
                        e.key === "E" ||
                        e.key === "+" ||
                        e.key === "-"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <p id="ErrorMessage">
                    {IvrMaxTimeoutError && IvrMaxTimeoutError}
                  </p>
                </div>
              </div>

              <div id="RowBox">
                <div id="InputBox">
                  <p>
                    Enter Timeout (ms) <span>*</span>{" "}
                  </p>
                  <TextField
                    placeholder="Timeout (ms)"
                    variant="outlined"
                    type="number"
                    value={IvrTimeout}
                    onChange={(e) =>
                      ErrorHandlingTimeout(
                        e,
                        setIvrTimeout,
                        setIvrTimeoutError,
                        "Please enter timeout in milli-seconds ( in digits between 1000 to 9999 )"
                      )
                    }
                    fullWidth
                    inputProps={{ min: 1000 }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "e" ||
                        e.key === "E" ||
                        e.key === "+" ||
                        e.key === "-"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <p id="ErrorMessage">{IvrTimeoutError && IvrTimeoutError}</p>
                </div>
              </div>

              <div id="RowBox">
                {/* <div
                                    style={{
                                        display: "grid",
                                        alignItems: "center",
                                        gridTemplateColumns: '45% 45%',
                                        width: "100%",
                                        columnGap: "20px",
                                        padding: "20px 5px",
                                    }}
                                >
                                    <p style={{ margin: '0px' }}>Status</p>

                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                checked={IvrStatus}
                                                onChange={(e) => setIvrStatus(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                    />
                                </div> */}
                <div id="InputBox">
                  <p>
                    Select Exit Action <span style={{ opacity: "0" }}>*</span>{" "}
                  </p>
                  <Select
                    id="node-type"
                    value={IvrSelectIvrExitAction}
                    onChange={(e) => {
                      setIvrSelectIvrExitAction(e.target.value);
                      setIvrSelectIvrExitName("0");
                    }}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="1">Select Exit Action</MenuItem>
                    <MenuItem value="IVR">IVR</MenuItem>
                    <MenuItem value="QUEUE">QUEUE</MenuItem>
                    <MenuItem value="PLAYBACK">PLAYBACK</MenuItem>
                    <MenuItem value="VOICEMAIL">VOICEMAIL</MenuItem>
                  </Select>
                </div>

                {IvrSelectIvrExitAction !== "1" && (
                  <div id="InputBox">
                    <p>
                      Select Exit Name <span>*</span>{" "}
                    </p>
                    <Select
                      id="node-type"
                      value={IvrSelectIvrExitName}
                      onChange={(e) =>
                        ErrorHandlingFunction(
                          e,
                          setIvrSelectIvrExitName,
                          setIvrExitNameError,
                          "Please select an exit name"
                        )
                      }
                      style={{ width: "100%" }}
                    >
                      <MenuItem value="0" disabled>
                        Select Exit Name
                      </MenuItem>
                      {ExitNameList && IvrSelectIvrExitAction != "" ? (
                        ExitNameList.map((option) => (
                          <MenuItem
                            key={
                              option.ivr_menu_uuid
                                ? option.ivr_menu_uuid
                                : option.call_center_queue_uuid
                                  ? option.call_center_queue_uuid
                                  : option.pmt_uuid
                                    ? option.pmt_uuid
                                    : option.extension_uuid
                            }
                            value={
                              option.ivr_menu_uuid
                                ? option.ivr_menu_uuid
                                : option.call_center_queue_uuid
                                  ? option.call_center_queue_uuid
                                  : option.pmt_uuid
                                    ? option.pmt_uuid
                                    : option.extension_uuid
                            }
                          >
                            {option.ivr_menu_name
                              ? option.ivr_menu_name
                              : option.queue_name
                                ? option.queue_name
                                : option.prompt_name
                                  ? option.prompt_name
                                  : option.extension}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No data</MenuItem>
                      )}
                    </Select>
                    <p id="ErrorMessage">
                      {IvrExitNameError && IvrExitNameError}
                    </p>
                  </div>
                )}
              </div>

              <div id="RowBox">
                <div id="InputBox">
                  <p>
                    Enter Description <span style={{ opacity: "0" }}>*</span>{" "}
                  </p>
                  <TextField
                    placeholder="Enter description here"
                    variant="outlined"
                    value={IvrDescription}
                    onChange={(e) => setIvrDescription(e.target.value)}
                    fullWidth
                  />
                </div>

                <div id="InputBox">
                  <p>
                    Select Direct Dial <span>*</span>{" "}
                  </p>
                  <Select
                    id="node-type"
                    value={IvrSelectDirectDial}
                    onChange={(e) => setIvrSelectDirectDial(e.target.value)}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </div>
              </div>

              <div id="RowBox">
                <div id="InputBox">
                  <p>
                    Select Ring Back <span>*</span>{" "}
                  </p>
                  <Select
                    id="node-type"
                    value={IvrSelectRingBack}
                    onChange={(e) => setIvrSelectRingBack(e.target.value)}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="1" disabled>
                      Select Ring Back
                    </MenuItem>
                    <MenuItem value="${us-ring}">us-ring</MenuItem>
                    <MenuItem value="${fi-ring}">fi-ring</MenuItem>
                    <MenuItem value="${fr-ring}">fr-ring</MenuItem>
                    <MenuItem value="${pt-ring}">pt-ring</MenuItem>
                    <MenuItem value="${hk-ring}">hk-ring</MenuItem>
                    <MenuItem value="${hu-ring}">hu-ring</MenuItem>
                    <MenuItem value="${il-ring}">il-ring</MenuItem>
                    <MenuItem value="${in-ring}">in-ring</MenuItem>
                    <MenuItem value="${jp-ring}">jp-ring</MenuItem>
                    <MenuItem value="${ko-ring}">ko-ring</MenuItem>
                    <MenuItem value="${pk-ring}">pk-ring</MenuItem>
                    <MenuItem value="${pl-ring}">pl-ring</MenuItem>
                    <MenuItem value="${ro-ring}">ro-ring</MenuItem>
                    <MenuItem value="${rs-ring}">rs-ring</MenuItem>
                    <MenuItem value="${it-ring}">it-ring</MenuItem>
                    <MenuItem value="${ru-ring}">ru-ring</MenuItem>
                    <MenuItem value="${sa-ring}">sa-ring</MenuItem>
                    <MenuItem value="${tr-ring}">tr-ring</MenuItem>
                    <MenuItem value="${uk-ring}">uk-ring</MenuItem>
                    <MenuItem value="${bong-ri}ng">bong-ring</MenuItem>
                    <MenuItem value="${au-ring}">au-ring</MenuItem>
                    <MenuItem value="${be-ring}">be-ring</MenuItem>
                    <MenuItem value="${ca-ring}">ca-ring</MenuItem>
                    <MenuItem value="${cn-ring}">cn-ring</MenuItem>
                    <MenuItem value="${cy-ring}">cy-ring</MenuItem>
                    <MenuItem value="${cz-ring}">cz-ring</MenuItem>
                    <MenuItem value="${de-ring}">de-ring</MenuItem>
                    <MenuItem value="${dk-ring}">dk-ring</MenuItem>
                    <MenuItem value="${dz-ring}">dz-ring</MenuItem>
                    <MenuItem value="${eg-ring}">eg-ring</MenuItem>
                  </Select>
                </div>

                <div id="InputBox">
                  <p>
                    Enter Caller ID Name Prefix{" "}
                    <span style={{ opacity: "0" }}>*</span>{" "}
                  </p>
                  <TextField
                    placeholder="Enter prefix here"
                    variant="outlined"
                    value={IvrCallerIdPrefix}
                    onChange={(e) => setIvrCallerIdPrefix(e.target.value)}
                    fullWidth
                  />
                </div>
              </div>

              <div id="RowBox">
                <div id="InputBox">
                  <p>
                    Select Invalid Sound <span>*</span>{" "}
                  </p>
                  <Select
                    id="node-type"
                    value={IvrInvalidSound}
                    onChange={(e) =>
                      ErrorHandlingFunction(
                        e,
                        setIvrInvalidSound,
                        setIvrInvalidSoundError,
                        "Please select an invalid sound"
                      )
                    }
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="0" disabled>
                      Select Invalid Sound
                    </MenuItem>
                    {promptList ? (
                      promptList.map((option) => (
                        <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                          {option.prompt_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                  <p id="ErrorMessage">
                    {IvrInvalidSoundError && IvrInvalidSoundError}
                  </p>
                </div>

                <div id="InputBox">
                  <p>
                    Select Exit Sound <span>*</span>{" "}
                  </p>
                  <Select
                    id="node-type"
                    value={IvrExitSound}
                    onChange={(e) =>
                      ErrorHandlingFunction(
                        e,
                        setIvrExitSound,
                        setIvrExitSoundError,
                        "Please select an exit sound"
                      )
                    }
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="0" disabled>
                      Select Exit Sound
                    </MenuItem>
                    {promptList ? (
                      promptList.map((option) => (
                        <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                          {option.prompt_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                  <p id="ErrorMessage">
                    {IvrExitSoundError && IvrExitSoundError}
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "15px",
                paddingTop: "10px",
              }}
            >
              <Button
                id="CancelButtonFrontPage"
                onClick={() => HandleCancelButtonIvrModal()}
              >
                Cancel
              </Button>

              <Button type="submit" id="AddFlowButton" variant="contained">
                Edit
              </Button>
            </div>
          </Box>
        </form>
      </Modal>

      {/* Modal for creating the Voice Bot configuration */}
      <Modal
        open={CreateVoiceBotConfigurationModal}
        onClose={() => HandleCancelButtonVoiceBotModal()}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddVoiceBotConfiguration();
          }}
        >
          <Box
            sx={{ ...voiceBotStyle }}
            id="IvrConfigurationModal"
            style={{ height: "fit-content", rowGap: "30px" }}
          >
            <h2>Add Voice Bot Configuration</h2>

            <div id="AllInputBoxes">
              <div id="RowBox">
                <div id="InputBox">
                  <p>
                    {" "}
                    Enter configuration name <span>*</span>
                  </p>
                  <TextField
                    placeholder="Enter name here"
                    variant="outlined"
                    value={voiceBotConfigurationName}
                    onChange={(e) =>
                      ErrorHandlingFunction(
                        e,
                        setVoiceBotConfigurationName,
                        setVoiceBotNameError,
                        "Please enter configuration name."
                      )
                    }
                    fullWidth
                    style={{ width: "100%" }}
                  />
                  {VoiceBotNameError && (
                    <p id="ErrorMessage"> {VoiceBotNameError} </p>
                  )}
                </div>

                <div id="InputBox">
                  <p>
                    {" "}
                    Enter Description
                    <span style={{ opacity: "0" }}>*</span>
                  </p>
                  <TextField
                    placeholder="Enter extension here"
                    variant="outlined"
                    value={voiceBotDescription}
                    onChange={(e) => setVoiceBotDescription(e.target.value)}
                    fullWidth
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "20% 80%",
                  justifyContent: "space-between",
                  padding: "0px 5px",
                }}
              >
                <p
                  style={{
                    color: "#746df7",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  Max Retry :{" "}
                </p>
                <Slider
                  value={VoiceBotRetryValue}
                  onChange={(e) => setVoiceBotRetryValue(e.target.value)}
                  valueLabelDisplay="auto"
                  step={1}
                  marks={VoiceBotRetryValueArray}
                  min={1}
                  max={5}
                  style={{ width: "100%", marginTop: "20px " }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "15px",
                paddingTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                id="CancelButtonFrontPage"
                onClick={() => HandleCancelButtonVoiceBotModal()}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                id="AddFlowButton"
                variant="contained"
              // onClick={handleAddVoiceBotConfiguration}
              >
                Add
              </Button>
            </div>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default WorkflowBuilder;
