
export const Voices = [
    {
        "language": "Afrikaans (South Africa)",
        "type": "Standard",
        "language_code": "af-ZA",
        "voice_name": "af-ZA-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Arabic",
        "type": "Standard",
        "language_code": "ar-XA",
        "voice_name": "ar-XA-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Arabic",
        "type": "Standard",
        "language_code": "ar-XA",
        "voice_name": "ar-XA-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Arabic",
        "type": "Standard",
        "language_code": "ar-XA",
        "voice_name": "ar-XA-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Arabic",
        "type": "Standard",
        "language_code": "ar-XA",
        "voice_name": "ar-XA-Standard-D",
        "gender": "FEMALE"
    },
    {
        "language": "Arabic",
        "type": "Premium",
        "language_code": "ar-XA",
        "voice_name": "ar-XA-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Arabic",
        "type": "Premium",
        "language_code": "ar-XA",
        "voice_name": "ar-XA-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Arabic",
        "type": "Premium",
        "language_code": "ar-XA",
        "voice_name": "ar-XA-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Arabic",
        "type": "Premium",
        "language_code": "ar-XA",
        "voice_name": "ar-XA-Wavenet-D",
        "gender": "FEMALE"
    },
    {
        "language": "Basque (Spain)",
        "type": "Standard",
        "language_code": "eu-ES",
        "voice_name": "eu-ES-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Basque (Spain)",
        "type": "Standard",
        "language_code": "eu-ES",
        "voice_name": "eu-ES-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Bengali (India)",
        "type": "Standard",
        "language_code": "bn-IN",
        "voice_name": "bn-IN-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Bengali (India)",
        "type": "Standard",
        "language_code": "bn-IN",
        "voice_name": "bn-IN-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Bengali (India)",
        "type": "Standard",
        "language_code": "bn-IN",
        "voice_name": "bn-IN-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Bengali (India)",
        "type": "Standard",
        "language_code": "bn-IN",
        "voice_name": "bn-IN-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Bengali (India)",
        "type": "Premium",
        "language_code": "bn-IN",
        "voice_name": "bn-IN-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Bengali (India)",
        "type": "Premium",
        "language_code": "bn-IN",
        "voice_name": "bn-IN-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Bengali (India)",
        "type": "Premium",
        "language_code": "bn-IN",
        "voice_name": "bn-IN-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "Bengali (India)",
        "type": "Premium",
        "language_code": "bn-IN",
        "voice_name": "bn-IN-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Bulgarian (Bulgaria)",
        "type": "Standard",
        "language_code": "bg-BG",
        "voice_name": "bg-BG-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Bulgarian (Bulgaria)",
        "type": "Standard",
        "language_code": "bg-BG",
        "voice_name": "bg-BG-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Catalan (Spain)",
        "type": "Standard",
        "language_code": "ca-ES",
        "voice_name": "ca-ES-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Catalan (Spain)",
        "type": "Standard",
        "language_code": "ca-ES",
        "voice_name": "ca-ES-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Chinese (Hong Kong)",
        "type": "Standard",
        "language_code": "yue-HK",
        "voice_name": "yue-HK-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Chinese (Hong Kong)",
        "type": "Standard",
        "language_code": "yue-HK",
        "voice_name": "yue-HK-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Chinese (Hong Kong)",
        "type": "Standard",
        "language_code": "yue-HK",
        "voice_name": "yue-HK-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Chinese (Hong Kong)",
        "type": "Standard",
        "language_code": "yue-HK",
        "voice_name": "yue-HK-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Czech (Czech Republic)",
        "type": "Standard",
        "language_code": "cs-CZ",
        "voice_name": "cs-CZ-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Czech (Czech Republic)",
        "type": "Standard",
        "language_code": "cs-CZ",
        "voice_name": "cs-CZ-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Czech (Czech Republic)",
        "type": "Premium",
        "language_code": "cs-CZ",
        "voice_name": "cs-CZ-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Premium",
        "language_code": "da-DK",
        "voice_name": "da-DK-Neural2-D",
        "gender": "FEMALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Standard",
        "language_code": "da-DK",
        "voice_name": "da-DK-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Standard",
        "language_code": "da-DK",
        "voice_name": "da-DK-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Standard",
        "language_code": "da-DK",
        "voice_name": "da-DK-Standard-D",
        "gender": "FEMALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Standard",
        "language_code": "da-DK",
        "voice_name": "da-DK-Standard-E",
        "gender": "FEMALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Standard",
        "language_code": "da-DK",
        "voice_name": "da-DK-Standard-F",
        "gender": "FEMALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Standard",
        "language_code": "da-DK",
        "voice_name": "da-DK-Standard-G",
        "gender": "MALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Premium",
        "language_code": "da-DK",
        "voice_name": "da-DK-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Premium",
        "language_code": "da-DK",
        "voice_name": "da-DK-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Premium",
        "language_code": "da-DK",
        "voice_name": "da-DK-Wavenet-D",
        "gender": "FEMALE"
    },
    {
        "language": "Danish (Denmark)",
        "type": "Premium",
        "language_code": "da-DK",
        "voice_name": "da-DK-Wavenet-E",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Belgium)",
        "type": "Standard",
        "language_code": "nl-BE",
        "voice_name": "nl-BE-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Belgium)",
        "type": "Standard",
        "language_code": "nl-BE",
        "voice_name": "nl-BE-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Dutch (Belgium)",
        "type": "Standard",
        "language_code": "nl-BE",
        "voice_name": "nl-BE-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Belgium)",
        "type": "Standard",
        "language_code": "nl-BE",
        "voice_name": "nl-BE-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Dutch (Belgium)",
        "type": "Premium",
        "language_code": "nl-BE",
        "voice_name": "nl-BE-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Belgium)",
        "type": "Premium",
        "language_code": "nl-BE",
        "voice_name": "nl-BE-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Standard",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Standard",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Standard",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Standard",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Standard-D",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Standard",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Standard-E",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Standard",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Standard-F",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Standard",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Standard-G",
        "gender": "MALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Premium",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Premium",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Premium",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Premium",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Wavenet-D",
        "gender": "FEMALE"
    },
    {
        "language": "Dutch (Netherlands)",
        "type": "Premium",
        "language_code": "nl-NL",
        "voice_name": "nl-NL-Wavenet-E",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Journey-D",
        "gender": "MALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Journey-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Journey-O",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Neural2-B",
        "gender": "MALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Neural2-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Neural2-D",
        "gender": "MALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-News-E",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-News-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-News-G",
        "gender": "MALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Polyglot-1",
        "gender": "MALE"
    },
    {
        "language": "English (Australia)",
        "type": "Standard",
        "language_code": "en-AU",
        "voice_name": "en-AU-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Standard",
        "language_code": "en-AU",
        "voice_name": "en-AU-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "English (Australia)",
        "type": "Standard",
        "language_code": "en-AU",
        "voice_name": "en-AU-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Standard",
        "language_code": "en-AU",
        "voice_name": "en-AU-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (Australia)",
        "type": "Premium",
        "language_code": "en-AU",
        "voice_name": "en-AU-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-Journey-D",
        "gender": "MALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-Journey-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-Journey-O",
        "gender": "FEMALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-Neural-A",
        "gender": "MALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-Neural-B",
        "gender": "FEMALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-Neural-C",
        "gender": "MALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-Neural-D",
        "gender": "FEMALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-News-A",
        "gender": "MALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-News-B",
        "gender": "FEMALE"
    },
    {
        "language": "English (India)",
        "type": "Premium",
        "language_code": "en-IN",
        "voice_name": "en-IN-News-C",
        "gender": "MALE"
    },
    {
        "language": "English (India)",
        "type": "Standard",
        "language_code": "en-IN",
        "voice_name": "en-IN-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "English (India)",
        "type": "Standard",
        "language_code": "en-IN",
        "voice_name": "en-IN-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "English (India)",
        "type": "Standard",
        "language_code": "en-IN",
        "voice_name": "en-IN-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (India)",
        "type": "Standard",
        "language_code": "en-IN",
        "voice_name": "en-IN-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Journey-D",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Journey-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Journey-O",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Neural2-B",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Neural2-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Neural2-D",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Neural2-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-News-G",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-News-H",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-News-I",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-News-J",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-News-K",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-News-L",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-News-M",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Standard",
        "language_code": "en-GB",
        "voice_name": "en-GB-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Standard",
        "language_code": "en-GB",
        "voice_name": "en-GB-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Standard",
        "language_code": "en-GB",
        "voice_name": "en-GB-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Standard",
        "language_code": "en-GB",
        "voice_name": "en-GB-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Standard",
        "language_code": "en-GB",
        "voice_name": "en-GB-Standard-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Standard",
        "language_code": "en-GB",
        "voice_name": "en-GB-Standard-N",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Standard",
        "language_code": "en-GB",
        "voice_name": "en-GB-Standard-O",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Studio",
        "language_code": "en-GB",
        "voice_name": "en-GB-Studio-B",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Studio",
        "language_code": "en-GB",
        "voice_name": "en-GB-Studio-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "English (UK)",
        "type": "Premium",
        "language_code": "en-GB",
        "voice_name": "en-GB-Wavenet-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Casual-K",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Journey-D",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Journey-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Journey-O",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Neural2-A",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Neural2-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Neural2-D",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Neural2-E",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Neural2-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Neural2-G",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Neural2-H",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Neural2-I",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Neural2-J",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-News-K",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-News-L",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-News-N",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Polyglot-1",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-A",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-E",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-G",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-H",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-I",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Standard",
        "language_code": "en-US",
        "voice_name": "en-US-Standard-J",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Studio",
        "language_code": "en-US",
        "voice_name": "en-US-Studio-O",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Studio",
        "language_code": "en-US",
        "voice_name": "en-US-Studio-Q",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-A",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-E",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-F",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-G",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-H",
        "gender": "FEMALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-I",
        "gender": "MALE"
    },
    {
        "language": "English (US)",
        "type": "Premium",
        "language_code": "en-US",
        "voice_name": "en-US-Wavenet-J",
        "gender": "MALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Standard",
        "language_code": "fil-PH",
        "voice_name": "fil-PH-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Standard",
        "language_code": "fil-PH",
        "voice_name": "fil-PH-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Standard",
        "language_code": "fil-PH",
        "voice_name": "fil-PH-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Standard",
        "language_code": "fil-PH",
        "voice_name": "fil-PH-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Premium",
        "language_code": "fil-PH",
        "voice_name": "fil-PH-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Premium",
        "language_code": "fil-PH",
        "voice_name": "fil-PH-Wavenet-B",
        "gender": "FEMALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Premium",
        "language_code": "fil-PH",
        "voice_name": "fil-PH-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Premium",
        "language_code": "fil-PH",
        "voice_name": "fil-PH-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Premium",
        "language_code": "fil-PH",
        "voice_name": "fil-ph-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "Filipino (Philippines)",
        "type": "Premium",
        "language_code": "fil-PH",
        "voice_name": "fil-ph-Neural2-D",
        "gender": "MALE"
    },
    {
        "language": "Finnish (Finland)",
        "type": "Standard",
        "language_code": "fi-FI",
        "voice_name": "fi-FI-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Finnish (Finland)",
        "type": "Standard",
        "language_code": "fi-FI",
        "voice_name": "fi-FI-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Finnish (Finland)",
        "type": "Premium",
        "language_code": "fi-FI",
        "voice_name": "fi-FI-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Journey-D",
        "gender": "MALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Journey-F",
        "gender": "FEMALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Journey-O",
        "gender": "FEMALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Neural2-B",
        "gender": "MALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Neural2-C",
        "gender": "FEMALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Neural2-D",
        "gender": "MALE"
    },
    {
        "language": "French (Canada)",
        "type": "Standard",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "French (Canada)",
        "type": "Standard",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "French (Canada)",
        "type": "Standard",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "French (Canada)",
        "type": "Standard",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "French (Canada)",
        "type": "Premium",
        "language_code": "fr-CA",
        "voice_name": "fr-CA-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Journey-D",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Journey-F",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Journey-O",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Neural2-B",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Neural2-C",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Neural2-D",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Neural2-E",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Polyglot-1",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Standard",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Standard",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Standard",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Standard",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Standard",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Standard-E",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Standard",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Standard-F",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Standard",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Standard-G",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Studio",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Studio-A",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Studio",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Studio-D",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Wavenet-E",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Wavenet-F",
        "gender": "FEMALE"
    },
    {
        "language": "French (France)",
        "type": "Premium",
        "language_code": "fr-FR",
        "voice_name": "fr-FR-Wavenet-G",
        "gender": "MALE"
    },
    {
        "language": "Galician (Spain)",
        "type": "Standard",
        "language_code": "gl-ES",
        "voice_name": "gl-ES-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Galician (Spain)",
        "type": "Standard",
        "language_code": "gl-ES",
        "voice_name": "gl-ES-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Journey-D",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Journey-F",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Journey-O",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Neural2-B",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Neural2-C",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Neural2-D",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Neural2-F",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Polyglot-1",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Standard",
        "language_code": "de-DE",
        "voice_name": "de-DE-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Standard",
        "language_code": "de-DE",
        "voice_name": "de-DE-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Standard",
        "language_code": "de-DE",
        "voice_name": "de-DE-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Standard",
        "language_code": "de-DE",
        "voice_name": "de-DE-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Standard",
        "language_code": "de-DE",
        "voice_name": "de-DE-Standard-E",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Standard",
        "language_code": "de-DE",
        "voice_name": "de-DE-Standard-F",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Standard",
        "language_code": "de-DE",
        "voice_name": "de-DE-Standard-G",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Studio",
        "language_code": "de-DE",
        "voice_name": "de-DE-Studio-A",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Studio",
        "language_code": "de-DE",
        "voice_name": "de-DE-Studio-B",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "German (Germany)",
        "type": "Premium",
        "language_code": "de-DE",
        "voice_name": "de-DE-Wavenet-E",
        "gender": "FEMALE"
    },
    {
        "language": "Hebrew (Israel)",
        "type": "Standard",
        "language_code": "he-IL",
        "voice_name": "he-IL-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Hebrew (Israel)",
        "type": "Standard",
        "language_code": "he-IL",
        "voice_name": "he-IL-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Hebrew (Israel)",
        "type": "Standard",
        "language_code": "he-IL",
        "voice_name": "he-IL-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Hebrew (Israel)",
        "type": "Standard",
        "language_code": "he-IL",
        "voice_name": "he-IL-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Hebrew (Israel)",
        "type": "Premium",
        "language_code": "he-IL",
        "voice_name": "he-IL-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Hebrew (Israel)",
        "type": "Premium",
        "language_code": "he-IL",
        "voice_name": "he-IL-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Hebrew (Israel)",
        "type": "Premium",
        "language_code": "he-IL",
        "voice_name": "he-IL-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "Hebrew (Israel)",
        "type": "Premium",
        "language_code": "he-IL",
        "voice_name": "he-IL-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Neural2-B",
        "gender": "MALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Neural2-C",
        "gender": "MALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Neural2-D",
        "gender": "FEMALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Standard",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Standard",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Standard",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Standard",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Standard-D",
        "gender": "FEMALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Standard",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Standard-E",
        "gender": "FEMALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Standard",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Standard-F",
        "gender": "MALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Wavenet-D",
        "gender": "FEMALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Wavenet-E",
        "gender": "FEMALE"
    },
    {
        "language": "Hindi (India)",
        "type": "Premium",
        "language_code": "hi-IN",
        "voice_name": "hi-IN-Wavenet-F",
        "gender": "MALE"
    },
    {
        "language": "Hungarian (Hungary)",
        "type": "Standard",
        "language_code": "hu-HU",
        "voice_name": "hu-HU-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Hungarian (Hungary)",
        "type": "Standard",
        "language_code": "hu-HU",
        "voice_name": "hu-HU-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Hungarian (Hungary)",
        "type": "Premium",
        "language_code": "hu-HU",
        "voice_name": "hu-HU-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Icelandic (Iceland)",
        "type": "Standard",
        "language_code": "is-IS",
        "voice_name": "is-IS-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Icelandic (Iceland)",
        "type": "Standard",
        "language_code": "is-IS",
        "voice_name": "is-IS-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Indonesian (Indonesia)",
        "type": "Standard",
        "language_code": "id-ID",
        "voice_name": "id-ID-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Indonesian (Indonesia)",
        "type": "Standard",
        "language_code": "id-ID",
        "voice_name": "id-ID-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Indonesian (Indonesia)",
        "type": "Standard",
        "language_code": "id-ID",
        "voice_name": "id-ID-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Indonesian (Indonesia)",
        "type": "Standard",
        "language_code": "id-ID",
        "voice_name": "id-ID-Standard-D",
        "gender": "FEMALE"
    },
    {
        "language": "Indonesian (Indonesia)",
        "type": "Premium",
        "language_code": "id-ID",
        "voice_name": "id-ID-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Indonesian (Indonesia)",
        "type": "Premium",
        "language_code": "id-ID",
        "voice_name": "id-ID-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Indonesian (Indonesia)",
        "type": "Premium",
        "language_code": "id-ID",
        "voice_name": "id-ID-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Indonesian (Indonesia)",
        "type": "Premium",
        "language_code": "id-ID",
        "voice_name": "id-ID-Wavenet-D",
        "gender": "FEMALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Premium",
        "language_code": "it-IT",
        "voice_name": "it-IT-Journey-D",
        "gender": "MALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Premium",
        "language_code": "it-IT",
        "voice_name": "it-IT-Journey-F",
        "gender": "FEMALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Premium",
        "language_code": "it-IT",
        "voice_name": "it-IT-Journey-O",
        "gender": "FEMALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Premium",
        "language_code": "it-IT",
        "voice_name": "it-IT-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Premium",
        "language_code": "it-IT",
        "voice_name": "it-IT-Neural2-C",
        "gender": "MALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Standard",
        "language_code": "it-IT",
        "voice_name": "it-IT-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Standard",
        "language_code": "it-IT",
        "voice_name": "it-IT-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Standard",
        "language_code": "it-IT",
        "voice_name": "it-IT-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Standard",
        "language_code": "it-IT",
        "voice_name": "it-IT-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Standard",
        "language_code": "it-IT",
        "voice_name": "it-IT-Standard-E",
        "gender": "FEMALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Standard",
        "language_code": "it-IT",
        "voice_name": "it-IT-Standard-F",
        "gender": "MALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Premium",
        "language_code": "it-IT",
        "voice_name": "it-IT-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Premium",
        "language_code": "it-IT",
        "voice_name": "it-IT-Wavenet-B",
        "gender": "FEMALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Premium",
        "language_code": "it-IT",
        "voice_name": "it-IT-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Italian (Italy)",
        "type": "Premium",
        "language_code": "it-IT",
        "voice_name": "it-IT-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Premium",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Neural2-B",
        "gender": "FEMALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Premium",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Neural2-C",
        "gender": "MALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Premium",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Neural2-D",
        "gender": "MALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Standard",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Standard",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Standard",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Standard",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Premium",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Premium",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Wavenet-B",
        "gender": "FEMALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Premium",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Japanese (Japan)",
        "type": "Premium",
        "language_code": "ja-JP",
        "voice_name": "ja-JP-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Kannada (India)",
        "type": "Standard",
        "language_code": "kn-IN",
        "voice_name": "kn-IN-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Kannada (India)",
        "type": "Standard",
        "language_code": "kn-IN",
        "voice_name": "kn-IN-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Kannada (India)",
        "type": "Standard",
        "language_code": "kn-IN",
        "voice_name": "kn-IN-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Kannada (India)",
        "type": "Standard",
        "language_code": "kn-IN",
        "voice_name": "kn-IN-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Kannada (India)",
        "type": "Premium",
        "language_code": "kn-IN",
        "voice_name": "kn-IN-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Kannada (India)",
        "type": "Premium",
        "language_code": "kn-IN",
        "voice_name": "kn-IN-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Kannada (India)",
        "type": "Premium",
        "language_code": "kn-IN",
        "voice_name": "kn-IN-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "Kannada (India)",
        "type": "Premium",
        "language_code": "kn-IN",
        "voice_name": "kn-IN-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Premium",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Premium",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Neural2-B",
        "gender": "FEMALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Premium",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Neural2-C",
        "gender": "MALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Standard",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Standard",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Standard",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Standard",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Premium",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Premium",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Wavenet-B",
        "gender": "FEMALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Premium",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Korean (South Korea)",
        "type": "Premium",
        "language_code": "ko-KR",
        "voice_name": "ko-KR-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Latvian (Latvia)",
        "type": "Standard",
        "language_code": "lv-LV",
        "voice_name": "lv-LV-Standard-A",
        "gender": "MALE"
    },
    {
        "language": "Latvian (Latvia)",
        "type": "Standard",
        "language_code": "lv-LV",
        "voice_name": "lv-LV-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Lithuanian (Lithuania)",
        "type": "Standard",
        "language_code": "lt-LT",
        "voice_name": "lt-LT-Standard-A",
        "gender": "MALE"
    },
    {
        "language": "Lithuanian (Lithuania)",
        "type": "Standard",
        "language_code": "lt-LT",
        "voice_name": "lt-LT-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Malay (Malaysia)",
        "type": "Standard",
        "language_code": "ms-MY",
        "voice_name": "ms-MY-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Malay (Malaysia)",
        "type": "Standard",
        "language_code": "ms-MY",
        "voice_name": "ms-MY-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Malay (Malaysia)",
        "type": "Standard",
        "language_code": "ms-MY",
        "voice_name": "ms-MY-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Malay (Malaysia)",
        "type": "Standard",
        "language_code": "ms-MY",
        "voice_name": "ms-MY-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Malay (Malaysia)",
        "type": "Premium",
        "language_code": "ms-MY",
        "voice_name": "ms-MY-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Malay (Malaysia)",
        "type": "Premium",
        "language_code": "ms-MY",
        "voice_name": "ms-MY-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Malay (Malaysia)",
        "type": "Premium",
        "language_code": "ms-MY",
        "voice_name": "ms-MY-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "Malay (Malaysia)",
        "type": "Premium",
        "language_code": "ms-MY",
        "voice_name": "ms-MY-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Malayalam (India)",
        "type": "Standard",
        "language_code": "ml-IN",
        "voice_name": "ml-IN-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Malayalam (India)",
        "type": "Standard",
        "language_code": "ml-IN",
        "voice_name": "ml-IN-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Malayalam (India)",
        "type": "Standard",
        "language_code": "ml-IN",
        "voice_name": "ml-IN-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Malayalam (India)",
        "type": "Standard",
        "language_code": "ml-IN",
        "voice_name": "ml-IN-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Malayalam (India)",
        "type": "Premium",
        "language_code": "ml-IN",
        "voice_name": "ml-IN-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Malayalam (India)",
        "type": "Premium",
        "language_code": "ml-IN",
        "voice_name": "ml-IN-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Malayalam (India)",
        "type": "Premium",
        "language_code": "ml-IN",
        "voice_name": "ml-IN-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "Malayalam (India)",
        "type": "Premium",
        "language_code": "ml-IN",
        "voice_name": "ml-IN-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Standard",
        "language_code": "cmn-CN",
        "voice_name": "cmn-CN-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Standard",
        "language_code": "cmn-CN",
        "voice_name": "cmn-CN-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Standard",
        "language_code": "cmn-CN",
        "voice_name": "cmn-CN-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Standard",
        "language_code": "cmn-CN",
        "voice_name": "cmn-CN-Standard-D",
        "gender": "FEMALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Premium",
        "language_code": "cmn-CN",
        "voice_name": "cmn-CN-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Premium",
        "language_code": "cmn-CN",
        "voice_name": "cmn-CN-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Premium",
        "language_code": "cmn-CN",
        "voice_name": "cmn-CN-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Premium",
        "language_code": "cmn-CN",
        "voice_name": "cmn-CN-Wavenet-D",
        "gender": "FEMALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Standard",
        "language_code": "cmn-TW",
        "voice_name": "cmn-TW-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Standard",
        "language_code": "cmn-TW",
        "voice_name": "cmn-TW-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Standard",
        "language_code": "cmn-TW",
        "voice_name": "cmn-TW-Standard-C",
        "gender": "MALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Premium",
        "language_code": "cmn-TW",
        "voice_name": "cmn-TW-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Premium",
        "language_code": "cmn-TW",
        "voice_name": "cmn-TW-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Mandarin Chinese",
        "type": "Premium",
        "language_code": "cmn-TW",
        "voice_name": "cmn-TW-Wavenet-C",
        "gender": "MALE"
    },
    {
        "language": "Marathi (India)",
        "type": "Standard",
        "language_code": "mr-IN",
        "voice_name": "mr-IN-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Marathi (India)",
        "type": "Standard",
        "language_code": "mr-IN",
        "voice_name": "mr-IN-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Marathi (India)",
        "type": "Standard",
        "language_code": "mr-IN",
        "voice_name": "mr-IN-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Marathi (India)",
        "type": "Premium",
        "language_code": "mr-IN",
        "voice_name": "mr-IN-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Marathi (India)",
        "type": "Premium",
        "language_code": "mr-IN",
        "voice_name": "mr-IN-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Marathi (India)",
        "type": "Premium",
        "language_code": "mr-IN",
        "voice_name": "mr-IN-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "Romanian (Romania)",
        "type": "Standard",
        "language_code": "ro-RO",
        "voice_name": "ro-RO-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Romanian (Romania)",
        "type": "Standard",
        "language_code": "ro-RO",
        "voice_name": "ro-RO-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Romanian (Romania)",
        "type": "Premium",
        "language_code": "ro-RO",
        "voice_name": "ro-RO-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Standard",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Standard",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Standard",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Standard",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Standard-D",
        "gender": "MALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Standard",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Standard-E",
        "gender": "FEMALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Premium",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Premium",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Premium",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Premium",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Wavenet-D",
        "gender": "MALE"
    },
    {
        "language": "Russian (Russia)",
        "type": "Premium",
        "language_code": "ru-RU",
        "voice_name": "ru-RU-Wavenet-E",
        "gender": "FEMALE"
    },
    {
        "language": "Serbian (Cyrillic)",
        "type": "Standard",
        "language_code": "sr-RS",
        "voice_name": "sr-RS-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Slovak (Slovakia)",
        "type": "Standard",
        "language_code": "sk-SK",
        "voice_name": "sk-SK-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Slovak (Slovakia)",
        "type": "Standard",
        "language_code": "sk-SK",
        "voice_name": "sk-SK-Standard-B",
        "gender": "FEMALE"
    },
    {
        "language": "Slovak (Slovakia)",
        "type": "Premium",
        "language_code": "sk-SK",
        "voice_name": "sk-SK-Wavenet-A",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Journey-D",
        "gender": "MALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Journey-F",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Journey-O",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Neural2-A",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Neural2-B",
        "gender": "MALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Neural2-C",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Neural2-D",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Neural2-E",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Neural2-F",
        "gender": "MALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Polyglot-1",
        "gender": "MALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Standard",
        "language_code": "es-ES",
        "voice_name": "es-ES-Standard-A",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Standard",
        "language_code": "es-ES",
        "voice_name": "es-ES-Standard-B",
        "gender": "MALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Standard",
        "language_code": "es-ES",
        "voice_name": "es-ES-Standard-C",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Standard",
        "language_code": "es-ES",
        "voice_name": "es-ES-Standard-D",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Standard",
        "language_code": "es-ES",
        "voice_name": "es-ES-Standard-E",
        "gender": "MALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Standard",
        "language_code": "es-ES",
        "voice_name": "es-ES-Standard-F",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Studio",
        "language_code": "es-ES",
        "voice_name": "es-ES-Studio-C",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Studio",
        "language_code": "es-ES",
        "voice_name": "es-ES-Studio-F",
        "gender": "MALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Wavenet-B",
        "gender": "MALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Wavenet-C",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Wavenet-D",
        "gender": "FEMALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Wavenet-E",
        "gender": "MALE"
    },
    {
        "language": "Spanish (Spain)",
        "type": "Premium",
        "language_code": "es-ES",
        "voice_name": "es-ES-Wavenet-F",
        "gender": "FEMALE"
    }
]

