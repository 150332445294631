const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_API_URL_FLOW = process.env.REACT_APP_API_URL_FLOW;
const REACT_APP_API_URL_CASE_WHATSAPP =
  process.env.REACT_APP_API_URL_CASE_WHATSAPP;
const REACT_APP_API_URL_CASE_TELEGRAM =
  process.env.REACT_APP_API_URL_CASE_TELEGRAM;
const REACT_APP_API_URL_CASE_VIBER = process.env.REACT_APP_API_URL_CASE_VIBER;
const REACT_APP_API_URL_CASE_CALL_CENTER =
  process.env.REACT_APP_API_URL_CASE_CALL_CENTER;
const REACT_APP_API_URL_CASE_FACEBOOK =
  process.env.REACT_APP_API_URL_CASE_FACEBOOK;

export const getWhatsappConfiguration_API = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-site");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append("authorization", `Bearer ${accessToken}`);
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_WHATSAPP}/get_whatsappConfiguration/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const showVoiceBotConfigurationList = async (
  accessToken,
  domainUUID
) => {
  const myHeaders = new Headers();

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Origin", "https://multiverse-flow.callibry.com");
  myHeaders.append("Referer", "https://multiverse-flow.callibry.com/");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-site");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append("accept", "application/json, text/plain, */*");
  myHeaders.append("accept-language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);
  myHeaders.append("content-type", "application/json");
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//tenant/get-voicebot-configuration?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const showCallBridgeConfigurationList = async (
  accessToken,
  domainUUID
) => {
  const myHeaders = new Headers();

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Origin", "https://multiverse-flow.callibry.com");
  myHeaders.append("Referer", "https://multiverse-flow.callibry.com/");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-site");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append("accept", "application/json, text/plain, */*");
  myHeaders.append("accept-language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);
  myHeaders.append("content-type", "application/json");
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//callBridge/get-configuration?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const KnowledgeBaseList = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/ai/ai-agent/v1/get-knowledge-base?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const showTelegramBotConfigurationList = async (
  accessToken,
  domainUUID
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Accept-Language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Origin", "https://multiverse-flow.callibry.com");
  myHeaders.append("Referer", "https://multiverse-flow.callibry.com/");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-site");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_TELEGRAM}/get_telegramConfiguration/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const showViberBotConfigurationList = async (
  accessToken,
  domainUUID
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Accept-Language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Origin", "https://multiverse-flow.callibry.com");
  myHeaders.append("Referer", "https://multiverse-flow.callibry.com/");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-site");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_VIBER}/get_viberConfiguration/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const showIvrConfigurationList = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Accept-Language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Origin", "https://multiverse-flow.callibry.com");
  myHeaders.append("Referer", "https://multiverse-flow.callibry.com/");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-site");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append("authorization", `Bearer ${accessToken}`);
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/getIvrList/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const CreateVoiceConfiguration = async (
  accessToken,
  domainUUID,
  voiceBotConfigurationName,
  voiceBotDescription,
  voiceBotMaxRetry
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_name: voiceBotConfigurationName,
    description: voiceBotDescription,
    max_retry: voiceBotMaxRetry.toString(),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//tenant/create-voicebot-configuration?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const CreateCallBridgeConfiguration = async (
  accessToken,
  domainUUID,
  callBridgeConfigurationName,
  callBridgeDescription
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_name: callBridgeConfigurationName,
    description: callBridgeDescription,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//callBridge/create-configuration?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const DeleteVoiceBotConfiguration = async (
  accessToken,
  configuration_uuid,
  domainUUID
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("authorization", `Bearer ${accessToken}`);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; ga=GA1.1.781975748.1719997597; ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );
  myHeaders.append("Origin", "https://multiverse-app.callibry.com");
  myHeaders.append(
    "Referer",
    "https://multiverse-app.callibry.com/voice-manager/voice-bot"
  );
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    configuration_uuid: configuration_uuid,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//tenant/delete-voicebot-configuration?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const DeleteCallBridgeConfiguration = async (
  accessToken,
  configuration_uuid,
  domainUUID
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("authorization", `Bearer ${accessToken}`);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; ga=GA1.1.781975748.1719997597; ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );
  myHeaders.append("Origin", "https://multiverse-app.callibry.com");
  myHeaders.append(
    "Referer",
    "https://multiverse-app.callibry.com/voice-manager/voice-bot"
  );
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    configuration_uuid: configuration_uuid,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//callBridge/delete-configuration?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const DeleteIvrConfiguration = async (
  accessToken,
  configuration_uuid,
  domainUUID
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("authorization", `Bearer ${accessToken}`);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; ga=GA1.1.781975748.1719997597; ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );
  myHeaders.append("Origin", "https://multiverse-app.callibry.com");
  myHeaders.append(
    "Referer",
    "https://multiverse-app.callibry.com/queue/ivr-list"
  );
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    ivr_menu_uuid: configuration_uuid,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/deleteIvrById?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const getQueuesList = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_FACEBOOK}/get_queue_list/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const ShowPromptList = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${accessToken} `);

  const formdata = new FormData();
  formdata.append("domain_uuid", domainUUID);
  formdata.append("id", "0");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/v1/callCenter/show-prompt-list/?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const ShowQueueListForIvr = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; ga=GA1.1.781975748.1719997597; ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );
  myHeaders.append("Origin", "https://multiverse-app.callibry.com");
  myHeaders.append(
    "Referer",
    "https://multiverse-app.callibry.com/queue/create-ivr"
  );
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/v1/callCenter/show-queue-list/?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const ShowExtensionsList = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();

  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; ga=GA1.1.781975748.1719997597; ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );
  myHeaders.append("Origin", "https://multiverse-app.callibry.com");
  myHeaders.append(
    "Referer",
    "https://multiverse-app.callibry.com/queue/create-ivr"
  );
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    limit: "",
    offset: "",
    search: "",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/v1/callCenter/show-extensions-list/?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const GetIdForTTSPrompt = async (
  accessToken,
  domainUUID,
  TtsShortPromptName,
  TtsShortPromptText,
  TtsShortPromptLanguage,
  TtsShortPromptVoiceType
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    name: TtsShortPromptName,
    text: TtsShortPromptText,
    language: TtsShortPromptLanguage,
    voice_type: TtsShortPromptVoiceType,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/tts-prompt?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const AddIVR = async (
  accessToken,
  domainUUID,
  IvrName,
  IvrExtension,
  SelectIvrGreetLong,
  GreetLongMessage,
  GreetLongValue,
  SelectIvrGreetShort,
  GreetShortMessage,
  GreetShortValue,
  IvrTimeout,
  IvrSelectIvrExitAction,
  IvrSelectIvrExitName,
  IvrSelectDirectDial,
  IvrSelectRingBack,
  IvrCallerIdPrefix,
  InvalidSoundValue,
  ExitSoundValue,
  IvrStatus,
  IvrDescription,
  IvrMaxFailures,
  IvrMaxTimeout
) => {
  const myHeaders = new Headers();

  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    ivr_menu_name: IvrName,
    ivr_menu_extension: IvrExtension,
    ivr_menu_greet_long:
      SelectIvrGreetLong == "say" ? `say:${GreetLongMessage}` : GreetLongValue,
    ivr_menu_greet_short:
      SelectIvrGreetShort == "say"
        ? `say:${GreetShortMessage}`
        : GreetShortValue,
    ivr_menu_timeout: IvrTimeout,
    ivr_menu_exit_app:
      IvrSelectIvrExitAction == "1" ? "" : IvrSelectIvrExitAction,
    ivr_menu_exit_data: IvrSelectIvrExitName == "0" ? "" : IvrSelectIvrExitName,
    ivr_menu_direct_dial: IvrSelectDirectDial,
    ivr_menu_ringback: IvrSelectRingBack,
    ivr_menu_cid_prefix: IvrCallerIdPrefix,
    ivr_menu_invalid_sound: InvalidSoundValue,
    ivr_menu_exit_sound: ExitSoundValue,
    ivr_menu_enabled: "false", // asked to send false as it was getting true after publish changes
    ivr_callback_enabled: "false", //static
    ivr_menu_description: IvrDescription,
    is_enable: "false", //static
    ivr_menu_max_failures: IvrMaxFailures,
    ivr_menu_max_timeouts: IvrMaxTimeout,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/addIvr?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const EditIvr = async (
  accessToken,
  domainUUID,
  EditIvrFlowUuid,
  IvrName,
  IvrExtension,
  SelectIvrGreetLong,
  GreetLongMessage,
  GreetLongValue,
  SelectIvrGreetShort,
  GreetShortMessage,
  GreetShortValue,
  IvrTimeout,
  IvrSelectIvrExitAction,
  IvrSelectIvrExitName,
  IvrSelectDirectDial,
  IvrSelectRingBack,
  IvrCallerIdPrefix,
  InvalidSoundValue,
  ExitSoundValue,
  IvrStatus,
  IvrDescription,
  IvrMaxFailures,
  IvrMaxTimeout
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "hjSessionUser_1932330=eyJpZCI6ImUzMWNhNzRmLTQxYmEtNTc0NC1iYmU3LTRlMzYzZmZlZDlmYyIsImNyZWF0ZWQiOjE3MjgzNzk4MDAwNDUsImV4aXN0aW5nIjpmYWxzZX0=; ga=GA1.1.169630990.1728379800; _ga_TFK6JQZ10J=GS1.1.1728379800.1.1.1728380077.0.0.0"
  );
  myHeaders.append("Origin", "https://multiverse-app.callibry.com");
  myHeaders.append(
    "Referer",
    "https://multiverse-app.callibry.com/queue/update-ivr"
  );
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"macOS"');

  const raw = JSON.stringify({
    ivr_menu_uuid: EditIvrFlowUuid,
    ivr_menu_name: IvrName,
    ivr_menu_extension: IvrExtension,
    ivr_menu_greet_long:
      SelectIvrGreetLong == "say" ? `say:${GreetLongMessage}` : GreetLongValue,
    ivr_menu_greet_short:
      SelectIvrGreetShort == "say"
        ? `say:${GreetShortMessage}`
        : GreetShortValue,
    ivr_menu_timeout: IvrTimeout,
    ivr_menu_exit_app:
      IvrSelectIvrExitAction == "1" ? "" : IvrSelectIvrExitAction,
    ivr_menu_exit_data: IvrSelectIvrExitName == "0" ? "" : IvrSelectIvrExitName,
    ivr_menu_direct_dial: IvrSelectDirectDial,
    ivr_menu_ringback: IvrSelectRingBack,
    ivr_menu_cid_prefix: IvrCallerIdPrefix,
    ivr_menu_invalid_sound: InvalidSoundValue,
    ivr_menu_exit_sound: ExitSoundValue,
    ivr_menu_enabled: "true",
    ivr_callback_enabled: "false",
    ivr_menu_description: IvrDescription,
    ivr_menu_max_failures: IvrMaxFailures,
    ivr_menu_max_timeouts: IvrMaxTimeout,
  });

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/update-ivr-by-ivr_menu_uuid?user_uuid=${domainUUID}`,
      requestOptions
    );

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const GetTtsPromptData = async (accessToken, domainUUID, pmtUuid) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    pmt_uuid: pmtUuid,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/tts-prompt/get?user_uuid=${domainUUID}`,
      requestOptions
    );

    const result = await response.json();
    return result;
  } catch (error) {}
};

export const GetIvrListUuid = async (accessToken, domainUUID, NodeId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/getIvrListUuid/${domainUUID}/${NodeId}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const PublishChanges = async (
  accessToken,
  domainUUID,
  configuration_id,
  flowvalue,
  channel
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_id: configuration_id,
    flow: flowvalue,
    channel: channel === "IVR" ? `${channel}_${configuration_id}` : channel,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${REACT_APP_API_URL_FLOW}/ccaas/workflow/v1/publishChanges?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const GetIVROptionMenuDetails = async (
  accessToken,
  domainUUID,
  configurationId,
  id
) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/get-ivr-option-menu-details-by-ivr-menu_options_uuid/${configurationId}/${id}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const DeleteIvrOptionById = async (
  accessToken,
  domainUUID,
  ivr_menu_option_uuid
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/deleteIvrOptionById/${ivr_menu_option_uuid}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const DeleteNodeForVoiceBot = async (
  accessToken,
  domainUUID,
  IDforVoiceBot,
  id
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    id: IDforVoiceBot.toString(),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//tenant/delete-voicebot-case?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const DeleteNodeForCallBridge = async (
  accessToken,
  domainUUID,
  IDforCallBridge,
  id
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    id: IDforCallBridge.toString(),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//callBridge/delete-case?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const EditVoiceBotNode = async (
  accessToken,
  domainUUID,
  selectedNodeType,
  nodeData,
  voiceBotLanguage,
  configurationId,
  IDforVoiceBot,
  voiceBotVoiceType,
  queueForVoiceBot,
  voiceBotUserInput,
  optionsList,
  MediaConfigurationId,
  file_path
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; ga=GA1.1.781975748.1719997597; ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    condition: "",
    condition_type: selectedNodeType,
    condition_data: nodeData,
    language: voiceBotLanguage,
    configuration_uuid: configurationId,
    id: IDforVoiceBot.toString(),
    voice_type: selectedNodeType == "whatsapp" ? file_path : voiceBotVoiceType,
    queue_uuid: queueForVoiceBot,
    user_input_type: voiceBotUserInput,
    add_option: optionsList,
    config_id:
      selectedNodeType == "whatsapp" ? MediaConfigurationId.toString() : "",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//tenant/update-voicebot-case?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const AddCallBridgeNode = async (
  accessToken,
  domainUUID,
  configurationId,
  conditionType,
  conditionData,
  voiceBotLanguage,
  voiceType,
  apiType,
  optionList,
  apiEndPoint
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; ga=GA1.1.781975748.1719997597; ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_uuid: configurationId,
    condition_type: conditionType,
    condition: "",
    condition_data: conditionData,
    language: conditionType == "welcome_message" ? voiceBotLanguage : "",
    voice_type: conditionType == "welcome_message" ? voiceType : "",
    queue_uuid: "",
    user_input_type: apiType,
    add_option: conditionType == "welcome_message" ? optionList : apiEndPoint,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//callBridge/create-case?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const EditCallBridgeNode = async (
  accessToken,
  domainUUID,
  configurationId,
  conditionType,
  conditionData,
  voiceBotLanguage,
  voiceType,
  apiType,
  optionList,
  apiEndPoint,
  IDforCallBridge
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; ga=GA1.1.781975748.1719997597; ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_uuid: configurationId,
    condition_type: conditionType,
    condition: "",
    condition_data: conditionData,
    language: conditionType == "welcome_message" ? voiceBotLanguage : "",
    voice_type: conditionType == "welcome_message" ? voiceType : "",
    queue_uuid: "",
    user_input_type: apiType,
    add_option: conditionType == "welcome_message" ? optionList : apiEndPoint,
    id: IDforCallBridge.toString(),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//callBridge/update-case?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getVoiceBotCaseById = async (
  accessToken,
  domainUUID,
  configurationId,
  nodeId
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_uuid: configurationId,
    node_id: nodeId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//tenant/get-voicebot-case-by-id?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getCallBridgeCaseById = async (
  accessToken,
  domainUUID,
  configurationId,
  nodeId
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_uuid: configurationId,
    node_id: nodeId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//callBridge/get-case-by-id?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const updateIvrOption = async (
  accessToken,
  domainUUID,
  IvrMenuList,
  IVR_MENU_OPTION_PARAM,
  nodeName,
  IvrNodeDestinationName
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    ivr_menu_uuid: IvrMenuList[0].ivr_menu_uuid,
    ivr_menu_option_uuid: IvrMenuList[0].ivr_menu_option_uuid, //as it is
    ivr_menu_option_digits: IvrMenuList[0].ivr_menu_option_digits, // as it is
    ivr_menu_option_action: "menu-exec-app",
    ivr_menu_option_param: IVR_MENU_OPTION_PARAM,
    ivr_menu_option_order: IvrMenuList[0].ivr_menu_option_order, // as it is
    ivr_menu_option_description: nodeName,
    ivr_menu_action_uuid: IvrNodeDestinationName,
    dtmf_uuid: "",
  });

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/update-ivr-option-by-ivr_menu_option_uuid?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const addIvrOption = async (
  accessToken,
  domainUUID,
  configurationId,
  IVR_MENU_OPTION_PARAM,
  nodeName
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken} `);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    ivr_menu_uuid: configurationId,
    domain_uuid: domainUUID,
    items: {
      inputs: [
        {
          ivr_menu_option_digits: "1", //set blank
          ivr_menu_option_action: "menu-exec-app",
          ivr_menu_option_param: IVR_MENU_OPTION_PARAM,
          ivr_menu_option_order: "1", //set blank
          ivr_menu_option_description: nodeName,
          dtmf_uuid: "", //only set for external number
          ivr_menu_action_uuid: "", //set only for external number
        },
      ],
    },
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/add-ivr-option-menu?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const addChatbotCase = async (
  accessToken,
  domainUUID,
  WhatsappNodeConditionType,
  ConditionData,
  conditionAPI,
  method,
  payloadType,
  payload,
  convertedButtonList,
  selectedImage,
  configurationId,
  queue,
  channel,
  nodeName,
  file_path
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    // id: selectedNodeId,
    condition: WhatsappNodeConditionType == "chat" ? nodeName : "",
    condition_type: WhatsappNodeConditionType,
    condition_data: ConditionData,
    condition_api: WhatsappNodeConditionType == "API" ? conditionAPI : "",
    method: WhatsappNodeConditionType == "API" ? method : "",
    payload_type:
      WhatsappNodeConditionType == "API" || WhatsappNodeConditionType == "media"
        ? payloadType
        : "",
    payload:
      WhatsappNodeConditionType == "media"
        ? file_path
        : WhatsappNodeConditionType == "API"
        ? payload
        : "",
    button:
      WhatsappNodeConditionType == "button" ||
      WhatsappNodeConditionType == "media"
        ? convertedButtonList
        : "[]",
    layout: selectedImage,
    map_variable: "",
    action_type: "",
    app_id: configurationId,
    queue_uuid: queue == "1" ? "" : queue,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  var API_URL_CASE;
  if (channel === "whatsapp") {
    API_URL_CASE = REACT_APP_API_URL_CASE_WHATSAPP;
  } else if (channel === "telegram") {
    API_URL_CASE = REACT_APP_API_URL_CASE_TELEGRAM;
  } else if (channel === "viber") {
    API_URL_CASE = REACT_APP_API_URL_CASE_VIBER;
  }

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${API_URL_CASE}/add-chatbot-case?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const updateChatbotCase = async (
  domainUUID,
  accessToken,
  selectedNodeId,
  WhatsappNodeConditionType,
  ConditionData,
  conditionAPI,
  method,
  payloadType,
  payload,
  convertedButtonList,
  selectedImage,
  configurationId,
  queue,
  channel,
  nodeName,
  file_path
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    id: selectedNodeId,
    condition: WhatsappNodeConditionType == "chat" ? nodeName : "",
    condition_type: WhatsappNodeConditionType,
    condition_data: ConditionData,
    condition_api: WhatsappNodeConditionType == "API" ? conditionAPI : "",
    method: WhatsappNodeConditionType == "API" ? method : "",
    payload_type: WhatsappNodeConditionType == "API" ? payloadType : "",
    payload:
      WhatsappNodeConditionType == "media"
        ? file_path
        : WhatsappNodeConditionType == "API"
        ? payload
        : "",
    button:
      WhatsappNodeConditionType == "button" ||
      WhatsappNodeConditionType == "media"
        ? convertedButtonList
        : "[]",
    layout: selectedImage,
    map_variable: "",
    action_type: "",
    app_id: configurationId,
    queue_uuid: queue == "1" ? "" : queue,
  });

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  var API_URL_CASE;
  if (channel === "whatsapp") {
    API_URL_CASE = REACT_APP_API_URL_CASE_WHATSAPP;
  } else if (channel === "telegram") {
    API_URL_CASE = REACT_APP_API_URL_CASE_TELEGRAM;
  } else if (channel === "viber") {
    API_URL_CASE = REACT_APP_API_URL_CASE_VIBER;
  }
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${API_URL_CASE}/update-chatbot-case?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const DeleteChatbotCase = async (
  domainUUID,
  accessToken,
  id,
  channel
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
  );

  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    id: id,
    domain_uuid: domainUUID,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  var API_URL_CASE;
  if (channel === "whatsapp") {
    API_URL_CASE = REACT_APP_API_URL_CASE_WHATSAPP;
  } else if (channel === "telegram") {
    API_URL_CASE = REACT_APP_API_URL_CASE_TELEGRAM;
  } else if (channel === "viber") {
    API_URL_CASE = REACT_APP_API_URL_CASE_VIBER;
  }
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${API_URL_CASE}/delete-chatbot-case?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getSMSBroadcastList = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json, text/plain, */*");
  myHeaders.append("accept-language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append("content-type", "application/json");
  myHeaders.append("origin", "http://localhost:4200");
  myHeaders.append("priority", "u=1, i");
  myHeaders.append("referer", "http://localhost:4200/");
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="128", "Not;A=Brand";v="24", "Google Chrome";v="128"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');
  myHeaders.append("sec-fetch-dest", "empty");
  myHeaders.append("sec-fetch-mode", "cors");
  myHeaders.append("sec-fetch-site", "cross-site");
  myHeaders.append(
    "user-agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36"
  );

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    sms_broadcast_uuid: "",
  });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}:10601/sms/broadcast/get?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const AddWhatsappConfiguration = async (
  domainUUID,
  accessToken,
  configuration_name,
  appId,
  chatLimit,
  appSecret,
  whatsapp_integration_id,
  outOfServiceMessage,
  size,
  ai_agent_status,
  context_id,
  welcomeMessage,
  working_days
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_name: configuration_name,
    appId: appId,
    appKey: chatLimit,
    appSecret: appSecret,
    whatsapp_integration_id: whatsapp_integration_id,
    message: outOfServiceMessage,
    size: size,
    ai_agent_status: ai_agent_status == false ? "undefined" : "true",
    context_id: ai_agent_status == false ? "" : context_id,
    trigger: {
      when: "when user sends message.",
      name: "Start message",
      message: welcomeMessage,
    },
    working_days: working_days,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_WHATSAPP}/add_whatsappConfiguration?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const AddTelegramConfiguration = async (
  domainUUID,
  accessToken,
  configuration_name,
  appId,
  chatLimit,
  appSecret,
  telegram_integration_id,
  outOfServiceMessage,
  size,
  ai_agent_status,
  context_id,
  welcomeMessage,
  working_days
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_name: configuration_name,
    appId: appId,
    appKey: chatLimit,
    appSecret: appSecret,
    telegram_integration_id: telegram_integration_id,
    message: outOfServiceMessage,
    size: size,
    ai_agent_status: ai_agent_status == false ? "undefined" : "true",
    context_id: ai_agent_status == false ? "" : context_id,
    trigger: {
      when: "when user sends message.",
      name: "Start message",
      message: welcomeMessage,
    },
    working_days: working_days,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_TELEGRAM}/add_telegramConfiguration?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const AddViberConfiguration = async (
  domainUUID,
  accessToken,
  configuration_name,
  appId,
  chatLimit,
  appSecret,
  viber_integration_id,
  outOfServiceMessage,
  size,
  ai_agent_status,
  context_id,
  welcomeMessage,
  working_days
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    configuration_name: configuration_name,
    appId: appId,
    appKey: chatLimit,
    appSecret: appSecret,
    viber_integration_id: viber_integration_id,
    message: outOfServiceMessage,
    size: size,
    ai_agent_status: ai_agent_status == false ? "undefined" : "true",
    context_id: ai_agent_status == false ? "" : context_id,
    trigger: {
      when: "when user sends message.",
      name: "Start message",
      message: welcomeMessage,
    },
    working_days: working_days,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_VIBER}/add_viberConfiguration?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const AddSMSConfiguration = async (
  accessToken,
  domainUUID,
  SmsBroadcastName,
  formattedContactList,
  SmsTimezone,
  SmsStatus,
  SmsScheduleTime,
  SmsStartDate,
  formattedStartTime,
  SmsEndDate,
  formattedEndTime,
  SmsTemplate,
  SmsDescription,
  SmsFrequency
) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json, text/plain, */*");
  myHeaders.append("accept-language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append("content-type", "application/json");
  myHeaders.append("priority", "u=1, i");
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="128", "Not;A=Brand";v="24", "Google Chrome";v="128"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');
  myHeaders.append("sec-fetch-dest", "empty");
  myHeaders.append("sec-fetch-mode", "cors");
  myHeaders.append("sec-fetch-site", "cross-site");
  myHeaders.append(
    "user-agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36"
  );

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    broadcast_name: SmsBroadcastName,
    contact_list: formattedContactList,
    timezone: SmsTimezone,
    status: SmsStatus,
    scheduled_type: SmsScheduleTime,
    start_date: SmsStartDate,
    start_time: formattedStartTime,
    end_date: SmsEndDate,
    end_time: formattedEndTime,
    sms_template_uuid: SmsTemplate,
    description: SmsDescription,
    sms_frequency: SmsFrequency.toString(),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}:10601/sms/broadcast/create?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const DeleteSMSBroadcast = async (
  accessToken,
  broadcastUuid,
  domainUUID
) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json, text/plain, */*");
  myHeaders.append("accept-language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append("content-type", "application/json");
  myHeaders.append("priority", "u=1, i");
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');
  myHeaders.append("sec-fetch-dest", "empty");
  myHeaders.append("sec-fetch-mode", "cors");
  myHeaders.append("sec-fetch-site", "same-site");
  myHeaders.append(
    "user-agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    sms_broadcast_uuid: broadcastUuid,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}:10601/sms/broadcast/delete?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const DeleteWhatsapp = async (accessToken, Id, domainUUID) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_WHATSAPP}/delete_whatsappConfiguration/${Id}/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const DeleteTelegramConfiguration = async (
  accessToken,
  domainUUID,
  Id
) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_TELEGRAM}/delete_telegramConfiguration/${Id}/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const DeleteViberConfiguration = async (accessToken, domainUUID, Id) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("Accept-Language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Sec-Fetch-Dest", "empty");
  myHeaders.append("Sec-Fetch-Mode", "cors");
  myHeaders.append("Sec-Fetch-Site", "same-origin");
  myHeaders.append(
    "User-Agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="122", "Not(A:Brand";v="24", "Google Chrome";v="122"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_VIBER}/delete_viberConfiguration/${Id}/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getGroupsListUnassigned = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    camp_uuid: "",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/get-group-list-unassigned?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const getSMSTemplate = async (accessToken, domainUUID) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json, text/plain, */*");
  myHeaders.append("accept-language", "en-US,en;q=0.9");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append("content-type", "application/json");
  myHeaders.append("origin", "http://localhost:4200");
  myHeaders.append("priority", "u=1, i");
  myHeaders.append("referer", "http://localhost:4200/");
  myHeaders.append(
    "sec-ch-ua",
    '"Chromium";v="128", "Not;A=Brand";v="24", "Google Chrome";v="128"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"Linux"');
  myHeaders.append("sec-fetch-dest", "empty");
  myHeaders.append("sec-fetch-mode", "cors");
  myHeaders.append("sec-fetch-site", "cross-site");
  myHeaders.append(
    "user-agent",
    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36"
  );

  const raw = JSON.stringify({
    domain_uuid: domainUUID,
    sms_template_uuid: "",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL}:10601/sms/template/get?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const getTemplates = async (accessToken, domainUUID, channel) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    channel: channel,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${REACT_APP_API_URL_FLOW}/ccaas/workflow/v1/getTemplates?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const getTemplateData = async (accessToken, domainUUID, channel, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    channel: channel,
    id: id.toString(),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${REACT_APP_API_URL_FLOW}/ccaas/workflow/v1/getTemplateData?user_uuid=${domainUUID}`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};
